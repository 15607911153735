import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./AboutPartner.css";
import { Ellipsis } from "react-spinners-css";
import axios from "axios";
import HeaderBackToEdit from "../../components/Header/HeaderBackToEdit";
import {
  getHared,
  getWordByGender,
  getWordOfOtherGender,
} from "../../handlers/dictionaryHandler.js";
import AboutPartnerHared from "../../components/AboutPartnerHared/AboutPartnerHared";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "../../slider.css";
import cubesvideo from "../../videos/cubesvideo.mp4";
import PopupExplainColors from "../../components/PopupExplainColors/PopupExplainColors";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const RangeSlider = createSliderWithTooltip(Slider.Range);
const SliderWithTooltip = createSliderWithTooltip(Slider);

const AboutPartner = () => {
  const history = useHistory();
  const myRef = useRef(null);
  const [viewIndex, setViewIndex] = useState(1);
  // Multi selection is an option
  const [partnerDat, setPartnerDat] = useState([]);
  const [partnerStatus, setPartnerStatus] = useState([]);
  const [partnerEdu, setPartnerEdu] = useState([]);

  const [charcterDictionary, setCharcterDictionary] = useState({});
  const [minTall, setMinTall] = useState();
  const [maxTall, setMaxTall] = useState();
  const [minAge, setMinAge] = useState();
  const [maxAge, setMaxAge] = useState();
  const [distance, setDistance] = useState();
  const [blackPhone1, setBlackPhone1] = useState("");
  const [blackPhone2, setBlackPhone2] = useState("");
  const [blackPhone3, setBlackPhone3] = useState("");
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [newUser, setNewUser] = useState(true);

  // this number is needed for validation
  const numberOfCharectersParameters = 6;
  const handleTall = (e) => {
    setMinTall(e[0]);
    setMaxTall(e[1]);
  };

  const cellPhoneValidatorRegex =
    /^\+?(0)(\-)?0?(([23489]{1}\d{7})|[5]{1}\d{8})$/i;

  const cellPhoneValidator = (phoneNumber) => {
    return cellPhoneValidatorRegex.exec(phoneNumber) !== null;
  };

  const handleAge = (e) => {
    setMinAge(e[0]);
    setMaxAge(e[1]);
  };
  const handlePartnerDat = (val) => {
    if (
      !partnerDat.includes(val) &&
      ((partnerDat.length < 11 && partnerDat.includes("0")) ||
        (partnerDat.length < 10 && !partnerDat.includes("0")))
    ) {
      setPartnerDat([...partnerDat, val]);
    } else {
      const tempPartner = partnerDat.filter((value) => value != val);
      setPartnerDat(tempPartner);
    }
  };
  const handlePartnerStatus = (val) => {
    if (!partnerStatus.includes(val)) {
      setPartnerStatus([...partnerStatus, val]);
    } else {
      const tempPartner = partnerStatus.filter((value) => value != val);
      setPartnerStatus(tempPartner);
    }
  };

  const handlePartnerEdu = (val) => {
    if (!partnerEdu.includes(val)) {
      setPartnerEdu([...partnerEdu, val]);
    } else {
      const tempPartner = partnerEdu.filter((value) => value != val);
      setPartnerEdu(tempPartner);
    }
  };

  const alertUser = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage(message);
  };

  const handleCharacter = (char, e) => {
    //setCharacterName(e.target.id);

    setCharcterDictionary({
      ...charcterDictionary,
      [char.type]: e.target.value,
    });
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  useEffect(async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PaboutPartner",
        config
      );
      const userData = response.data;
      // console.log(userData);

      if (userData.serious.toString() > 0) {
        setDistance(userData.distance);
        setMinAge(userData.minAge);
        setMaxAge(userData.maxAge);
        setMinTall(userData.minTall);
        setMaxTall(userData.maxTall);
        handleTall([userData.minTall, userData.maxTall]);

        setPartnerDat(userData.religous.split(","));

        setPartnerEdu(userData.education.split(","));

        setPartnerStatus(userData.status.split(","));

        setCharcterDictionary({
          serious: userData.serious.toString(),
          simpliest: userData.simpliest.toString(),
          activity: userData.activity.toString(),
          popular: userData.popular.toString(),
          bourgeoise: userData.bourgeoise.toString(),
          friendly: userData.friendly.toString(),
        });
        // console.log(userData);
        setBlackPhone1(userData.cellPhone1 > 0 ? userData.cellPhone1 : "");
        setBlackPhone2(userData.cellPhone2 > 0 ? userData.cellPhone2 : "");
        setBlackPhone3(userData.cellPhone3 > 0 ? userData.cellPhone3 : "");
        setNewUser(false);
        setIsDataLoaded(true);
      } else {
        setIsDataLoaded(true); // in case its a new user
      }
    } catch (error) {
      // probebly user is not authenticated or server is down
    }
  }, []);

  const onSubmitBlackPhone = async () => {
    if (cellPhoneValidator(blackPhone1.trim()) || blackPhone1.trim() === "") {
      if (cellPhoneValidator(blackPhone2.trim()) || blackPhone2.trim() === "") {
        if (
          cellPhoneValidator(blackPhone3.trim()) ||
          blackPhone3.trim() === ""
        ) {
          try {
            const response = await axios.post(
              process.env.REACT_APP_LOCALHOST_ADDRESS + "/PBlackPhone",
              {
                blackPhone1: blackPhone1 !== "" ? blackPhone1 : 0,
                blackPhone2: blackPhone2 !== "" ? blackPhone2 : 0,
                blackPhone3: blackPhone3 !== "" ? blackPhone3 : 0,
              },
              config
            );

            if (response.data === true) {
              alertUser("");
              history.push("/miror");
            } else {
              alertUser("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
            }
          } catch (error) {
            console.log(error);
            alertUser("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
          }
        } else {
          alertUser("מספר הטלפון השלישי לא חוקי");
          console.log("Phone1 number is not valid");
        }
      } else {
        alertUser("מספר הטלפון השני לא חוקי");
        console.log("Phone1 number is not valid");
      }
    } else {
      alertUser("מספר הטלפון הראשון לא חוקי");
      console.log("Phone1 number is not valid");
    }
  };

  const onSubmit = async () => {
    if (partnerDat != "" && partnerDat != ",0," && partnerDat != ",0,,") {
      if (distance != "" && distance != undefined) {
        if (minTall != "" && minTall != undefined && minTall != maxTall) {
          if (minAge != "" && minAge != undefined && minAge != maxAge) {
            if (partnerStatus != "" && partnerStatus != "0") {
              if (partnerEdu != "" && partnerEdu != "0") {
                if (
                  Object.keys(charcterDictionary).length ==
                  numberOfCharectersParameters
                ) {
                  // alertUser("נשמר בהצלחה");
                  // history.push("/miror");
                  try {
                    const response = await axios.post(
                      process.env.REACT_APP_LOCALHOST_ADDRESS +
                        "/PaboutPartner",
                      {
                        minTall,
                        maxTall,
                        minAge,
                        maxAge,
                        distance,
                        partnerDat,
                        partnerStatus,
                        partnerEdu,
                        serious: charcterDictionary.serious,
                        simpliest: charcterDictionary.simpliest,
                        activity: charcterDictionary.activity,
                        popular: charcterDictionary.popular,
                        bourgeoise: charcterDictionary.bourgeoise,
                        friendly: charcterDictionary.friendly,
                      },
                      config
                    );
                    // console.log("Here");

                    if (response.data === true) {
                      setViewIndex(2);
                      myRef.current.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      alertUser("");
                      // history.push("/miror");
                    } else {
                      alertUser(
                        "יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית"
                      );
                    }
                  } catch (error) {
                    console.log(error);
                    alertUser(
                      "יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית"
                    );
                  }
                } else {
                  alertUser("לא כל מדדי האופי מולאו");
                  console.log("Not all personal data is filled");
                }
              } else {
                alertUser("לא בחרת השכלה");
                console.log("Education did not set");
              }
            } else {
              alertUser("לא בחרת סטטוס זוגי");
              console.log("status not set");
            }
          } else {
            alertUser("לא בחרת טווח גילאים");
            console.log("tall did not set");
          }
        } else {
          alertUser("לא בחרת טווח גובה");
          console.log("tall did not set");
        }
      } else {
        alertUser("לא בחרת מרחק");
        console.log("tall did not set");
      }
    } else {
      alertUser("לא בחרת סגנון דתי");
      console.log("Dat did not chosen");
    }
  };

  const defaultcharacter = [
    {
      type: "serious",
      textright: "בזרימה עם החיים",
      textleft: getWordOfOtherGender("serious"),
    },
    {
      type: "simpliest",
      textright: "שיחות פשוטות",
      textleft: "נטייה להתפלסף",
    },
    {
      type: "activity",
      textright: "נוטה לפעילות ואקטיביות",
      textleft: getWordOfOtherGender("activity"),
    },
    {
      type: "popular",
      textright: "מסמר הערב",
      textleft: "נחבא אל הכלים",
    },
    {
      type: "bourgeoise",
      textright: "סגנון חיים בסטייל",
      textleft: "סגנון חיים פשוט",
    },
    { type: "friendly", textright: "סחבק", textleft: "שומר 2 מטר" },
  ];
  const [character, setCharacter] = useState(defaultcharacter);
  const rendercharacterButtons = () => {
    const characterButtons = character.map((character) => (
      <div key={character.type}>
        <br />
        <div id="hright">{character.textright}</div>
        <div id="hleft">{character.textleft}</div>
        <br></br>
        <div className="parent-7cubes">
          <input
            type="button"
            // id={character.type + "1"}
            value="1"
            // className="characterCube"
            id={character.type}
            className={getClassNameForCharacterCube(
              charcterDictionary[character.type],
              1
            )}
            onClick={(e) => handleCharacter(character, e)}
          />
          <input
            type="button"
            id={character.type}
            value="2"
            className={getClassNameForCharacterCube(
              charcterDictionary[character.type],
              2
            )}
            onClick={(e) => handleCharacter(character, e)}
          />
          <input
            type="button"
            id={character.type}
            value="3"
            className={getClassNameForCharacterCube(
              charcterDictionary[character.type],
              3
            )}
            onClick={(e) => handleCharacter(character, e)}
          />
          <input
            type="button"
            id={character.type}
            value="4"
            className={getClassNameForCharacterCube(
              charcterDictionary[character.type],
              4
            )}
            onClick={(e) => handleCharacter(character, e)}
          />
          <input
            type="button"
            id={character.type}
            value="5"
            className={getClassNameForCharacterCube(
              charcterDictionary[character.type],
              5
            )}
            onClick={(e) => handleCharacter(character, e)}
          />
          <input
            type="button"
            id={character.type}
            value="6"
            className={getClassNameForCharacterCube(
              charcterDictionary[character.type],
              6
            )}
            onClick={(e) => handleCharacter(character, e)}
          />
          <input
            type="button"
            id={character.type}
            value="7"
            className={getClassNameForCharacterCube(
              charcterDictionary[character.type],
              7
            )}
            onClick={(e) => handleCharacter(character, e)}
          />
        </div>
      </div>
    ));

    return <div className="questionnaires-buttons">{characterButtons}</div>;
  };

  const getClassNameForCharacterCube = (currentValue, myValue) => {
    let defaultClassName = "characterCube";

    if (currentValue == myValue) {
      defaultClassName += " active";
    } else if (currentValue == myValue - 1) {
      // Right
      defaultClassName += " rightCharacterCube";
    } else if (currentValue == myValue + 1) {
      // Left
      defaultClassName += " leftCharacterCube";
    }

    return defaultClassName;
  };

  return (
    <form className="about-partner-form" ref={myRef}>
      <HeaderBackToEdit />
      {isDataLoaded === true ? (
        <>
          <div className="steps">
            <div
              className={
                viewIndex > 0 ? "step-about-me-after" : "step-about-me"
              }
              onClick={() => setViewIndex(1)}
            >
              1
            </div>
            <div
              className={
                viewIndex > 1 ? "line-between-step-after" : "line-between-step"
              }
            ></div>
            <div
              className={
                viewIndex > 1 ? "step-about-me-after" : "step-about-me"
              }
              onClick={() => setViewIndex(2)}
            >
              2
            </div>
            <div
              className={
                viewIndex > 2 ? "line-between-step-after" : "line-between-step"
              }
            ></div>
            <div
              className={
                viewIndex > 2 ? "step-about-me-after" : "step-about-me"
              }
              onClick={() => setViewIndex(3)}
            >
              3
            </div>
          </div>
          <div className="title"> </div>
          <div className="page-title">
            <div>מי מתאים לי</div>
          </div>
          <div className="aboutpartner-body">
            {viewIndex === 1 && (
              <>
                <div className="page-sub-title">
                  <b>ניתן לסמן יותר מתשובה אחת</b>
                </div>

                <div className="section-title">
                  <b>גובה: </b>
                  מתאים לי מעל גובה {minTall} ומתחת ל- {maxTall} ס"מ
                </div>
                <RangeSlider
                  componentId="RangeSliderSensor"
                  progress
                  style={{ marginTop: 36 }}
                  defaultValue={[170]}
                  value={[minTall, maxTall]}
                  tipProps={{ visible: true }}
                  // dataField="guests"
                  min={100}
                  max={200}
                  onChange={(e) => handleTall(e)}
                />

                <br />
                <div className="line" />
                <div className="section-title">
                  <b>גילאים: </b>
                  מתאים לי מעל גיל {minAge} ועד גיל {maxAge}
                </div>
                <RangeSlider
                  componentId="RangeSliderSensor"
                  progress
                  style={{ marginTop: 36 }}
                  defaultValue={[32]}
                  value={[minAge, maxAge]}
                  // key={`${minAge}-${maxAge}`}
                  // dataField="guests"
                  min={20}
                  max={70}
                  tipProps={{ visible: true }}
                  onChange={(e) => handleAge(e)}
                />
                <br />
                <div className="line" />
                <div className="section-title">
                  <b>עד מרחק: </b>
                  {distance} ק"מ
                </div>
                <SliderWithTooltip
                  componentId="RangeSliderSensor"
                  style={{ marginTop: 36 }}
                  defaultValue={150}
                  value={distance}
                  // dataField="guests"
                  tipProps={{ visible: true }}
                  min={0}
                  max={510}
                  onChange={(e) => setDistance(e)}
                />
                <br />
                <div className="line" />
                <h5 className="vipDescription">
                  <b>ניתן (וכדאי) לסמן יותר מתשובה אחת</b>
                </h5>
                <div className="section-title">
                  <b>דת</b> (מקסימום 8)
                </div>
                <div className="parent-dat">
                  <input
                    type="button"
                    name="dat"
                    className={`dati ${
                      partnerDat.includes("1") ? "active" : ""
                    }`}
                    value={getWordByGender("pdati")}
                    onClick={(e) => handlePartnerDat("1")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`datito ${
                      partnerDat.includes("2") ? "active" : ""
                    }`}
                    value={getWordByGender("pdatito")}
                    onClick={(e) => handlePartnerDat("2")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`modern ${
                      partnerDat.includes("3") ? "active" : ""
                    }`}
                    value={getWordByGender("pmodern")}
                    onClick={(e) => handlePartnerDat("3")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`hardal ${
                      partnerDat.includes("4") ? "active" : ""
                    }`}
                    value={getWordByGender("phardal")}
                    onClick={(e) => handlePartnerDat("4")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`light ${
                      partnerDat.includes("5") ? "active" : ""
                    }`}
                    value={getWordByGender("plight")}
                    onClick={(e) => handlePartnerDat("5")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`trad ${
                      partnerDat.includes("6") ? "active" : ""
                    }`}
                    value={getWordByGender("ptrad")}
                    key="6"
                    onClick={(e) => handlePartnerDat("6")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`datil ${
                      partnerDat.includes("7") ? "active" : ""
                    }`}
                    value={getWordByGender("pdatil")}
                    key="7"
                    onClick={(e) => handlePartnerDat("7")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`datlash ${
                      partnerDat.includes("8") ? "active" : ""
                    }`}
                    value={getWordByGender("pdatlash")}
                    key="8"
                    onClick={(e) => handlePartnerDat("8")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`hared ${
                      partnerDat.includes("9") ? "active" : ""
                    }`}
                    value={getWordByGender("phared")}
                    key="9"
                    onClick={(e) => handlePartnerDat("9")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`hozer ${
                      partnerDat.includes("10") ? "active" : ""
                    }`}
                    value={getWordByGender("phozer")}
                    key="10"
                    onClick={(e) => handlePartnerDat("10")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`hilon ${
                      partnerDat.includes("11") ? "active" : ""
                    }`}
                    value={getWordByGender("philon")}
                    key="11"
                    onClick={(e) => handlePartnerDat("11")}
                  />
                </div>
                <div className="line"></div>
                <div className="section-title">
                  <b>סטטוס</b>
                </div>

                {newUser ? (
                  <div className="section-title">
                    {" "}
                    <PopupExplainColors />
                  </div>
                ) : (
                  ""
                )}
                <div className="parent-status">
                  <input
                    type="button"
                    name="status"
                    className={`single ${
                      partnerStatus.includes("1") ? "active" : ""
                    }`}
                    value={getWordByGender("psingle")}
                    onClick={(e) => handlePartnerStatus("1")}
                  />
                  <input
                    type="button"
                    name="status"
                    className={`divo ${
                      partnerStatus.includes("2") ? "active" : ""
                    }`}
                    value={getWordByGender("pdivo")}
                    onClick={(e) => handlePartnerStatus("2")}
                  />
                  <input
                    type="button"
                    name="status"
                    className={`divop ${
                      partnerStatus.includes("3") ? "active" : ""
                    }`}
                    value={getWordByGender("pdivop")}
                    onClick={(e) => handlePartnerStatus("3")}
                  />
                  <input
                    type="button"
                    name="status"
                    className={`widow ${
                      partnerStatus.includes("4") ? "active" : ""
                    }`}
                    value={getWordByGender("pwidow")}
                    onClick={(e) => handlePartnerStatus("4")}
                  />
                  <input
                    type="button"
                    name="status"
                    className={`singleplus ${
                      partnerStatus.includes("5") ? "active" : ""
                    }`}
                    value={getWordByGender("psingleplus")}
                    onClick={(e) => handlePartnerStatus("5")}
                  />
                </div>
                <div className="line" />
                <div className="section-title">
                  <b>השכלה</b>
                </div>

                <div className="parent-edu">
                  <input
                    type="button"
                    name="edu"
                    className={`highschool ${
                      partnerEdu.includes("1") ? "active" : ""
                    }`}
                    value="תיכונית"
                    onClick={(e) => handlePartnerEdu("1")}
                  />
                  <input
                    type="button"
                    name="edu"
                    className={`student ${
                      partnerEdu.includes("2") ? "active" : ""
                    }`}
                    value={getWordByGender("pstudent")}
                    onClick={(e) => handlePartnerEdu("2")}
                  />
                  <input
                    type="button"
                    name="edu"
                    className={`academy ${
                      partnerEdu.includes("3") ? "active" : ""
                    }`}
                    value="אקדמית"
                    onClick={(e) => handlePartnerEdu("3")}
                  />
                  <input
                    type="button"
                    name="edu"
                    className={`other ${
                      partnerEdu.includes("4") ? "active" : ""
                    }`}
                    value={"קבע/ישיבה/נדלן /8200 וכד'"}
                    onClick={(e) => handlePartnerEdu("4")}
                  />
                  {/* <div className="section-title">
                    {" "}
                    'אחר: קבע, נדלן, 8200, ישיבה וכד*
                  </div> */}
                </div>

                <div className="line" />
                <div className="section-title">
                  <b>מדדי אופי: </b>
                  מה מתאים לי בסולם של
                </div>

                {rendercharacterButtons()}
              </>
            )}
            {viewIndex === 2 && (
              <>
                {getHared() === 1 ? <AboutPartnerHared /> : ""} <br />
                <p style={{ margin: "0px", direction: "rtl" }}>
                  אנחנו בחצי הדרך! שנמשיך?
                </p>
                <div>
                  <video autoPlay loop muted className="cubesvideo">
                    <source src={cubesvideo} type="video/mp4" />
                  </video>
                </div>
              </>
            )}

            {viewIndex === 3 && (
              <>
                <div className="section-title">
                  <b> רשימת טלפונים שחורה</b>
                </div>
                <div className="section-title">
                  מספרי טלפון של אנשים שאני לא רוצה שדאבל יציע לי. (לפעמים יש
                  מישהו שאם דאבל יציע לנו זה יהיה ממש מביך. אז אפשר לרשום כאן את
                  מספר הטלפון של אותו אדם - ודאבל לא יציע אותו.)
                </div>
                <input
                  value={blackPhone1}
                  maxLength="10"
                  placeholder="1 טלפון"
                  autoComplete="off"
                  onChange={(e) => setBlackPhone1(e.target.value)}
                ></input>
                <input
                  value={blackPhone2}
                  maxLength="10"
                  placeholder="2 טלפון"
                  autoComplete="off"
                  onChange={(e) => setBlackPhone2(e.target.value)}
                ></input>
                <input
                  value={blackPhone3}
                  maxLength="10"
                  placeholder="טלפון 3"
                  autoComplete="off"
                  onChange={(e) => setBlackPhone3(e.target.value)}
                ></input>
                <br />
                <br />
              </>
            )}
            <div className="line" />
            {isErrorMessageDisplayed && (
              <div className="error-message">
                <h5>{errorMessage} </h5>
              </div>
            )}
            {viewIndex !== 2 ? (
              <div
                className="button-text-center"
                id="btnSubmit"
                onClick={viewIndex === 1 ? onSubmit : onSubmitBlackPhone}
              >
                שמירה והמשך
              </div>
            ) : (
              <div
                className="button-text-center"
                id="btnSubmit"
                onClick={() => {
                  setViewIndex(3);
                  myRef.current.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });

                  alertUser("");
                }}
              >
                המשך
              </div>
            )}

            <Link to="/edit" style={{ textDecoration: "none" }}>
              <div className="button" id="backBtn">
                {" "}
                חזרה
              </div>
            </Link>
          </div>
        </>
      ) : (
        <div className="loading-data-container">
          <div className="loading-data-div">
            <Ellipsis />
            <h4>טוען את המידע שלך</h4>
          </div>
        </div>
      )}
    </form>
  );
};

export default AboutPartner;
