import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import { Ellipsis } from "react-spinners-css";
import "../../App.css";
import Cookies from "js-cookie";
import "./MyProfile.css";
import HeaderBack from "../../components/Header/HeaderBack";

import axios from "axios";
import {
  getWordOfOtherGender,
  getHobby,
  getGender,
  getWordByGender,
  getFirstName,
} from "../../handlers/dictionaryHandler.js";
import FileManager from "../../utils/FileManager";

// import login from "../login/login.component";

const MyProfile = () => {
  let history = useHistory();
  const [details1, setDetails1] = useState("");
  const [freeText1, setfreeText1] = useState("");
  const [cellFriend1, setCellFriend1] = useState("");
  const [image1a, setImage1a] = useState("");
  const [image2a, setImage2a] = useState("");
  const [image3a, setImage3a] = useState("");
  const [image4a, setImage4a] = useState("");
  const [image5a, setImage5a] = useState("");
  const [image1aBlobURL, setImage1aBlobURL] = useState("");
  const [image2aBlobURL, setImage2aBlobURL] = useState("");
  const [image3aBlobURL, setImage3aBlobURL] = useState("");
  const [image4aBlobURL, setImage4aBlobURL] = useState("");
  const [image5aBlobURL, setImage5aBlobURL] = useState("");
  const [image1aFullScreen, setImage1aFullScreen] = useState(false);
  const [image2aFullScreen, setImage2aFullScreen] = useState(false);
  const [image3aFullScreen, setImage3aFullScreen] = useState(false);
  const [image4aFullScreen, setImage4aFullScreen] = useState(false);
  const [image5aFullScreen, setImage5aFullScreen] = useState(false);
  const [facebook1, setFacebook1] = useState("");
  const [instegram1, setInstegram1] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [religous1, setReligous1] = useState("");
  const [tall1, setTall1] = useState("");
  const [education1, setEducation1] = useState("");
  const [status1, setStatus1] = useState("");
  const [hobbies1, setHobbies1] = useState("");
  const [hobbieFree, setHobbieFree] = useState("");
  const [occupation1, setOccupation1] = useState("");
  const [origin1, setOrigin1] = useState("");
  const [record1a, setRecord1a] = useState("");
  const [myRecord1a, setMyRecord1a] = useState("");
  const [dateRecord1a, setDateRecord1a] = useState("");
  const [isRecord1aPlaying, setIsRecord1aPlaying] = useState(false);
  const [recordingBlobURL1a, setRecordingBlobURL1a] = useState("");
  const [cameraAreaVisible, setCameraAreaVisible] = useState(false);
  const [faceAreaVisible, setFaceAreaVisible] = useState(false);
  const [instegramAreaVisible, setInstegramAreaVisible] = useState(false);
  const [linkedinAreaVisible, setLinkedinAreaVisible] = useState(false);
  const [recordAreaVisible, setRecordAreaVisible] = useState(false);
  const [inFreezeUse, setInFreezeUse] = useState(false);
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ageRange, setAgeRange] = useState("");
  const [tallRange, setTallRange] = useState("");
  const [partnerReligous, setPartnerReligous] = useState("");
  const [partnerEducation, setPartnerEducation] = useState("");
  const [distanceRange, setDistanceRange] = useState("");
  const [multiLook, setMultiLook] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const recordBtnClick = () => {
    setFaceAreaVisible(false);
    setCameraAreaVisible(false);
    setInstegramAreaVisible(false);
    setLinkedinAreaVisible(false);
    setRecordAreaVisible(!recordAreaVisible);
  };

  const instegramBtnClick = () => {
    setFaceAreaVisible(false);
    setCameraAreaVisible(false);
    setInstegramAreaVisible(!instegramAreaVisible);
    setRecordAreaVisible(false);
    setLinkedinAreaVisible(false);
  };

  const linkedinBtnClick = () => {
    setFaceAreaVisible(false);
    setCameraAreaVisible(false);
    setInstegramAreaVisible(false);
    setLinkedinAreaVisible(!linkedinAreaVisible);
    setRecordAreaVisible(false);
  };

  const facebookBtnClick = () => {
    setFaceAreaVisible(!faceAreaVisible);
    setCameraAreaVisible(false);
    setInstegramAreaVisible(false);
    setLinkedinAreaVisible(false);
    setRecordAreaVisible(false);
  };

  const cameraBtnClick = () => {
    setCameraAreaVisible(!cameraAreaVisible);
    setFaceAreaVisible(false);
    setInstegramAreaVisible(false);
    setLinkedinAreaVisible(false);
    setRecordAreaVisible(false);
  };

  const alertUser = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage(message);
  };

  const onFreezeUse = async () => {
    setInFreezeUse(!inFreezeUse);
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/freezeUse",
        // send 0 even thogh its the oposite from logic, cause react didnt refresh the value
        { toFreeze: inFreezeUse ? 0 : 1 },
        config
      );

      if (response.data === true) {
        // history.push("/questionnaires");
        // alertUser("");
        setIsErrorMessageDisplayed(false);
      } else {
        alertUser("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
      }
    } catch (error) {
      alertUser("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
    }
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_LOCALHOST_ADDRESS + "/PmyProfile",
          config
        );
        const userData = response.data;

        if (userData[0].length > 0) {
          setDetails1(userData[0][0].details);
          setfreeText1(userData[0][0].freeText);
          setCellFriend1(userData[0][0].cellFriend);
          if (userData[0][0].keyImage1 !== "") {
            const image1Data = await FileManager.getFile(
              userData[0][0].keyImage1
            );
            setImage1a(image1Data.file);
            setImage1aBlobURL(image1Data.blobURL);
          }
          if (userData[0][0].keyImage2 !== "") {
            const image2Data = await FileManager.getFile(
              userData[0][0].keyImage2
            );
            setImage2a(image2Data.file);
            setImage2aBlobURL(image2Data.blobURL);
          }
          if (userData[0][0].keyImage3 !== "") {
            const image3Data = await FileManager.getFile(
              userData[0][0].keyImage3
            );
            setImage3a(image3Data.file);
            setImage3aBlobURL(image3Data.blobURL);
          }
          if (userData[0][0].keyImage4 !== "") {
            const image4Data = await FileManager.getFile(
              userData[0][0].keyImage4
            );
            setImage4a(image4Data.file);
            setImage4aBlobURL(image4Data.blobURL);
          }
          if (userData[0][0].keyImage5 !== "") {
            const image5Data = await FileManager.getFile(
              userData[0][0].keyImage5
            );
            setImage5a(image5Data.file);
            setImage5aBlobURL(image5Data.blobURL);
          }

          setFacebook1(userData[0][0].facebook);
          setInstegram1(userData[0][0].instegram);
          setLinkedin(userData[0][0].linkedin);
          setReligous1(userData[0][0].religous);
          setTall1(userData[0][0].tall);
          setEducation1(userData[0][0].education);
          setStatus1(userData[0][0].status);
          setOccupation1(userData[0][0].occupation);
          setInFreezeUse(userData[0][0].inFreezeUse);
          setOrigin1(userData[0][0].origin);
          setHobbies1(userData[0][0].hobbies);
          setHobbieFree(userData[0][0].hobbieFree);
          setAgeRange(userData[0][0].ageRange);
          setTallRange(userData[0][0].tallRange);
          setPartnerReligous(userData[0][0].partnerReligous);
          setPartnerEducation(userData[0][0].partnerEducation);
          setDistanceRange(userData[0][0].distanceRange);
          setMultiLook(userData[0][0].multiLook);
          if (userData[0][0].rec1 !== "") {
            const rec1aBuffer = userData[0][0].rec1
              .split(",,")
              .map(
                (current) =>
                  new Int8Array(current.split(",").map((current) => +current))
              );
            const blob1a = new Blob(rec1aBuffer, {
              type: "audio/mp3",
            });
            const blob1aURL = URL.createObjectURL(blob1a);
            setRecordingBlobURL1a(blob1aURL);
          }
          // setRecordingBlob(blob1a);
          setMyRecord1a(userData[0][0].myRec1);
          setIsDataLoaded(true);
        } else {
          // in case there is no data
        }
      } catch (error) {
        // probebly user is not authenticated or server is down
      }
    };
    loadData();
  }, []);

  return (
    <form className="supermatchmaker-form">
      {isDataLoaded === true ? (
        <>
          <HeaderBack />
          <br />
          <div className="signup-body">
            <br />
            <h5 className="vipDescription"> אז... לסיכום בואו נראה! </h5>
            <div className="line"></div>
            <h5 className="vipDescription">
              <br />
              עריכת הפרופיל מתבצעת בדפים הבאים: <br />
              <Link to="/aboutme">{"מי אני"}</Link>
              <br />
              <Link to="/aboutpartner">{"מי מתאים לי"}</Link>
              <br />
              <Link to="/miror">{"מראה"}</Link>
              <br />
            </h5>
            <h5 className="h5middle"> מה סימנתי שמתאים לי? </h5>
            <div className="messegeMatch">
              {getGender() === "1" ? "גבר " : "אישה "}
              בין גילאים {ageRange} בלבד.
            </div>
            <h5> סגנונות דתיים</h5>
            <div className="messegeMatch">
              {partnerReligous != null
                ? partnerReligous
                    .split(",")
                    .map((current) => getWordByGender("pdat" + current))
                    .join(", ")
                : ""}
            </div>
            <div className="messegeMatch">
              <b> בגובה</b> {tallRange}
              ס"מ בלבד. זאת אומרת שדאבל לא יציע הצעה מעל הגובה שסימנתי או פחות
              מהגובה שסימנתי.
            </div>
            <h5> עם השכלה </h5>
            <div className="messegeMatch">
              {partnerEducation != null
                ? partnerEducation
                    .split(",")
                    .map((current) => getWordByGender("pedu" + current))
                    .join(", ")
                : ""}
            </div>
            <div className="messegeMatch">
              <b>עד מרחק </b>
              {distanceRange} ק"מ בדיוק.
            </div>
            <div className="messegeMatch">
              {multiLook > 3 ? " בכמה סגנונות מראה" : " בסוג מראה אחד בלבד"}
              {"."}
            </div>
            <h5>
              {" "}
              אם נראה לך שאפשר לסמן עוד דברים שמתאימים, או שסימנת מידי הרבה
              אפשרויות, ניתן לשנות בעמודים המתאימים.
            </h5>

            <div className="line"></div>
            <h5 className="h5middle"> איך הפרופיל שלי נראה? </h5>

            <div
              className="button-text-center-no-hover"
              id="btnMatch"
              //   onClick={infoBtnClick}
            >
              {details1}
            </div>

            <div></div>
            <div className="faceline" style={{ maxWidth: "100%" }}>
              {image1a !== "" ? (
                <>
                  <img
                    className={
                      image1aFullScreen
                        ? "image-fullscreen"
                        : "image-doubleMatch"
                    }
                    src={image1aBlobURL}
                    alt=""
                  />
                </>
              ) : null}
              <div>
                <h5> מי אני</h5>
                <div className="messegeMatch">
                  {getWordOfOtherGender("pdat" + religous1)} , {tall1} ס'מ ,{" "}
                  {getWordOfOtherGender("psta" + status1)}
                </div>
                <div className="messegeMatch">
                  השכלה:
                  {getWordOfOtherGender("pedu" + education1)}
                </div>
                <div className="messegeMatch">
                  עיסוק:
                  {occupation1}
                </div>
                <div className="messegeMatch">
                  מוצא:
                  {origin1}
                </div>
                <h5> התחביבים שלי</h5>
                <div className="messegeMatch">
                  {hobbies1 != null
                    ? hobbies1
                        .split(",")
                        .map((current) => getHobby(current))
                        .join(", ")
                    : ""}
                  {""}
                  {hobbieFree != null ? ", " : ""}
                  {hobbieFree}
                </div>
              </div>
              <div> </div>
            </div>

            <h5> טקסט חופשי</h5>
            <div className="messegeMatch"> {freeText1}</div>
            <h5> טלפון לבירורים </h5>
            <div className="messegeMatch"> {cellFriend1}</div>
            <br />
            <h5> תמונות ועוד</h5>

            <div className="iconsline">
              <div
                className={image1a != "" ? "cameraicon" : "cameraicongrey"}
                onClick={cameraBtnClick}
              ></div>
              <div
                className={facebook1 != "" ? "faceicon" : "faceicongrey"}
                onClick={facebook1 != "" ? facebookBtnClick : () => {}}
              ></div>
              <div
                className={
                  instegram1 != "" ? "instegramicon" : "instegramicongrey"
                }
                onClick={instegram1 != "" ? instegramBtnClick : () => {}}
              ></div>
              <div
                className={linkedin != "" ? "linkedinicon" : "linkedinicongrey"}
                onClick={linkedin != "" ? linkedinBtnClick : () => {}}
              ></div>
              <div
                // className="recordicon"
                // onClick={recordBtnClick}

                className={
                  recordingBlobURL1a !== "" ? "recordicon" : "recordicongrey"
                }
                onClick={recordingBlobURL1a !== "" ? recordBtnClick : () => {}}
              ></div>
            </div>
            <div
              className={cameraAreaVisible ? "areaAboutMeCard " : " invisible "}
            >
              {image1a !== "" ? (
                <>
                  <img
                    className={
                      image1aFullScreen
                        ? "image-fullscreen"
                        : "image-doubleMatch"
                    }
                    src={image1aBlobURL}
                    alt=""
                    onClick={(e) => setImage1aFullScreen(!image1aFullScreen)}
                  />
                </>
              ) : null}
              {image2a !== "" ? (
                <>
                  <img
                    className={
                      image2aFullScreen
                        ? "image-fullscreen"
                        : "image-doubleMatch"
                    }
                    src={image2aBlobURL}
                    alt=""
                    onClick={(e) => setImage2aFullScreen(!image2aFullScreen)}
                  />
                </>
              ) : null}
              {image3a !== "" ? (
                <>
                  <img
                    className={
                      image3aFullScreen
                        ? "image-fullscreen"
                        : "image-doubleMatch"
                    }
                    src={image3aBlobURL}
                    alt=""
                    onClick={(e) => setImage3aFullScreen(!image3aFullScreen)}
                  />
                </>
              ) : null}
              {image4a !== "" ? (
                <>
                  <img
                    className={
                      image4aFullScreen
                        ? "image-fullscreen"
                        : "image-doubleMatch"
                    }
                    src={image4aBlobURL}
                    alt=""
                    onClick={(e) => setImage4aFullScreen(!image4aFullScreen)}
                  />
                </>
              ) : null}
              {image5a !== "" ? (
                <>
                  <img
                    className={
                      image5aFullScreen
                        ? "image-fullscreen"
                        : "image-doubleMatch"
                    }
                    src={image5aBlobURL}
                    alt=""
                    onClick={(e) => setImage5aFullScreen(!image5aFullScreen)}
                  />
                </>
              ) : null}
            </div>

            <div
              className={faceAreaVisible ? "areaAboutMeCard " : " invisible "}
            >
              {"הקישור לפייס הוא: "}
              <a href={facebook1} target="_blank" rel="noopener noreferrer">
                {facebook1}
              </a>
            </div>
            <div
              className={
                instegramAreaVisible ? "areaAboutMeCard " : " invisible "
              }
            >
              {"הקישור לאינסטגרם הוא: "}
              <a href={instegram1} target="_blank" rel="noopener noreferrer">
                {instegram1}
              </a>
            </div>
            <div
              className={
                linkedinAreaVisible ? "areaAboutMeCard " : " invisible "
              }
            >
              {"הקישור ללינקדין הוא: "}
              <a href={linkedin} target="_blank" rel="noopener noreferrer">
                {linkedin}
              </a>
            </div>

            <div
              className={recordAreaVisible ? "areaAboutMeCard " : " invisible "}
            >
              <div className="record-line">
                {/* <div className="playicon"></div>
                  <div className="stopplayicon"></div> */}
                <ReactPlayer
                  playing={isRecord1aPlaying}
                  autoPlay={false}
                  url={recordingBlobURL1a}
                  controls={true}
                  progressInterval={100}
                  config={{
                    file: {
                      forceAudio: true,
                    },
                  }}
                  onEnded={() => {
                    setIsRecord1aPlaying(false);
                  }}
                  width="0px"
                  height="0px"
                />
                <div style={{ width: "33.3%" }}>
                  <div
                    className={
                      "playicon-button " +
                      (isRecord1aPlaying ? "pause-icon" : "play-icon")
                    }
                    onClick={() => {
                      setIsRecord1aPlaying(!isRecord1aPlaying);
                    }}
                  ></div>
                  <p className="lbl">לשמיעה</p>
                </div>
                {myRecord1a == 1 ? "אני על עצמי" : "מספרים עלי"}
                {record1a + dateRecord1a}
              </div>
            </div>
            <div
              className={
                Cookies.get("newUser") == 1 ? "newUserPopup" : "invisible"
              }
              id="newUserFP"
            >
              <div
                className="exit-popup"
                onClick={() => {
                  document.getElementById("newUserFP").style.display = "none";
                }}
              >
                x
              </div>
              <div>
                {" "}
                {getFirstName()} רוצה לראות מבצע למצטרפים חדשים להיום בלבד?
                <Link to="/Subscribe"> הנה כאן </Link>
              </div>
            </div>
            <br></br>
            <div className="toggle-line">
              <label className="toggle">
                <input
                  type="checkbox"
                  onChange={onFreezeUse}
                  checked={!inFreezeUse}
                />
                <span className="slider-in-toggle"></span>
              </label>

              <div className="lbl">
                {inFreezeUse
                  ? " סליידר לחזור לשימוש באתר "
                  : "סליידר להשהיית שימוש"}
              </div>
            </div>
            {isErrorMessageDisplayed && (
              <div className="error-message">
                <h5>{errorMessage} </h5>
              </div>
            )}

            <Link to="/questionnaires" style={{ textDecoration: "none" }}>
              <div className="button" id="backBtn">
                {" "}
                חזרה
              </div>
            </Link>
          </div>
        </>
      ) : (
        <div className="loading-data-container">
          <div className="loading-data-div">
            <Ellipsis />
            <h4>טוען את המידע שלך</h4>
          </div>
        </div>
      )}
    </form>
  );
};

export default MyProfile;
