import React from "react";

const ContactUsHelper = () => {
  return (
    <div className="contactUsHelper">
      <br />
      <div className="faceline">
        <a
          className="h-nomargin widthfix120"
          href="https://www.facebook.com/%D7%93%D7%90%D7%91%D7%9C-%D7%94%D7%9B%D7%A8%D7%95%D7%99%D7%95%D7%AA-103072662171430"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          דאבל בפייסבוק
        </a>
        <a
          href="https://www.facebook.com/%D7%93%D7%90%D7%91%D7%9C-%D7%94%D7%9B%D7%A8%D7%95%D7%99%D7%95%D7%AA-103072662171430"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="facebook"></div>
        </a>
      </div>
      <div className="faceline">
        <a
          className="h-nomargin widthfix120"
          href="https://instagram.com/double2love.ai?igshid=MzNlNGNkZWQ4Mg=="
          target="_blank"
          rel="noopener noreferrer"
        >
          דאבל באינסטגרם
        </a>
        <a
          href="https://instagram.com/double2love.ai?igshid=MzNlNGNkZWQ4Mg=="
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="instegramrealicon"></div>
        </a>
      </div>

      <div className="faceline">
        <a
          className="h-nomargin widthfix120"
          href={"https://wa.me/972549624084"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          בווטסאפ
        </a>
        <a
          href={"https://wa.me/972549624084"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="whatsupp"></div>
        </a>
      </div>
      <div className="faceline">
        <a
          className="h-nomargin widthfix120"
          href={"mailto: doublelo2love@gmail.com"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          ואפשר ליצור קשר באימייל
        </a>
        <a
          href={"mailto: doublelo2love@gmail.com"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="gmail"></div>
        </a>
      </div>
    </div>
  );
};

export default ContactUsHelper;
