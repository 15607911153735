import React from "react";

const JobOrFunSaritShavit = () => {
  return (
    // <div className="terms-and-conditions-container">
    // {/* className="terms-and-conditions-p" */}

    <div className="">
      <b> דייט אצלכם זה עבודה או כיף?!</b>
      <br />
      <br />
      וידוי: היינו בדרך לתחנה בסיום הלימודים, כשפתאום ירון מסתכל עלי ואומר - -
      מה יש לך? אתה נראה בבאסה כללית
      <br />
      - .. עזוב,
      <br />
      - נו, מה הסיפור?
      <br />
      - יש לי דייט הערב. ואתה יודע באסה.
      <br />-<b>דייט זה באסה? </b>
      לא מבין אתכם הדתיים.. דייט זה כיף! דייט זה בילוי!
      <br />
      השיחה טלטלה אותי לגמרי.
      <br />
      הוא דרך על תחושה כואבת שאני מרגיש מזמן, אני רוצה להתחתן, אבל מתי פגישה עם
      בחורה הפכה ל'עבודה'? אז אני מעדיף לנוח..
      <br />
      היה משהו קליל אצל החבר שלי. סגנון שרציתי להיות בו.
      <br />
      לדייט של אותו ערב, חייב להודות, זה לא עזר. אבל אז שחררתי איזה דפייקה
      שהתיישבה אצלי.
      <br />
      אני לא רוצה יותר
      <b> להיפגש כאילו זה שיעורי בית, </b>
      אני רוצה להכיר בחורה שהתרשמתי שהיא סבבה. אכיר אותה, ואם יהיה נעים נתקדם
      צעד צעד.
      <br />
      התובנה גררה שינוי שהיה ממש מהפיכה עבורי.
      <br />
      הבחורה השניה שהכרתי אחרי ה'שטיפה' שקיבלתי, הפכה להיות חברה שלי,
      <br />
      אח'כ ארוסתי,
      <br />
      וכיום ב'ה אשתי.
      <br />
      <br />
      איך אצלכם - דייט זה משימה? או כיף?
      <br />
      מציעה לנסות לשנות את הגישה ותראו נפלאות!
    </div>
  );
};

export default JobOrFunSaritShavit;
