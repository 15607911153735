import { useState } from "react";
import { singletonHook } from "react-singleton-hook";

const initRoom = 0;
let currentRoom = initRoom;
let globalSetRoom = () => {
  throw new Error("you must UseRoom before setting its state");
};

export const UseRoom = singletonHook(initRoom, () => {
  const [room, setRoom] = useState(initRoom);
  globalSetRoom = setRoom;
  currentRoom = room;
  return room;
});

export const setRoom = (room) => globalSetRoom(room);

export const getRoom = () => currentRoom;
