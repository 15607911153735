import React from "react";

const SecretSuccessEliGaron = () => {
  return (
    // <div className="terms-and-conditions-container">
    // {/* className="terms-and-conditions-p" */}

    <div className="">
      <b> הסוד הכי חשוב להצלחה בדייטים</b>
      <br />
      <br />
      כשאתם יוצאים לדייטים, אתם יוצאים לאיזה טיול נחמד, אפשר בקלות להתפעל מהנוף.
      אם זה מהנוף הפתוח, או מהצלע של ההר.
      <br />
      אבל בעיקר בדייט צריך להתפעל מהיופי של הצד השני, צריך להתפעל מהיופי הנפשי
      שיש בצד השני.
      <br />
      אם אני הגבר והבחורה מספרת על עצמה, מה היא עושה בחיים, זה לא צריך להיכנס לי
      מאוזן אחת ולצאת מהאוזן השנייה. זה צריך לגרום לי להתפעל!
      <br />
      נניח שהיא מספרת על עצמה שהיא מורה. אתה צריך להגיד וואו!! איזה יופי! אני
      מאוד מעריך אנשי חינוך.
      <br />
      עבודה מצד אחד כל כך קשה, מצד שני לפחות מתגמלת. לפעמים גם בשכר, אבל בעיקר
      בקטע של משהו שיבוא ויעריך את מה שאתם עושים, את ההשקעה, את מיתרי הקול
      הכואבים שלכם. שמישהו יעריך אתכם.
      <br />
      לכן, כשהבחורה מורה, אתה צריך להגיד וואו! אני כל כך מעריך אנשי חינוך, ולחנך
      את הדור הבא זה לבנות את האנשים. מדהים! יפה שבחרת במקצוע הזה, זה מראה על
      כמה טוב יש בך בפנים, כמה נתינה יש לך. פשוט מדהים, מדהים, מדהים..
      <br />
      אולי קצת הגזמתי אבל זה בכדי להעביר את המסר. 😊
      <br />
      <br />
      אם אני אתייחס ככה למה שהצד השני מספר לי, אני אמצא את הנוף ואת היופי שבצד
      השני.
      <br />
      <b>אתם יודעים מה זה יעשה לי? זה יפתח לי את הלב.</b>
      <br />
      אם אני אראה דברים טובים בצד השני ולא רק באתי לדייט ואני בביקורתיות
      ושיפוטיות, היא מתאימה לי, היא לא מתאימה לי, זה מה שחיפשתי, זה לא מה
      שחיפשתי. אני אדע להעריך את הטוב שיש בצד השני, אני אדע להעריך את ההשקעה שיש
      בצד השני.
      <br />
      <br />
      ואז יקרו שני דברים:
      <br />
      1. הלב שלי ייפתח ואני אבחר בה.
      <br />
      2. זה שהיא תבחר בי. היא תגיד :וואו!! סוף סוף, יש מישהו שאוהב אותי! (לא
      יודע אם הוא אוהב אותי בינתיים) אבל שמעריך אותי, שאיכפת לו ממני, שיש לו עין
      טובה. אני רוצה קשר עם כזה בנאדם. אני רוצה קשר עם בן אדם שיודע לראות רחוק,
      שיודע לראות את היופי הפנימי שיש בי.
      <br />
      <br />
      אם ככה תדבר, ככה תתבטא ולא רק כי באת לדייט כדי לבחון את הצד השני, אין לי
      ספק! הקשר הזה יימשך, ואתם תבנו את הבית שלכם באהבה! הכל מתחיל מעין טובה.
    </div>
  );
};

export default SecretSuccessEliGaron;
