import React, { useEffect } from "react";
import { useHistory, useState } from "react-router-dom";
import "../../App.css";
import "./SuccessPayment.css";

import axios from "axios";

// import login from "../login/login.component";

const SuccessPayment = () => {
  let history = useHistory();

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  return (
    <form className="supermatchmaker-form">
      <div className="signup-body">
        <br />
        <br />
        <br />
        <br />
        <div className="h1-homepage" style={{ fontSize: "25px" }}>
          התשלום בוצע בהצלחה :)
        </div>
        <div className="h1-homepage">תודה על רכישתך, קבלה נשלחה למייל</div>
        <br />
      </div>
    </form>
  );
};

export default SuccessPayment;
