import React, { useEffect, useState, useRef } from "react";
import "./Bi.css";
import axios from "axios";
import { PieChart, Pie, Cell, Legend } from "recharts";

// import AdSense from "react-adsense";

const Bi = () => {
  const [age18, setAge18] = useState(10);
  const [age26, setAge26] = useState(12);
  const [age36, setAge36] = useState(9);
  const [age46, setAge46] = useState(7);
  const [total, setTotal] = useState(38);
  const [hared, setHared] = useState(10);
  const [torani, setTorani] = useState(17);
  const [dati, setDati] = useState(60);
  const [masoret, setMasoret] = useState(9);
  const [totalDat, setTotalDat] = useState(96);
  const [scrollIndex, setScrollIndex] = useState(1);
  const containerRef = useRef(null);
  const items = [1, 2];

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_LOCALHOST_ADDRESS + "/metadata/getStatAges"
        );
        if (response.data && response.data.total) {
          setAge18(response.data.age18);
          setAge26(response.data.age26);
          setAge36(response.data.age36);
          setAge46(response.data.age46);
          setTotal(response.data.total);
          setHared(response.data.hared);
          setTorani(response.data.torani);
          setDati(response.data.dati);
          setMasoret(response.data.masoret);
          setTotalDat(response.data.totalDat);
        }
      } catch (error) {
        console.log(error);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const item = containerRef.current.children[scrollIndex];
      // item.scrollIntoView({
      //   behavior: "smooth",
      //   inline: "center",
      // });
      // // const container = containerRef.current;
      // // const containerCenter = container.clientWidth / 2;
      // // const itemCenter = item.offsetLeft + item.clientWidth / 2;
      // // container.scrollLeft = itemCenter - containerCenter;
      const container = containerRef.current;
      const containerCenter = container.clientWidth / 2;
      const itemCenter = item.offsetLeft + item.clientWidth / 2;
      const scrollLeft = itemCenter - containerCenter;

      // Smooth scroll animation
      container.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });

      // if (scrollIndex >= items.length) {
      //   console.log("scrolling to start");
      //   setTimeout(() => {
      //     container.scrollLeft = 0;
      //     setScrollIndex(0);
      //   }, 3000);
      // }
    }
  }, [scrollIndex, items.length]);

  const data = [
    { name: " 18 - 25 ", value: age18 },
    { name: " 26 - 35 ", value: age26 },
    { name: " 36 - 45 ", value: age36 },
    { name: "  46 + ", value: age46 },
  ];

  const dataDat = [
    { name: " דתי, דתי לייט, דתי לאומי וחוזר בתשובה ", value: dati },
    { name: "  חרדי וחרדי פתוח ", value: hared },
    { name: 'דת"ל תורני וחרד"ל', value: torani },
    { name: 'מסורתי דתל"ש וחילוני', value: masoret },
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <h5
        className={window.innerWidth < 1100 ? "arrowleft" : ""}
        onClick={() => {
          setScrollIndex((prevIndex) => {
            const newValue = Math.max((prevIndex + 1) % (items.length - 1), 1);
            return newValue;
          });
        }}
      ></h5>
      <h5
        className={window.innerWidth < 1100 ? "arrowright" : ""}
        onClick={() => {
          setScrollIndex((prevIndex) => {
            if (prevIndex - 1 <= 0) {
              return items.length - 2;
            } else {
              return prevIndex - 1;
            }
          });
        }}
      ></h5>
      <div className="tellAboutUsContainer" ref={containerRef}>
        {/* <ResponsiveContainer width="100%" height="100%"> */}
        <div className="tellAboutUsSectionInScroll" style={{ width: "96vw" }}>
          <div
            className="h-nomargin"
            style={{
              margin: "0 auto",
              width: "100px",
              marginTop: "12px",
              marginBottom: "-35px",
            }}
          >
            {" "}
            <b>גילאים</b>
          </div>
          <PieChart className="mypie" width={380} height={270}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={120}
              innerRadius={40}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Legend
              layout="vertical"
              verticalAlign="top"
              align="right"
              width={"100px"}
              wrapperStyle={{ direction: "ltr", marginTop: "42px" }}
            />
          </PieChart>
        </div>
        <div className="tellAboutUsSectionInScroll" style={{ width: "96vw" }}>
          <div
            className="h-nomargin"
            style={{
              margin: "0 auto",
              width: "100px",
              marginTop: "12px",
              marginBottom: "-35px",
            }}
          >
            {" "}
            <b>סגנון דתי</b>
          </div>
          <PieChart className="mypie" width={380} height={270}>
            <Pie
              data={dataDat}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={120}
              innerRadius={40}
              fill="#8884d8"
              dataKey="value"
            >
              {dataDat.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Legend
              layout="vertical"
              verticalAlign="top"
              align="right"
              width={"100px"}
              wrapperStyle={{ direction: "ltr", marginTop: "42px" }}
            />
          </PieChart>
        </div>
      </div>
    </div>
  );
};

export default Bi;
