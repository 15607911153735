import React from "react";

const InstegramHelper = () => {
  return (
    // <div className="terms-and-conditions-container">
    // {/* className="terms-and-conditions-p" */}
    <div className="terms-and-conditions-p">
      פרופיל אינסטגרם לא ניתן להעתיק מהאפליקציה. הכנסו ל instagram.com בדפדפן
      היכנסו לפרופיל של עצמכם והעתיקו את הכתובת שמופיעה למעלה בדפדפן.
      <br />
      נ.ב. אם לא הצלחתם לצרף מהטלפון, תנסו מהמחשב.
    </div>
    // </div>
  );
};

export default InstegramHelper;
