import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./Miror.css";
import { Ellipsis } from "react-spinners-css";
import "../../slider.css";
import HeaderBackToEdit from "./../../components/Header/HeaderBackToEdit";
import { getGender } from "../../handlers/dictionaryHandler.js";
import axios from "axios";
import PopupExplainColors from "../../components/PopupExplainColors/PopupExplainColors";
import Cookies from "js-cookie";

const Miror = () => {
  let history = useHistory();
  const [myFace, setMyFace] = useState("");
  const [myShape, setMyShape] = useState("");
  const [partnerFace, setPartnerFace] = useState("");
  const [partnerShape, setPartnerShape] = useState("");
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [newUser, setNewUser] = useState(true);

  const alertUser = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage(message);
  };

  const handlePartnerFace = (val) => {
    if (!partnerFace.includes(val)) {
      setPartnerFace([...partnerFace, val]);
    } else {
      const tempPartner = partnerFace.filter((value) => value != val);
      setPartnerFace(tempPartner);
    }
  };

  const handlePartnerShape = (val) => {
    if (!partnerShape.includes(val)) {
      setPartnerShape([...partnerShape, val]);
    } else {
      const tempPartner = partnerShape.filter((value) => value != val);
      setPartnerShape(tempPartner);
    }
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  useEffect(async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PlookMiror",
        config
      );
      const userData = response.data;
      if (userData.face > 0) {
        setMyFace(userData.face);
        setMyShape(userData.shape);

        setPartnerFace(userData.partnerFace.split(","));
        setPartnerShape(userData.partnerShape.split(","));
        setIsDataLoaded(true);
        setNewUser(false);
      } else {
        setIsDataLoaded(true); // in case its a new user
      }
    } catch (error) {
      // probebly user is not authenticated or server is down
    }
  }, []);

  const onSubmit = async () => {
    if (partnerFace.length > 1 || partnerShape.length > 1) {
      if (myFace != "") {
        if (myShape != "") {
          if (partnerFace != "") {
            if (partnerShape != "") {
              try {
                const response = await axios.post(
                  process.env.REACT_APP_LOCALHOST_ADDRESS + "/PlookMiror",
                  {
                    myFace,
                    myShape,
                    partnerFace,
                    partnerShape,
                  },
                  config
                );
                if (response.data === true) {
                  // Cookies.get("newUser") == 1 // &&&&&&&
                  //   ? localStorage.setItem("fromMiror", 1)
                  //   : localStorage.setItem("fromMiror", 0);
                  // Cookies.get("newUser") == 1 // &&&&&&&
                  //   ? history.push("/aboutme") :
                  history.push("/pics");
                } else {
                  alertUser(
                    "יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית"
                  );
                }
              } catch (error) {
                alertUser(
                  "יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית"
                );
              }
            } else {
              alertUser("לא סימנת מראה מתאים (שורה רביעית");
              console.log("partnerShape did not set");
            }
          } else {
            alertUser("לא סימנת מראה מתאים (שורה שלישית)");
            console.log("partnerFace did not set");
          }
        } else {
          alertUser("לא סימנת מה המראה שלך (שורה שניה)");
          console.log("myShape did not set");
        }
      } else {
        alertUser("לא סימנת מה המראה שלך (שורה ראשונה)");
        console.log("myFace did not chosen");
      }
    } else {
      alertUser("יש לבחור עוד אפשרות במראה מתאים אצל בן זוג (שורה 3 או 4)");
      console.log("myFace did not chosen");
    }
  };

  return (
    <form className="mirror-form">
      <HeaderBackToEdit />
      {isDataLoaded === true ? (
        <>
          <br></br>
          <div className="page-title"> מראה שעל הקיר</div>

          <div className="miror-body">
            <div className="section-title">
              <b>מי אני מבין הדמויות</b>
            </div>
            <div className="section-title">בחרו אחת מהאופציות</div>
            <div className="parentMirror">
              <input
                type="button"
                // id={getGender() === "1" ? "hw1" : "fm1"}
                className={`mirorCube ${getGender() === "1" ? "hw1" : "fm1"}  ${
                  myFace === "1" ? "mirorCube-selected" : ""
                }`}
                onClick={(e) => setMyFace("1")}
                label={
                  getGender() === "1" ? "short strait hair" : "without beard"
                }
              />
              <input
                type="button"
                // id={getGender() === "1" ? "hw2" : "fm2"}
                className={`mirorCube ${getGender() === "1" ? "hw2" : "fm2"}  ${
                  myFace === "2" ? "mirorCube-selected" : ""
                }`}
                onClick={(e) => setMyFace("2")}
                label={getGender() === "1" ? "long strait hair" : "bristles"}
              />
              <input
                type="button"
                // id={getGender() === "1" ? "hw3" : "fm3"}
                className={`mirorCube ${getGender() === "1" ? "hw3" : "fm3"} ${
                  myFace === "3" ? "mirorCube-selected" : ""
                }`}
                onClick={(e) => setMyFace("3")}
                label={
                  getGender() === "1" ? "short curly hair" : "with a beard"
                }
              />
              <input
                type="button"
                // id={getGender() === "1" ? "hw4" : "fm4"}
                className={`mirorCube ${getGender() === "1" ? "hw4" : "fm4"} ${
                  myFace === "4" ? "mirorCube-selected" : ""
                }`}
                onClick={(e) => setMyFace("4")}
                label={
                  getGender() === "1" ? "long curly hair" : "with a long beard"
                }
              />
            </div>
            <div className="parentMirror">
              <input
                type="button"
                // id={getGender() === "1" ? "sw1" : "sm1"}
                className={`mirorCube ${getGender() === "1" ? "sw1" : "sm1"} ${
                  myShape === "1" ? "mirorCube-selected" : ""
                }`}
                onClick={(e) => setMyShape("1")}
                label="very thin"
              />
              <input
                type="button"
                // id={getGender() === "1" ? "sw2" : "sm2"}
                className={`mirorCube ${getGender() === "1" ? "sw2" : "sm2"} ${
                  myShape === "2" ? "mirorCube-selected" : ""
                }`}
                onClick={(e) => setMyShape("2")}
                label="Average build"
              />
              <input
                type="button"
                // id={getGender() === "1" ? "sw3" : "sm3"}
                className={`mirorCube ${getGender() === "1" ? "sw3" : "sm3"} ${
                  myShape === "3" ? "mirorCube-selected" : ""
                }`}
                onClick={(e) => setMyShape("3")}
                label="A little full"
              />
              <input
                type="button"
                // id={getGender() === "1" ? "sw4" : "sm4"}
                className={`mirorCube ${getGender() === "1" ? "sw4" : "sm4"} ${
                  myShape === "4" ? "mirorCube-selected" : ""
                }`}
                onClick={(e) => setMyShape("4")}
                label="Full"
              />
            </div>
            <div className="line" />
            {/* {getGender() === "1" ? renderphotoButtonsw() : renderphotoButtonsm()} */}
            {newUser ? (
              <div className="section-title">
                {" "}
                <PopupExplainColors />
              </div>
            ) : (
              ""
            )}
            <div className="section-title">
              <b>מי מתאים לי מבין הדמויות</b>
            </div>
            <h5 className="vipDescription">
              <b>ניתן (וכדאי) לסמן יותר מתשובה אחת</b>
            </h5>

            {/* {getGender() === "2" ? renderphotoButtonsw() : renderphotoButtonsm()} */}
            {/* <div
              className="mirorCube "
              style={{ backgroundImage: "url(/topadimgs/ex.png')" }}
            ></div> */}

            <div className="parentMirror">
              <input
                type="button"
                // id={getGender() === "1" ? "fm1" : "hw1"}
                className={`mirorCube ${getGender() === "1" ? "fm1" : "hw1"} ${
                  partnerFace.includes("1")
                    ? "mirorCube-selected"
                    : getGender() === "1"
                    ? "fm1"
                    : "hw1"
                }`}
                onClick={(e) => handlePartnerFace("1")}
                label={
                  getGender() === "1" ? "without beard" : "short strait hair"
                }
              />
              <input
                type="button"
                // id={getGender() === "1" ? "fm2" : "hw2"}
                className={`mirorCube ${getGender() === "1" ? "fm2" : "hw2"} ${
                  partnerFace.includes("2")
                    ? "mirorCube-selected"
                    : getGender() === "1"
                    ? "fm2"
                    : "hw2"
                }`}
                onClick={(e) => handlePartnerFace("2")}
                label={getGender() === "1" ? "bristles" : "long strait hair"}
              />
              <input
                type="button"
                // id={getGender() === "1" ? "fm3" : "hw3"}
                className={`mirorCube ${getGender() === "1" ? "fm3" : "hw3"} ${
                  partnerFace.includes("3")
                    ? "mirorCube-selected"
                    : getGender() === "1"
                    ? "fm3"
                    : "hw3"
                }`}
                onClick={(e) => handlePartnerFace("3")}
                label={
                  getGender() === "1" ? "with a beard" : "short curly hair"
                }
              />
              <input
                type="button"
                // id={getGender() === "1" ? "fm4" : "hw4"}
                className={`mirorCube ${getGender() === "1" ? "fm4" : "hw4"} ${
                  partnerFace.includes("4")
                    ? "mirorCube-selected"
                    : getGender() === "1"
                    ? "fm4"
                    : "hw4"
                }`}
                onClick={(e) => handlePartnerFace("4")}
                label={
                  getGender() === "1" ? "with a long beard" : "long curly hair"
                }
              />
            </div>
            <div className="parentMirror">
              <input
                type="button"
                // id={getGender() === "1" ? "sm1" : "sw1"}
                className={`mirorCube ${getGender() === "1" ? "sm1" : "sw1"} ${
                  partnerShape.includes("1")
                    ? "mirorCube-selected"
                    : getGender() === "1"
                    ? "sm1"
                    : "sw1"
                }`}
                onClick={(e) => handlePartnerShape("1")}
                label="very thin"
              />
              <input
                type="button"
                // id={getGender() === "1" ? "sm2" : "sw2"}
                className={`mirorCube ${getGender() === "1" ? "sm2" : "sw2"} ${
                  partnerShape.includes("2")
                    ? "mirorCube-selected"
                    : getGender() === "1"
                    ? "sm2"
                    : "sw2"
                }`}
                onClick={(e) => handlePartnerShape("2")}
                label="Average build"
              />
              <input
                type="button"
                // id={getGender() === "1" ? "sm3" : "sw3"}
                className={`mirorCube ${getGender() === "1" ? "sm3" : "sw3"} ${
                  partnerShape.includes("3")
                    ? "mirorCube-selected"
                    : getGender() === "1"
                    ? "sm3"
                    : "sw3"
                }`}
                onClick={(e) => handlePartnerShape("3")}
                label="A little full"
              />
              <input
                type="button"
                // id={getGender() === "1" ? "sm4" : "sw4"}
                className={`mirorCube ${getGender() === "1" ? "sm4" : "sw4"} ${
                  partnerShape.includes("4")
                    ? "mirorCube-selected"
                    : getGender() === "1"
                    ? "sm4"
                    : "sw4"
                }`}
                onClick={(e) => handlePartnerShape("4")}
                label="Full"
              />
            </div>
            <div className="line" />
            {isErrorMessageDisplayed && (
              <div className="error-message">
                <h5>{errorMessage} </h5>
              </div>
            )}

            <div
              className="button-text-center"
              id="btnSubmit"
              onClick={onSubmit}
            >
              שמירה והמשך
            </div>

            <Link to="/edit" style={{ textDecoration: "none" }}>
              <div className="button" id="backBtn">
                {" "}
                חזרה
              </div>
            </Link>
            <div className="lineDice" />
          </div>
        </>
      ) : (
        <div className="loading-data-container">
          <div className="loading-data-div">
            <Ellipsis />
            <h4>טוען את המידע שלך</h4>
          </div>
        </div>
      )}
    </form>
  );
};

export default Miror;
