import React, { useState } from "react";
import "../../App.css";
import "./PopupExplainColors.css";

const PopupExplainColors = () => {
  const [isPopupDisplayed, setIsPopupDisplayed] = useState(true);

  return (
    <div className={isPopupDisplayed ? "likePopup" : "invisible"}>
      <div
        className="exit-popup"
        onClick={() => {
          setIsPopupDisplayed(false);
        }}
      >
        x
      </div>
      <br />
      הי נשים לב לצבעים:
      <div className="faceline">
        <input type="button" name="dat" className="datil active" />
        מתאים לי!
      </div>
      <div className="faceline">
        <input type="button" name="dat" className="datil" />
        לא רלוונטי
      </div>
      <br />
    </div>
  );
};

export default PopupExplainColors;
