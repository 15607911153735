import React, { Fragment, useEffect, useRef, useState } from "react";
import axios from "axios";

const TellAboutUsHelper = () => {
  const [cntMatches, setCntMatches] = useState(650);
  const [scrollIndex, setScrollIndex] = useState(1);
  const containerRef = useRef(null);
  const [lastClickTime, setLastClickTime] = useState(null);
  const items = [
    // <div className="tellAboutUsSectionInScroll"></div>,
    // <div className="tellAboutUsSectionInScroll"></div>,
    <div className="traingleRight"></div>,

    <div className="tellAboutUsSectionInScroll">
      אמ;לק:
      <b> דאבל פגע בהתאמה הראשונה שלי! </b>
      שמעתי על מיזם דאבל, והאופן שבו הוא עובד מאוד התאים לי, ובמוצש כבר נרשמתי
      לאתר. בהתאמה הראשונה קיבלתי את י., ואחרי חמישה חודשים ברוך ה' התארסנו. אז
      תודה על אתר נעים למשתמש ושנותן תחושה אמיתית של התאמה אישית.
    </div>,
    <div className="traingleRight"></div>,
    <div className="tellAboutUsSectionInScroll">
      {" "}
      רואים כמה מחשבה הושקעה בכל פרט,
      <b> דבר שבא לידי ביטוי גם בניסוח וגם בתוכן. </b>
      למשל, התיבה המבארת את הדרך הנכונה כיצד לכתוב על אודותינו :) אהבתי ממש!
      תודה רבה על האתר דאבל הכרויות והלוואי שהוא יפרוץ ויופץ. אתם עושים משהו
      נכון. מעבר לזה שהוא בכמה רמות מעל אתרים אחרים בעיניי. א.
    </div>,
    <div className="traingleRight"></div>,

    <div className="tellAboutUsSectionInScroll">
      {" "}
      השוס בעיני שדאבל הכרויות
      <b> דיסקרטי, </b>
      אף אחד לא יודע שאני שם חוץ ממי שהוא הצעה. שזה ממש יפה בעיני. זה מעודד
      אנשים להיכנס. א. מ.
    </div>,
    <div className="traingleRight"></div>,

    <div className="tellAboutUsSectionInScroll">
      {" "}
      היי דאבל, תודה על רעיון טוב ואיכותי. יצאתי כבר עם 3 בנות משם. טוב שיש
      פלטפורמה שיודעת לתת
      <b> הצעות רלוונטיות וכמה שיותר מדוייקות. </b>
      כ.
    </div>,
    <div className="traingleRight"></div>,

    <div className="tellAboutUsSectionInScroll">
      {" "}
      הי, שוב, תודה על האתר דאבל :-) בינתיים הוא
      <b> הצליח להתאים יפה, </b>
      גם מצד התוכן, שזה ממש נחמד :-) ש.
    </div>,
    <div className="traingleRight"></div>,

    <div className="tellAboutUsSectionInScroll">
      {" "}
      תודה,
      <b> יוצא כבר מעל חודשיים </b>
      עם עלמת חמד שהכרתי אצלכם בדאבל. ג.
    </div>,
    <div className="traingleRight"></div>,

    <div className="tellAboutUsSectionInScroll">
      {" "}
      תמיד אני מסתבכת איך לכתוב על עצמי, ובדאבל
      <b> כתבתם שאלות שעוזרות לכתוב טקסט </b>
      ממש יפה על עצמי, פשוט כיף. מ.{" "}
    </div>,
    <div className="traingleRight"></div>,

    <div className="tellAboutUsSectionInScroll">
      ש ו- ש מירושלים
      <b> מתחתנים</b>: יצאנו בעבר לפני שנתיים וחצי ולא הלך ואז ההצעה לחזור עלתה
      שוב התלבטנו ובאותו שבוע גם דאבל הציע אז הבנו שזה מאלוקים ואמרנו שנינו כן
      והנה אנחנו כאן :)
    </div>,
    <div className="traingleRight"></div>,

    <div className="tellAboutUsSectionInScroll">
      <b> הפיצ'ר של ההקלטה הוא נהדר</b>
      🙂, מישהי אמרה לי כן רק בגללו 🙂 זה שאתה יכול לשמוע את הקול של האדם השני,
      זה מוריד חומות ומצמצם זרות.
    </div>,
    // <div className="tellAboutUsSectionInScroll"></div>,
    // <div className="tellAboutUsSectionInScroll"></div>,
  ];

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_LOCALHOST_ADDRESS + "/metadata/getCntMatches"
        );
        if (response.data && response.data.currentCntMatches) {
          setCntMatches(response.data.currentCntMatches);
        }
      } catch (error) {
        console.log(error);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    const now = new Date().getTime();
    const timeTillAutoScrollStartsAgain = 6000;
    const lastTimeClickedFinal = lastClickTime
      ? lastClickTime
      : new Date().getTime();
    const timeSinceClick = now - lastTimeClickedFinal;
    let autoScrollInterval = null;

    const timeout = setTimeout(
      () => {
        autoScrollInterval = setInterval(() => {
          setScrollIndex((prevIndex) => (prevIndex + 1) % items.length);
        }, 3 * 1000);
      },
      lastClickTime
        ? Math.max(timeTillAutoScrollStartsAgain - timeSinceClick, 0)
        : 0
    );
    return () => {
      if (autoScrollInterval) {
        clearInterval(autoScrollInterval);
      }
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [items.length, lastClickTime]);

  useEffect(() => {
    if (containerRef.current) {
      const item = containerRef.current.children[scrollIndex];
      // item.scrollIntoView({
      //   behavior: "smooth",
      //   inline: "center",
      // });
      // // const container = containerRef.current;
      // // const containerCenter = container.clientWidth / 2;
      // // const itemCenter = item.offsetLeft + item.clientWidth / 2;
      // // container.scrollLeft = itemCenter - containerCenter;
      const container = containerRef.current;
      const containerCenter = container.clientWidth / 2;
      const itemCenter = item.offsetLeft + item.clientWidth / 2;
      const scrollLeft = itemCenter - containerCenter;

      // Smooth scroll animation
      container.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });

      // if (scrollIndex >= items.length) {
      //   console.log("scrolling to start");
      //   setTimeout(() => {
      //     container.scrollLeft = 0;
      //     setScrollIndex(0);
      //   }, 3000);
      // }
    }
  }, [scrollIndex, items.length]);

  return (
    <div>
      <div className="tellAboutUsSection">
        <b> בינתיים נפגשו {cntMatches} זוגות מדאבל! </b>
      </div>
      <div
        style={{
          position: "relative",
        }}
      >
        <h5
          className="arrowleft"
          onClick={() => {
            setLastClickTime(new Date().getTime());
            setScrollIndex((prevIndex) => {
              const newValue = Math.max(
                (prevIndex + 1) % (items.length - 1),
                1
              );
              return newValue;
            });
          }}
        ></h5>
        <h5
          className="arrowright"
          onClick={() => {
            setLastClickTime(new Date().getTime());
            setScrollIndex((prevIndex) => {
              if (prevIndex - 1 <= 0) {
                return items.length - 2;
              } else {
                return prevIndex - 1;
              }
            });
          }}
        ></h5>
        <div className="tellAboutUsContainer" ref={containerRef}>
          {/* {items.concat(items).map((item, index) => {
          return <Fragment key={index}>{item}</Fragment>;
        })} */}
          {items}
        </div>
      </div>
    </div>
  );
};

export default TellAboutUsHelper;
