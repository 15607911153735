import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./PrevMatches.css";
import axios from "axios";
import {
  getMyChoice,
  getOtherChoice,
} from "../../handlers/dictionaryHandler.js";

const PrevMatches = () => {
  const [prevMatch, setPrevMatch] = useState([]);
  const [isPrevMatchesAreaOpen, setIsPrevMatchesAreaOpen] = useState(false);
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleTextReport = (text, pMatchId) => {
    setPrevMatch([
      ...prevMatch.map((prevMatch) => {
        if (prevMatch.match.prevMatchId === pMatchId) {
          return {
            ...prevMatch,
            match: {
              ...prevMatch.match,
              freeText: text,
            },
          };
        } else {
          return prevMatch;
        }
      }),
    ]);
  };

  const handleReportVal = (pMatchId, e) => {
    setPrevMatch([
      ...prevMatch.map((prevMatch) => {
        if (prevMatch.match.prevMatchId === pMatchId) {
          return {
            ...prevMatch,
            match: {
              ...prevMatch.match,
              report: e,
            },
          };
        } else {
          return prevMatch;
        }
      }),
    ]);
  };

  const prevMatchesAreaClick = () => {
    setIsPrevMatchesAreaOpen(!isPrevMatchesAreaOpen);
  };

  const alertUser = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage(message);
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  useEffect(async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/prevMatches",
        config
      );
      const userData = response.data.map((prevMatch) => {
        return {
          match: prevMatch,
          isOpen: false,
        };
      });
      setPrevMatch([...userData]);
    } catch (error) {
      // probebly user is not authenticated or server is down
    }
  }, []);

  const onSubmit = async (prevMatchSave) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/prevMatchReport",
        {
          idMatch: prevMatchSave.prevMatchId,
          report: prevMatchSave.report,
          freeText: prevMatchSave.freeText,
        },
        config
      );
      if (response.data === true) {
        alertUser("נשמר בהצלחה");
      }
    } catch (error) {
      alertUser("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
    }
  };

  return (
    <div className="">
      <div className="prev-matches-button" onClick={prevMatchesAreaClick}>
        התאמות קודמות - צפייה ומשוב
      </div>
      {isPrevMatchesAreaOpen && (
        <div className="containerPrevMatches">
          <div
            className="exit-popup"
            onClick={() => {
              setIsPrevMatchesAreaOpen(false);
            }}
          >
            x
          </div>
          <br />
          {/* <div className="messegeMatch">{doubleYes1}</div> */}

          {prevMatch.map((pMatch) => {
            return (
              <div key={pMatch.match.prevMatchId} style={{ height: "auto" }}>
                <div
                  className="prevMatchName"
                  onClick={() => {
                    // displayPrevMatchReportArea(pMatch.match.prevMatchId);
                    setPrevMatch([
                      ...prevMatch.map((prevMatch) => {
                        if (
                          prevMatch.match.prevMatchId ===
                          pMatch.match.prevMatchId
                        ) {
                          return {
                            ...prevMatch,
                            isOpen: !prevMatch.isOpen,
                          };
                        } else {
                          return prevMatch;
                        }
                      }),
                    ]);
                    alertUser("");
                  }}
                >
                  {pMatch.match.prevMatch.includes("-")
                    ? pMatch.match.prevMatch + ` ❤️ `
                    : pMatch.match.prevMatch}
                </div>
                {pMatch.isOpen && (
                  <div id={`prevMatchReportArea` + pMatch.match.prevMatchId}>
                    {" "}
                    <div className="lbl">
                      {pMatch.match.mySee != "1" ? "לא ראית את ההתאמה." : ""}
                      {pMatch.match.otherSee != "1"
                        ? "הצד השני לא ראה את ההתאמה."
                        : ""}
                      <br />
                      {"התשובה שלך: " + getMyChoice(pMatch.match.myResponse)}
                      <br />
                      {"תשובת הצד השני: "}{" "}
                      {pMatch.match.myResponse == "4"
                        ? "לא הגבת, אז אי אפשר לראות את התשובה של הצד השני"
                        : getOtherChoice(pMatch.match.otherResponse)}
                    </div>
                    <div
                      className="parent-dat"
                      style={{ alignItems: "center" }}
                    >
                      <input
                        type="button"
                        className={`prevMatchReportBtn ${
                          pMatch.match.report === 1 ? "active" : ""
                        }`}
                        value={"לא יצרנו קשר :( :("}
                        onClick={(e) =>
                          handleReportVal(pMatch.match.prevMatchId, 1)
                        }
                      />
                      <input
                        type="button"
                        className={`prevMatchReportBtn ${
                          pMatch.match.report === 2 ? " active" : ""
                        }`}
                        value={"בטלפון הבנו שלא מתאים"}
                        onClick={(e) =>
                          handleReportVal(pMatch.match.prevMatchId, 2)
                        }
                      />
                      <input
                        type="button"
                        className={`prevMatchReportBtn ${
                          pMatch.match.report === 3 ? " active" : ""
                        }`}
                        value={"יצאנו דייט אחד וזהו"}
                        onClick={(e) =>
                          handleReportVal(pMatch.match.prevMatchId, 3)
                        }
                      />
                      <input
                        type="button"
                        className={`prevMatchReportBtn ${
                          pMatch.match.report === 4 ? " active" : ""
                        }`}
                        value={"יצאנו בין 1 ל 4  דייטים"}
                        onClick={(e) =>
                          handleReportVal(pMatch.match.prevMatchId, 4)
                        }
                      />
                      <input
                        type="button"
                        className={`prevMatchReportBtn ${
                          pMatch.match.report === 5 ? "active" : ""
                        }`}
                        value={"נכנסנו לקשר ארוך"}
                        onClick={(e) =>
                          handleReportVal(pMatch.match.prevMatchId, 5)
                        }
                      />
                      <input
                        type="button"
                        className={`hatunaReportBtn ${
                          pMatch.match.report === 6 ? "active" : ""
                        }`}
                        value={"חתונה "}
                        onClick={(e) =>
                          handleReportVal(pMatch.match.prevMatchId, 6)
                        }
                      />
                      <input
                        type="button"
                        className={`longTextReportBtn ${
                          pMatch.match.report === 7 ? "active" : ""
                        }`}
                        value={"נפגשנו, התאור שונה מהמציאות"}
                        onClick={(e) =>
                          handleReportVal(pMatch.match.prevMatchId, 7)
                        }
                      />
                      <input
                        type="button"
                        className={`prevMatchReportBtn ${
                          pMatch.match.report === 8 ? "active" : ""
                        }`}
                        value={"הצעה לא קשורה..."}
                        onClick={(e) =>
                          handleReportVal(pMatch.match.prevMatchId, 8)
                        }
                      />
                      <input
                        type="button"
                        className={`longTextReportBtn ${
                          pMatch.match.report === 9 ? "active" : ""
                        }`}
                        value={"הצעה קשורה אך לא מתאימה"}
                        onClick={(e) =>
                          handleReportVal(pMatch.match.prevMatchId, 9)
                        }
                      />
                    </div>
                    <h5>משהו נוסף?</h5>
                    <div className="section-title">
                      {pMatch.match.freeText.length + "/100 "}{" "}
                    </div>
                    <div className="freetext-container">
                      <textarea
                        Style={"height:70px"}
                        className="freetext"
                        rows="3"
                        maxLength="100"
                        value={pMatch.match.freeText}
                        onChange={(e) =>
                          handleTextReport(
                            e.target.value,
                            pMatch.match.prevMatchId
                          )
                        }
                      ></textarea>
                    </div>
                    <div
                      className="btn-save-report"
                      onClick={() => {
                        onSubmit(pMatch.match);
                      }}
                    >
                      שמירה
                    </div>
                    {isErrorMessageDisplayed && (
                      <div className="error-message">
                        <h5>{errorMessage} </h5>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PrevMatches;
