import React, { useState } from "react";
import "./Header.css";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useHistory } from "react-router-dom";
import {
  getSuperMatchMaker,
  getDoubleCounsler,
  getVip,
  getFirstName,
  getGender,
  getSignedIn,
  getExpiredVip,
  getStarCount,
  // getFriendlyTag,
} from "../../handlers/dictionaryHandler";
import DateCount from "./DateCount";

const Header = () => {
  let history = useHistory();
  const [starExplain, setStarExplain] = useState(false);
  const [vipExplain, setVipExplain] = useState(false);

  const humburgerClicked = () => {
    if (document.getElementById("slidepanel1").style.width === "200px") {
      document.getElementById("slidepanel1").style.width = "0px";
    } else {
      document.getElementById("slidepanel1").style.width = "200px";
    }
  };

  const aboutDoubleClicked = () => {
    history.push("/aboutdouble");
  };

  const contactUsClicked = () => {
    history.push("/contactus");
  };

  const tellAboutUsClicked = () => {
    history.push("/tellaboutus");
  };

  const qaClicked = () => {
    history.push("/qa");
  };

  const subscribeClicked = () => {
    history.push("/subscribe");
  };

  const myProfileClicked = () => {
    history.push("/myprofile");
  };

  const chngPwdClicked = () => {
    history.push("/changepassword");
  };

  const almostClicked = () => {
    history.push("/almost");
  };

  const homePageClicked = () => {
    humburgerClicked();
    history.push("/Questionnaires");
  };

  const collectStarsClicked = () => {
    history.push("/collectstars");
  };

  const superMatchMakerClicked = () => {
    history.push("/supermatchmaker");
  };

  return (
    <div className="header">
      <div
        className={getVip() === "0" ? "lbl_grey" : "invisible"}
        onClick={() => setVipExplain(true)}
      >
        <u>למה כדאי להיות מנוי?</u>
      </div>

      <Popup
        open={vipExplain}
        contentStyle={{ minWidth: "35%", maxWidth: "45%", borderRadius: "5px" }}
        position="top center"
        onClose={() => setVipExplain(false)}
        closeOnDocumentClick
        closeOnEscape
      >
        <div
          className="exit-popup"
          onClick={() => {
            setVipExplain(false);
          }}
        >
          x
        </div>
        <div
          style={{
            textAlign: "center",
            marginBottom: "-15px",
            fontSize: "medium",
          }}
        >
          <b>למנויים יש יותר דייטים!</b>
        </div>
        <br /> <br />
        <DateCount />
        <br />
      </Popup>

      <div className={getVip() === "0" ? "invisible" : "lbl_grey"}>
        מנוי עד: {getExpiredVip()}
      </div>
      {/* <div
        className={getFriendlyTag() === "1" ? "friendlytag" : "invisible"}
      ></div> */}
      <div className="starTag" onClick={() => setStarExplain(true)}>
        <div
          style={{
            lineHeight: "46px",
            margin: "0 auto",
            textAlign: "center",
            fontSize: "10px",
          }}
          className="lbl_grey"
        >
          {" "}
          {getStarCount()}{" "}
        </div>
      </div>
      <Popup
        open={starExplain}
        contentStyle={{ minWidth: "30%", maxWidth: "40%", borderRadius: "5px" }}
        position="top center"
        onClose={() => setStarExplain(false)}
        closeOnDocumentClick
        closeOnEscape
      >
        <div
          className="exit-popup"
          onClick={() => {
            setStarExplain(false);
          }}
        >
          x
        </div>
        הי! יש לך {getStarCount()} נקודות!!
      </Popup>
      {/* <div
        className={getSignedIn() === "1" ? (getVip() === "0" ? "" : "vip") : ""}
      ></div> */}
      {/* <div className="space"></div> */}
      <div className="lbl_grey"> שלום {getFirstName()}</div>

      <div
        className={
          getSignedIn() === "1"
            ? getGender() === "1"
              ? "aboutmeiconw"
              : "aboutmeiconm"
            : ""
        }
        onClick={myProfileClicked}
      ></div>

      <div id="slidepanel1">
        <div
          onClick={humburgerClicked}
          style={{ fontSize: "22px", margin: "5px", cursor: "pointer" }}
        >
          {" "}
          X{" "}
        </div>
        {/* <div className="button-slidepanel" onClick={aboutDoubleClicked}>
          אודות
        </div> */}
        <div className="button-slidepanel" onClick={contactUsClicked}>
          יצירת קשר
        </div>
        <div className="button-slidepanel" onClick={tellAboutUsClicked}>
          כותבים לנו
        </div>
        <div className="button-slidepanel" onClick={qaClicked}>
          שו"ת
        </div>
        <div className="button-slidepanel" onClick={chngPwdClicked}>
          שינוי סיסמה
        </div>
        <div className="button-slidepanel" onClick={subscribeClicked}>
          רכישת מנוי
        </div>
        <div className="button-slidepanel" onClick={myProfileClicked}>
          הפרופיל שלי
        </div>
        <div className="button-slidepanel" onClick={almostClicked}>
          כמעט מתאים
        </div>
        {/* <div className="button-slidepanel">
          <a
            href="whatsapp://send/?text=הי מה המצב? אני רוצה להמליץ לך לנסות אתר קצת שונה וממש שווה, הוא שדכן וירטואלי.. זה הקישור: https://double2love.com"
            style={{ textDecoration: "none", color: "var(--btnText-color)" }}
          >
            שיתוף בווטסאפ
          </a>
        </div> */}
        <div className="button-slidepanel" onClick={collectStarsClicked}>
          צבירת נקודות
        </div>
        <div className="button-slidepanel" onClick={homePageClicked}>
          דף הבית
        </div>

        <div
          className={
            getDoubleCounsler() === "0" ? " invisible" : " button-slidepanel"
          }
          onClick={superMatchMakerClicked}
        >
          {getSuperMatchMaker() === "1" ? " כניסת מנהל" : " דאבל הלפ"}
        </div>
      </div>
      {/* <div
        className="info-double-date"
        onClick={() => setIsAboutDisplayed(true)}
      ></div>
      <Popup
        open={isAboutDisplayed}
        contentStyle={{ minWidth: "30%", maxWidth: "60%", borderRadius: "5px" }}
        position="right center"
        onClose={() => setIsAboutDisplayed(false)}
        closeOnDocumentClick
        closeOnEscape
      >
        {AboutHelper}
      </Popup> */}
      {/* <div className="help" onClick={() => setIsHelpDisplayed(true)}></div>
      <Popup
        open={isHelpDisplayed}
        contentStyle={{ minWidth: "30%", maxWidth: "60%", borderRadius: "5px" }}
        position="right center"
        onClose={() => setIsHelpDisplayed(false)}
        closeOnDocumentClick
        closeOnEscape
      >
        {HelpHelper}
      </Popup> */}

      {/* <div className="running"> אנחנו בהרצה, תהיו סבלניים :)</div> */}

      <div className="hamburger" onClick={humburgerClicked}></div>
    </div>
  );
};

export default Header;
