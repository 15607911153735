import React, { useEffect, useState } from "react";
import Autosuggest from 'react-autosuggest';
import "./CitiesSearch.css";
import axios from 'axios';
// import { match as AutosuggestHighlightMatch, parse as AutosuggestHighlightParse } from "autosuggest-highlight"
const AutosuggestHighlightMatch = require('autosuggest-highlight/match');
const AutosuggestHighlightParse = require('autosuggest-highlight/parse');

const CitiesSearch = ({ onSelect, startValue }) => {
    const [cities, setCities] = useState([])
    const [value, setValue] = useState(startValue)
    const [suggestions, setSuggestions] = useState([])
    const maxCitySuggestions = 10
    useEffect(() => {
        const config = {
            method: 'get',
            url: `${process.env.REACT_APP_LOCALHOST_ADDRESS}/cities`,
        };

        axios(config)
            .then((response) => {

                setCities(response.data)
            })
            .catch((error) => {
                console.log(error);
            });

    }, [])

    // Teach Autosuggest how to calculate suggestions for any given input value.
    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength === 0 ? [] : cities.filter(lang =>
            lang.City.toLowerCase().slice(0, inputLength) === inputValue
        ).slice(0, maxCitySuggestions);
    };

    const inputProps = {
        placeholder: 'עיר מגורים',
        value,
        onChange: (event, { newValue }) => {
            onSelect(newValue, cities.map(city => city.City).includes(newValue))
            setValue(newValue)
        }
    };

    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={({ value }) => {

                setSuggestions(getSuggestions(value))
            }}
            onSuggestionsClearRequested={() => setSuggestions([])}
            getSuggestionValue={suggestion => suggestion.City}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
        />
    );
};

export default CitiesSearch;

const renderSuggestion = (suggestion, { query }) => {
    const matches = AutosuggestHighlightMatch(suggestion.City, query);
    const parts = AutosuggestHighlightParse(suggestion.City, matches);

    return (
        <span>
            {parts.map((part, index) => {
                const className = part.highlight ? 'react-autosuggest__suggestion-match' : null;

                return (
                    <span className={className} key={index}>
                        {part.text}
                    </span>
                );
            })}
        </span>
    );
}