import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./Almost.css";
import { getHared } from "../../handlers/dictionaryHandler.js";
import HeaderBack from "../../components/Header/HeaderBack";
import axios from "axios";

const Almost = () => {
  let history = useHistory();
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const paramStart = [
    {
      paramId: "1",
      title: "גובה",
      exact: "0",
    },
    {
      paramId: "2",
      title: "גיל",
      exact: "0",
    },
    {
      paramId: "3",
      title: "מרחק",
      exact: "0",
    },
    {
      paramId: "4",
      title: "מסגרת אחרונה",
      exact: "0",
    },
    {
      paramId: "5",
      title: "סטטוס",
      exact: "0",
    },
  ];
  const [param, setParam] = useState(paramStart);

  const alertUser = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage(message);
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const saveParam = async (saveParam) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/saveParamAlmost",
        {
          paramId: saveParam.paramId,
          exact: saveParam.exact === "1" ? "0" : "1",
        },
        config
      );
      // console.log(exactsArr + param.paramId + param.exact);
      if (response.data === true) {
        setParam([
          ...param.map((param) => {
            if (param.paramId === saveParam.paramId) {
              return {
                paramId: param.paramId,
                title: param.title,
                exact: saveParam.exact === "1" ? "0" : "1",
              };
            } else {
              return param;
            }
          }),
        ]);
        alertUser("נשמר בהצלחה");
      }
    } catch (error) {
      alertUser("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
    }
  };

  useEffect(async () => {
    {
      try {
        const response = await axios.get(
          process.env.REACT_APP_LOCALHOST_ADDRESS + "/PalmostGet",
          config
        );
        const userData = response.data;

        if (userData.length > 0) {
          console.log(userData);
          const fromDB = Object.keys(userData).map(
            (key) => userData[key].paramNotChange - 1
          );
          console.log(fromDB);

          const tempParams = paramStart.map((param, index) => {
            if (fromDB.includes(index)) {
              return {
                paramId: param.paramId,
                title: param.title,
                exact: "1",
              };
            } else {
              return param;
            }
          });
          setParam(tempParams);
        }
      } catch (error) {
        // probebly user is not authenticated or server is down
      }
    }
  }, []);

  // setParam([
  //   ...param.map((param) => {
  //     if (param.paramId === "2") {
  //       return {
  //         paramId: param.paramId,
  //         title: param.title,
  //         exact: "0",
  //       };
  //     } else {
  //       return param;
  //     }
  //   }),
  // ]);

  return (
    <div className="aboutdouble-full-screen">
      <HeaderBack />
      <br />
      <form className="almost-form">
        <h2 className="h-nomargin"> כמעט מתאים </h2>
        <div className="lbl">
          דאבל יחפש לך התאמות כפי שהגדרת, על מנת להגדיל את אפשרויות ההתאמה, יתכן
          שאחד מהפרמטרים הבאים לא יהיה
          <b> בדיוק </b>
          כפי שהגדרת (אבל לא מאוד רחוק..) וכל פעם אפשר שרק אחד מהפרמטרים לא יהיה
          מדויק.
          <br />
          אם יש פרמטר שבשום אופן אי אפשר להתגמש בו - אז כדאי לסמן כאן.
          <br />
          אגב, זו הזדמנות לעבור על מה שסימנת ב
          <br />
          <Link to="/aboutme">{"מי אני"}</Link>
          <br />
          <Link to="/aboutpartner">{"מי מתאים לי"}</Link>
          <br />
          <Link to="/miror">{"מראה"}</Link>
          <br />
          {" ולראות שזה עדכני"}
        </div>
        <br />

        {param.map((cur) => {
          return (
            <div
              key={cur.paramId}
              className={
                (getHared() === 0) & (cur.paramId === "4")
                  ? "invisible"
                  : "paramAlmost"
              }
            >
              <br />
              <h4> {cur.title}</h4>
              {cur.paramId === "5" ? (
                <div
                  className="lbl"
                  style={{
                    fontSize: "12px",
                    marginTop: "-16px",
                    marginBottom: "12px",
                  }}
                >
                  * אם סימנת רווק יתכן גם גרוש ללא ילדים, ולהיפך
                </div>
              ) : (
                <></>
              )}
              <div className="toggle-line">
                <div className="lbl"> בדיוק כפי שהגדרתי </div>
                <label className="toggle">
                  <input
                    type="checkbox"
                    onChange={() => {
                      saveParam(cur);
                    }}
                    checked={cur.exact === "1" ? true : false}
                  />
                  <span className="slider-in-toggle"></span>
                </label>
                <div className="lbl"> אפשר כמעט מתאים </div>
              </div>
            </div>
          );
        })}
        <br />
        {isErrorMessageDisplayed && (
          <div className="error-message">
            <h5>{errorMessage} </h5>
          </div>
        )}
        <Link to={"/questionnaires"} style={{ textDecoration: "none" }}>
          <div className="button" id="backBtn">
            {" "}
            חזרה
          </div>
        </Link>
      </form>{" "}
    </div>
  );
};

export default Almost;
