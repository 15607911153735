import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";

import axios from "axios";

// import login from "../login/login.component";

const FailurePayment = () => {
  let history = useHistory();

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const showDetailsByCell = async (cell) => {
    try {
      console.log(cell);
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/getDetailsByCell",
        { cell },
        config
      );
      const userData = response.data;

      if (userData[0].length > 0) {
      }
    } catch (error) {
      // probebly user is not authenticated or server is down
    }
  };

  return (
    <form className="supermatchmaker-form">
      <div className="signup-body">
        <br />
        <br />
        <br />
        <br />
        <div className="h1-homepage" style={{ fontSize: "25px" }}>
          התשלום נכשל...
        </div>
        <div className="h1-homepage">אנא נסה שנית, או צור קשר בווטסאפ</div>
        <br />
      </div>
    </form>
  );
};

export default FailurePayment;
