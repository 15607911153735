import React from "react";

const AboutHelper = () => {
  return (
    // <div className="terms-and-conditions-container">
    // {/* className="terms-and-conditions-p" */}

    <div className="terms-and-conditions-p">
      {/* <div className="tellAboutUsSection"> */}
      היי, נעים להכיר
      <br />
      <h3>דאבל</h3>
      <b>“מי אתה בכלל?"</b>
      <br />
      <b>בסטי </b>
      - החבר השדכן שלך עד החתונה. שזוכר אותך מידי שבוע, ביום שלישי, כן אני זוכר
      אותך, מחפש לך התאמה, ומעדכן אם מצאתי.
      <br />
      <br />
      <b>“התעייפתי כבר מלבדוק כל הזמן ולחפש לעצמי”</b>
      <br />
      אין בעיה, דאבל מחפש לך, וכשתמצא לך התאמה, יישלח על כך מייל, אין צורך
      להיכנס כל רגע ולבדוק. כמובן אם אתם רוצים לבדוק את ההגדרות שלכם, ולעדכן
      אותם, או את מי אתם מחפשים. זה אפשרי ואפילו כדאי.
      <br />
      <br />
      <b>”האם גם בדאבל אכנס למאגר ואהפוך לכרטיס?”</b>
      <br />
      לא. כאן מכבדים את הפרטיות שלך, לא תהיו חלק ממאגר הניתן לצפיה ודיפדוף, רק
      כשהמערכת מוצאת התאמה, היא שולחת את הפרטים שמילאת להתאמה שלך בלבד.
      <br />
      אנחנו מעדיפים להתמקד ולהתייחס יותר לכל הצעה. ופחות לדפדף.
      <br />
      <br />
      <b>"האם שולחים רק לי את ההתאמה, ולמי שולחים קודם?“</b>
      <br />
      ההתאמות הן תמיד לשני הצדדים.
      <br />
      והן רק בתנאי ששניכם סימנתם שמחפשים האחד את השניה, זאת אומרת: לא תקבלו
      (לדוגמה) הצעה בטווח גיל שלא מקובל עליכם.
      <br />
      וגם כשתקבלו הצעה - שני הצדדים מקבלים אותה.
      <br />
      במידה ושניכם אומרים כן - תקבלו על כך התראה במייל, וגם תראו זאת באתר.
      <br />
      <br />
      <b> ”טוב, הבנתי, אז איך בעצם ההתאמות עובדות?“</b>
      <br />
      בתחילת יום שלישי ירוץ אלגוריתם שיבצע התאמות.
      <br />
      ההתאמות הן לפי הגדרות גיל מרחק גובה וכד‘, שיש לכולם. וגם לפי מדדי אופי.
      <br />
      עד יום שישי יוצגו ההתאמות ותסמנו אם מתאים לכם להכיר.
      <br />
      אם שני הצדדים יסמנו שרוצים להכיר - דאבל התאמה!
      <br />
      יוצגו מספרי הטלפון וגם תקבלו על כך מייל.
      <br />
      אם תסמנו אולי בהמשך - יתכן שבהמשך תקבלו שוב את ההתאמה. (כמובן בתנאי שהצד
      השני לא אמר שלא מתאים לו..)
      <br />
      שימו לב- אם קיבלתם התאמה, גם הצד השני קיבל אתכם, וממתין לתשובה.
      <br />
      <br />
      <b> "לא לגמרי הבנתי את ההתאמות לפי אופי, אפשר בבקשה פירוט?“ </b>
      <br />
      הגדרנו כמה סולמות אופי (ובהמשך נוסיף עוד).
      <br />
      בסולמות אתם ממקמים את עצמכם, ואת מי מתאים לכם, בטווח של 1-7.
      <br />
      ע“מ שתהיה התאמה נאפשר סטיה של מספר אחד למעלה או מספר אחד למטה. נניח סימנת
      על תכונה מסויימת שמתאים לך 5, ניתן יהיה לקבל התאמה גם של 4, וגם של 6.
      <br />
      בנוסף, במדדי האופי נבדק שרוב מדדי האופי הם כפי שסימנתם, לא בהכרח כולם.
      <br />
      <br />
      ----------------------------------
      <br />
      האתר בפיתוח כל הזמן, ודאבל פתוח לשינויים והתאמות.
      <br />
      ומתוכננות הפתעות נוספות.
      <br />
      דאבל הוא תוכנה, וכמו תוכנה - לא מתעייף.
    </div>
  );
};

export default AboutHelper;
