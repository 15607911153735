import React, { useRef, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./ForumRooms.css";
import { Ellipsis } from "react-spinners-css";
import axios from "axios";
import Header from "../../components/Header/Header";
import Popup from "reactjs-popup";
import ForumRules from "../../components/ForumRules/ForumRules";
import useScrollPosition from "use-scroll-position";
import { UseRoom, setRoom } from "../../handlers/chatRoomsHandler";
import { Button } from "rsuite";
import Linkify from "react-linkify";
import FooterForum from "../../components/Footer/FooterForum";
import Footer from "../../components/Footer/Footer";

const ForumRooms = () => {
  const [isForumRulesDisplayed, setIsForumRulesDisplayed] = useState(false);
  const room = UseRoom();
  const chooseRoom = (room) => {};
  const history = useHistory();
  const [isChangeNicknamePopupDisplayed, setIsChangeNicknamePopupDisplayed] =
    useState(false);

  const [isChangeNicknameErrorDisplayed, setIsChangeNicknameErrorDisplayed] =
    useState(false);
  const [newNickname, setNewNickname] = useState("");
  const [errorMessageInResetPassword, setErrorMessageInResetPassword] =
    useState("");
  const [isNicknameChangedSuccessfully, setIsNicknameChangedSuccessfully] =
    useState(null);

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const changeNicknameClicked = async () => {
    if (newNickname !== "דאבל") {
      if (newNickname.length > 1 && newNickname != "") {
        try {
          const response = await axios.post(
            process.env.REACT_APP_LOCALHOST_ADDRESS + "/pChangeNickname",
            { nick: newNickname },
            config
          );

          if (response.status === 200) {
            setIsChangeNicknameErrorDisplayed(false);
            setIsNicknameChangedSuccessfully(true);
            setInterval(() => {
              setIsChangeNicknamePopupDisplayed(false);
              setIsNicknameChangedSuccessfully(null);
            }, 5000);
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            setIsChangeNicknameErrorDisplayed(true);
            setErrorMessageInResetPassword(
              "ארעה תקלה זמנית, יש לנסות שוב בהמשך"
            );
          }
        }
      } else {
        setIsChangeNicknameErrorDisplayed(true);
        setErrorMessageInResetPassword("שם קצר מידי");
      }
    } else {
      setIsChangeNicknameErrorDisplayed(true);
      setErrorMessageInResetPassword("שם שמור, יש לבחור שם אחר");
    }
  };

  return (
    <form className="forum-main-form">
      <Header />

      <br></br>
      <div id="h1">הפורום</div>

      <div className="form-body">
        <div className="messegeMatch">
          <div
            className="infoicon-empty"
            onClick={() => setIsForumRulesDisplayed(true)}
          />
          <div
            className="nickicon"
            onClick={() => setIsChangeNicknamePopupDisplayed(true)}
          />
        </div>
        <Popup
          open={isForumRulesDisplayed}
          contentStyle={{
            minWidth: "30%",
            maxWidth: "60%",
            borderRadius: "5px",
          }}
          position="right center"
          onClose={() => setIsForumRulesDisplayed(false)}
          closeOnDocumentClick
          closeOnEscape
        >
          {ForumRules}
        </Popup>
        <Popup
          open={isChangeNicknamePopupDisplayed}
          contentStyle={{
            minWidth: "30%",
            maxWidth: "60%",
            borderRadius: "5px",
          }}
          position="right center"
          onClose={() => setIsChangeNicknamePopupDisplayed(false)}
          closeOnDocumentClick
          closeOnEscape
        >
          <div className="change-nickname-container">
            {isNicknameChangedSuccessfully == true ? (
              <h4>החלפת את הכינוי :)</h4>
            ) : (
              <>
                <div className="change-nickname-title">
                  אני רוצה כינוי חדש בפורום
                </div>

                <input
                  className="change-nickname-email-input"
                  type="name"
                  id="name"
                  maxlength={7}
                  value={newNickname}
                  autocomplete="off"
                  placeholder="כינוי"
                  onChange={(e) => {
                    setIsChangeNicknameErrorDisplayed(false);
                    setNewNickname(e.target.value);
                  }}
                ></input>
                <div
                  className="change-nickname-popup-button"
                  onClick={changeNicknameClicked}
                >
                  אישור
                </div>
                {isChangeNicknameErrorDisplayed ? (
                  <h4>{errorMessageInResetPassword}</h4>
                ) : null}
              </>
            )}
          </div>
        </Popup>
        <div className="choose_room">
          <div
            className="choose-room-btn-long-text"
            onClick={() => {
              setRoom(4);
              localStorage.setItem("room", 4);
              history.push("/forummain");
            }}
          >
            הפינה של פנינה המומחית להתאמה זוגית
          </div>
          <div
            className="choose-room-btn"
            onClick={() => {
              setRoom(1);
              localStorage.setItem("room", 1);
              history.push("/forummain");
            }}
          >
            השאלה השבועית
          </div>
          <div
            className="choose-room-btn"
            onClick={() => {
              setRoom(2);
              localStorage.setItem("room", 2);
              history.push("/forummain");
            }}
          >
            שיתופים ותהיות
          </div>
          <div
            className="choose-room-btn"
            onClick={() => {
              setRoom(3);
              localStorage.setItem("room", 3);
              history.push("/forummain");
            }}
          >
            כל מיני
          </div>
        </div>

        <br></br>
        <br></br>

        <Link to="/questionnaires" style={{ textDecoration: "none" }}>
          <div className="button" id="backBtn">
            {" "}
            חזרה
          </div>
        </Link>
        <div className="lineDice" />
      </div>
      <Footer />
    </form>
  );
};

export default ForumRooms;
