import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./ChangePassword.css";
import axios from "axios";
import { sha256 } from "js-sha256";
import HeaderBack from "../../components/Header/HeaderBack";

const ChangePassword = () => {
  let history = useHistory();
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const maxPasswordLength = 16;
  const minPasswordLength = 8;

  const passwordValidatorRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/i;

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const passwordValidator = (password) => {
    if (
      password.length <= maxPasswordLength &&
      password.length >= minPasswordLength
    ) {
      if (passwordValidatorRegex.exec(password) !== null) {
        return true;
      }
    } else {
      return false;
    }
  };

  const confirmValidator = (password, confirmPassword) => {
    return password === confirmPassword;
  };

  const alertUser = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage(message);
  };

  const onSubmit = async () => {
    console.log("onSubmit " + oldPassword.length);
    if (oldPassword.length > 1 && oldPassword !== null) {
      if (passwordValidator(password)) {
        if (confirmValidator(password, confirmPassword)) {
          const response = await axios.post(
            process.env.REACT_APP_LOCALHOST_ADDRESS + "/PchngPass",
            {
              oldPassword: sha256(oldPassword),
              newPassword: sha256(password),
            },
            config
          );

          if (response.data[0][0].res >= 1) {
            alertUser("הסיסמה הוחלפה בהצלחה:)");
          } else {
            alertUser("הסיסמה הישנה לא נכונה");
            console.log("confirm Password is not valid");
          }
        } else {
          alertUser("הסיסמאות אינן תואמות");
          console.log("confirm Password is not valid");
        }
      } else {
        alertUser(
          `סיסמה חדשה לא תקינה, הסיסמה חייבת להיות בין ${minPasswordLength} ל ${maxPasswordLength} תווים, עם מספר ואות אחת באנגלית לפחות`
        );
        console.log("Password is not valid");
      }
    } else {
      alertUser(`יש למלא את הסיסמה הישנה`);
      console.log("Password is not valid");
    }
  };

  return (
    <div className="aboutdouble-full-screen">
      <HeaderBack />
      <br />
      <form className="aboutdouble-form">
        <h1 className="h1-homepage">בחירת סיסמה חדשה</h1>
        <div className="section-title">סיסמה ישנה</div>
        <input
          // type="password"
          id="oldPwd"
          value={oldPassword}
          autocomplete="off"
          placeholder="סיסמה ישנה"
          onChange={(e) => setOldPassword(e.target.value)}
        ></input>
        <br />
        <div className="section-title">
          סיסמה באורך 8 לפחות, תוים ומספרים (ללא תוים מיוחדים כמו @,# וכדומה)
        </div>
        <input
          // type="password"
          id="newPwd1"
          value={password}
          autocomplete="off"
          placeholder="סיסמה חדשה"
          onChange={(e) => setPassword(e.target.value)}
        ></input>
        <input
          // type="password"
          id="newPwd2"
          value={confirmPassword}
          autocomplete="off"
          placeholder="אימות סיסמה"
          onChange={(e) => setConfirmPassword(e.target.value)}
        ></input>
        <br />
        <div className="button-text-center" id="btnSubmit" onClick={onSubmit}>
          אישור
        </div>
        {isErrorMessageDisplayed && (
          <div className="error-message">
            <h5>{errorMessage} </h5>
          </div>
        )}

        <Link to="/questionnaires" style={{ textDecoration: "none" }}>
          <div className="button-text-center" id="backBtn">
            {" "}
            חזרה
          </div>
        </Link>
      </form>{" "}
    </div>
  );
};

export default ChangePassword;
