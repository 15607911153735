import axios from "axios";

const register = () => {
  const register = async () => {
    const publicKey =
      "BJuCqtHzPnVTqT4U6oHO4VYR4Krg7fxLhshWE5bFQgIIXKTaNNhXmeOQXmS2FIjrsQXq8tJDQuTTkoQAbVrP3no";

    // console.log("is secure:", window.isSecureContext);

    const reg = await navigator.serviceWorker.register(
      "registerNotificationScript.js",
      {
        scope: "/",
      }
    );

    // console.log("registered");

    // (B3) SUBSCRIBE TO PUSH SERVER
    let sub = await reg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: publicKey,
    });

    // console.log("Trying to send to /addme");
    // (B4) TEST PUSH
    // await fetch(
    //   `${process.env.REACT_APP_LOCALHOST_ADDRESS}/notifications/addme`,
    //   {
    //     method: "POST",
    //     body: JSON.stringify(sub),
    //     headers: { "content-type": "application/json" },
    //   }
    // );
    sub.isDelete = false;
    const response = await axios.post(
      `${process.env.REACT_APP_LOCALHOST_ADDRESS}/notifications/addme`,
      sub,
      {
        withCredentials: true,
      }
    );

    // console.log("Trying to send to /notifications/addme");
  };
  const removeUserNotifications = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LOCALHOST_ADDRESS}/notifications/addme`,
        {
          isDelete: true,
        },
        {
          withCredentials: true,
        }
      );
    } catch (error) {}
  };
  // console.log("Current notification permission:", Notification.permission);

  if (Notification.permission === "default") {
    Notification.requestPermission().then((perm) => {
      if (Notification.permission === "granted") {
        register().catch((err) => {
          console.error(err);
        });
      } else {
        // alert("Please allow notifications. :)");
        removeUserNotifications();
      }
    });
  } else if (Notification.permission === "granted") {
    register().catch((err) => {
      console.error(err);
    });
  } else {
    // alert("Please allow notifications. :(");
    removeUserNotifications();
  }
};

const NotificationManager = {
  register,
};

export default NotificationManager;
