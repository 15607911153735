import axios from "axios";
import Cookies from "js-cookie";
import { Service } from "axios-middleware";

const service = new Service(axios);

service.register({
  onRequest(config) {
    return config;
  },
  onSync(promise) {
    return promise;
  },
  onResponse(response) {
    try {
      if (JSON.parse(response.data).isAuthValid === false) {
        window.location.href = process.env.REACT_APP_CLIENT_ADDRESS;
      }
    } catch (error) {
      // console.log("Axios auto error:" + error);
    }

    return response;
  },
});
