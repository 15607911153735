import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./AboutPartnerHared.css";
import axios from "axios";
import {
  getWordByGender,
  getGender,
  getHared,
} from "../../handlers/dictionaryHandler.js";
import PopupExplainColors from "../PopupExplainColors/PopupExplainColors";

const AboutPartnerHared = () => {
  const history = useHistory();
  const [isDataLoaded, setIsDataLoaded] = useState();
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [partnerCurPos, setPartnerCurPos] = useState([]);
  const [partnerLimud, setPartnerLimud] = useState([]);
  const [partnerEda, setPartnerEda] = useState([]);
  const [partnerDos, setPartnerDos] = useState("");
  const [notHaredMatch, setNotHaredMatch] = useState(0);
  const [newUser, setNewUser] = useState(true);

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const displayeHaredArea = () => {
    if (document.getElementById("haredArea").style.height === "auto") {
      document.getElementById("haredArea").style.height = "0";
    } else {
      document.getElementById("haredArea").style.height = "auto";
    }
  };
  const handleCurPos = (val) => {
    if (!partnerCurPos.includes(val)) {
      setPartnerCurPos([...partnerCurPos, val]);
    } else {
      const tempPartner = partnerCurPos.filter((value) => value != val);
      setPartnerCurPos(tempPartner);
    }
  };
  const handleLimud = (val) => {
    if (!partnerLimud.includes(val)) {
      setPartnerLimud([...partnerLimud, val]);
    } else {
      const tempPartner = partnerLimud.filter((value) => value != val);
      setPartnerLimud(tempPartner);
    }
  };
  const handlePartnerEda = (val) => {
    if (!partnerEda.includes(val)) {
      setPartnerEda([...partnerEda, val]);
    } else {
      const tempPartner = partnerEda.filter((value) => value != val);
      setPartnerEda(tempPartner);
    }
  };
  const handlePartnerDos = (val) => {
    setPartnerDos(val);
  };

  const getClassNameForCharacterCube = (currentValue, myValue) => {
    let defaultClassName = "characterCube";

    if (currentValue == myValue) {
      defaultClassName += " active";
    } else if (currentValue == myValue - 1) {
      // Right
      defaultClassName += " rightCharacterCube";
    } else if (currentValue == myValue + 1) {
      // Left
      defaultClassName += " leftCharacterCube";
    }

    return defaultClassName;
  };

  const alertUser = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage(message);
  };

  useEffect(async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PaboutPartnerHaredGet",
        config
      );
      const userData = response.data;
      if (userData.notHaredMatch > 0) {
        setNotHaredMatch(userData.notHaredMatch);
      }
      if (userData.dosometer > 0) {
        setPartnerCurPos(userData.lastSchool.split(","));
        setPartnerLimud(userData.limudTora.split(","));
        setPartnerEda(userData.eda.split(","));
        setPartnerDos(userData.dosometer);
        setIsDataLoaded(true);
        setNewUser(false);
      } else {
        setIsDataLoaded(true); // in case its a new user
      }
    } catch (error) {
      // probebly user is not authenticated or server is down
    }
  }, []);

  const onSubmitHared = async () => {
    if (partnerCurPos != "") {
      if ((partnerLimud != "0" && getGender() == "1") || getGender() == "2") {
        if (partnerEda != "") {
          if (partnerDos != "") {
            try {
              const response = await axios.post(
                process.env.REACT_APP_LOCALHOST_ADDRESS + "/PaboutPartnerHared",
                {
                  partnerCurPos,
                  partnerLimud,
                  partnerEda,
                  partnerDos,
                },
                config
              );
              if (response.data === true) {
                alertUser("נשמר בהצלחה");
              } else {
                alertUser(
                  "יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית"
                );
              }
            } catch (error) {
              console.log(error);
              alertUser("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
            }
            // console.log(response.data[0][0]);
          } else {
            alertUser("לא בחרת מדד דוסיות");
            console.log("Hobby did not set");
          }
        } else {
          alertUser("לא בחרת עדה");
          console.log("Education did not set");
        }
      } else {
        alertUser("לא בחרת לימוד תורה");
        console.log("status not set");
      }
    } else {
      alertUser("לא בחרת מסגרת אחרונה/נוכחית");
      console.log("Dat did not chosen");
    }
  };

  return (
    <div className="">
      {getHared() != "1" ? (
        <div className="write-with-me" onClick={displayeHaredArea}>
          רוצה שאלון מותאם לחרדים? הנה :)
        </div>
      ) : (
        ""
      )}

      <div
        id="haredArea"
        className={` ${getHared() == "1" ? "haredAreaVisible" : ""}`}
      >
        <br />
        <div className="section-title">
          {" "}
          {notHaredMatch > 0
            ? "** חשוב לדעת! בחרת שמתאים לך להכיר גם לא חרדים, הפרמטרים הבאים לא יבדקו עבור התאמה לא חרדית (במילים אחרות, הפרמטרים הבאים נבדקים רק אם שניכם חרדים)."
            : ""}
        </div>
        <br />
        <div className="section-title">
          <b>מסגרת אחרונה/נוכחית</b>
        </div>

        <div className="parent-edu">
          <input
            type="button"
            name="edu"
            className={`seminar ${partnerCurPos.includes("1") ? "active" : ""}`}
            value={getWordByGender("psmallyeshiva")}
            key="1"
            onClick={(e) => handleCurPos("1")}
          />
          <input
            type="button"
            name="edu"
            className={`seminar ${partnerCurPos.includes("2") ? "active" : ""}`}
            value={getWordByGender("pbigyeshiva")}
            key="2"
            onClick={(e) => handleCurPos("2")}
          />
          {getGender() === "1" ? (
            <input
              type="button"
              name="edu"
              className={`seminar ${
                partnerCurPos.includes("3") ? "active" : ""
              }`}
              value='צבא / ש"ל'
              key="3"
              onClick={(e) => handleCurPos("3")}
            />
          ) : (
            ""
          )}
          <input
            type="button"
            name="edu"
            className={`curPos ${partnerCurPos.includes("4") ? "active" : ""}`}
            style={{ fontSize: "12px", top: "-2px" }}
            value="מכללה/ אוניברסיטה"
            key="4"
            onClick={(e) => handleCurPos("4")}
          />
          <input
            type="button"
            name="edu"
            className={`curPos ${partnerCurPos.includes("5") ? "active" : ""}`}
            value="לימוד מקצועי"
            key="5"
            onClick={(e) => handleCurPos("5")}
          />
          {getGender() === "1" ? (
            <input
              type="button"
              name="edu"
              className={`hesderHared ${
                partnerCurPos.includes("6") ? "active" : ""
              }`}
              value="ישיבת הסדר חרדית"
              key="6"
              onClick={(e) => handleCurPos("6")}
            />
          ) : (
            ""
          )}
        </div>
        {getGender() == "1" ? (
          <div>
            <br />
            <div className="section-title">
              {" "}
              <b> לימוד תורה</b>
            </div>

            <div className="parent-edu">
              <input
                type="button"
                name="edu"
                className={`limudTop ${
                  partnerLimud.includes("1") ? "active" : ""
                }`}
                value="חצי יום בכולל"
                key="1"
                onClick={(e) => handleLimud("1")}
              />
              <input
                type="button"
                name="edu"
                className={`limudDaf ${
                  partnerLimud.includes("2") ? "active" : ""
                }`}
                value="דף יומי"
                // style={{ top: "6px" }}
                key="2"
                onClick={(e) => handleLimud("2")}
              />

              <input
                type="button"
                name="edu"
                className={`limud ${
                  partnerLimud.includes("3") ? "active" : ""
                }`}
                value="שיעור כשיש"
                key="3"
                onClick={(e) => handleLimud("3")}
              />

              <input
                type="button"
                name="edu"
                className={`limud ${
                  partnerLimud.includes("4") ? "active" : ""
                }`}
                style={{ fontSize: "12px", top: "-2px" }}
                value="ישיבה על מלא"
                key="4"
                onClick={(e) => handleLimud("4")}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <br />
        <div className="section-title">
          <b> עדה </b>
        </div>
        {newUser ? (
          <div className="section-title">
            {" "}
            <PopupExplainColors />
          </div>
        ) : (
          ""
        )}
        <div className="parent-edu">
          <input
            type="button"
            name="edu"
            className={`eda ${partnerEda.includes("1") ? "active" : ""}`}
            value="אשכנז"
            key="1"
            onClick={(e) => handlePartnerEda("1")}
          />
          <input
            type="button"
            name="edu"
            className={`eda ${partnerEda.includes("2") ? "active" : ""}`}
            value="עדות מזרח"
            style={{ fontSize: "12px" }}
            key="2"
            onClick={(e) => handlePartnerEda("2")}
          />

          <input
            type="button"
            name="edu"
            className={`eda ${partnerEda.includes("3") ? "active" : ""}`}
            value="תימן"
            key="3"
            onClick={(e) => handlePartnerEda("3")}
          />

          <input
            type="button"
            name="edu"
            className={`eda ${partnerEda.includes("4") ? "active" : ""}`}
            value="אתיופיה"
            key="4"
            onClick={(e) => handlePartnerEda("4")}
          />
          <input
            type="button"
            name="edu"
            className={`eda ${partnerEda.includes("5") ? "active" : ""}`}
            value="מעורב"
            key="5"
            onClick={(e) => handlePartnerEda("5")}
          />

          <input
            type="button"
            name="edu"
            className={`eda ${partnerEda.includes("6") ? "active" : ""}`}
            value="אחר"
            key="6"
            onClick={(e) => handlePartnerEda("6")}
          />
        </div>
        <br />
        <div className="section-title">
          <b>{getWordByGender("howMuchPartnerDos")}</b>
        </div>
        <div style={{ direction: "ltr" }}>
          <div id="hright">{getWordByGender("pSuperDos")}</div>
          <div id="hleft">{getWordByGender("pSuperLight")}</div>
          <br></br>
          <div className="parent-7cubes">
            <input
              type="button"
              value="1"
              className={getClassNameForCharacterCube(partnerDos, 1)}
              onClick={(e) => handlePartnerDos("1")}
            />
            <input
              type="button"
              value="2"
              className={getClassNameForCharacterCube(partnerDos, 2)}
              onClick={(e) => handlePartnerDos("2")}
            />
            <input
              type="button"
              value="3"
              className={getClassNameForCharacterCube(partnerDos, 3)}
              onClick={(e) => handlePartnerDos("3")}
            />
            <input
              type="button"
              value="4"
              className={getClassNameForCharacterCube(partnerDos, 4)}
              onClick={(e) => handlePartnerDos("4")}
            />
            <input
              type="button"
              value="5"
              className={getClassNameForCharacterCube(partnerDos, 5)}
              onClick={(e) => handlePartnerDos("5")}
            />
            <input
              type="button"
              value="6"
              className={getClassNameForCharacterCube(partnerDos, 6)}
              onClick={(e) => handlePartnerDos("6")}
            />
            <input
              type="button"
              value="7"
              className={getClassNameForCharacterCube(partnerDos, 7)}
              onClick={(e) => handlePartnerDos("7")}
            />
          </div>
        </div>
        <br />
        {isErrorMessageDisplayed && (
          <div className="error-message">
            <h5>{errorMessage} </h5>
          </div>
        )}
        <div
          className="button-text-center"
          id="btnSubmit"
          onClick={onSubmitHared}
        >
          שמירה!
        </div>
      </div>
    </div>
    // </div>
  );
};

export default AboutPartnerHared;
