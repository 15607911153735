import React from "react";

const FacebookHelper = () => {
  return (
    // <div className="terms-and-conditions-container">
    // {/* className="terms-and-conditions-p" */}
    <div className="terms-and-conditions-p">
      כניסה לפרופיל של עצמך בפייס, לחיצה על … עוד, בחירת: ’העתק את הקישור
      לפרופיל‘
    </div>
    // </div>
  );
};

export default FacebookHelper;
