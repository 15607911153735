import React, { useEffect, useState } from "react";
import "./Footer.css";
import Popup from "reactjs-popup";
import axios from "axios";
// import AdSense from "react-adsense";

const Footer = () => {
  const [metTillnowDisplayed, setMetTillnowDisplayed] = useState(false);
  const [encourageDisplayed, setEncourageDisplayed] = useState(false);
  const [cntMatches, setCntMatches] = useState(650);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_LOCALHOST_ADDRESS + "/metadata/getCntMatches"
        );
        if (response.data && response.data.currentCntMatches) {
          setCntMatches(response.data.currentCntMatches);
        }
      } catch (error) {
        console.log(error);
      }
    };
    loadData();
  }, []);

  return (
    <div className="Footer">
      {/* <AdSense.Google
        client="ca-pub-1163888547214080"
        slot="7806394673"
        style={{ width: 500, height: 300, float: "left", display: "block" }}
        format=""
      /> */}
      {/* <div className="footer-text">בינתיים נפגשו 176 זוגות מדאבל :)</div> */}

      {/* <a
        href="https://www.facebook.com/%D7%93%D7%90%D7%91%D7%9C-%D7%94%D7%9B%D7%A8%D7%95%D7%99%D7%95%D7%AA-103072662171430"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="facebook"></div>
      </a> */}

      <a
        href={"https://wa.me/972549624084"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="whatsupp"></div>
      </a>
      <div className="mettillnow" onClick={() => setMetTillnowDisplayed(true)}>
        {`${cntMatches}  נפגשו מדאבל!`}
      </div>
      <Popup
        open={metTillnowDisplayed}
        contentStyle={{
          minWidth: "30%",
          maxWidth: "200px",
          borderRadius: "5px",
          textAlign: "center",
        }}
        position="right bottom"
        onClose={() => setMetTillnowDisplayed(false)}
        closeOnDocumentClick
        closeOnEscape
      >
        <div style={{ fontSize: "15px" }}>
          {" "}
          {" בינתיים נפגשו " + cntMatches + " זוגות מדאבל!!"}
        </div>
        <a
          href="whatsapp://send/?text=הי מה המצב? אני רוצה להמליץ לך לנסות אתר ממש שווה, הוא שדכן וירטואלי.. זה הקישור: https://double2love.com"
          className="prev-matches-button"
          style={{ color: "var(--btn-color)" }}
        >
          שתפו חברים שעדיין רווקים :){" "}
        </a>
        <br />

        <div style={{ fontSize: "12px" }}>
          {/* {"נשמח שתשתפו אותנו בחוויה שלכם מדאבל - זה נותן כוח לנו ולאחרים :)"} */}
        </div>
      </Popup>
      {/* <div
        className="encourage"
        onClick={() => setEncourageDisplayed(true)}
      ></div> */}
      <Popup
        open={encourageDisplayed}
        contentStyle={{ minWidth: "30%", maxWidth: "60%", borderRadius: "5px" }}
        position="right bottom"
        onClose={() => setEncourageDisplayed(false)}
        closeOnDocumentClick
        closeOnEscape
      >
        מתאים לך לפרגן לנו בביקורת או חוויה טובה? נשמח שתכתבו לנו בווטסאפ :)
      </Popup>

      {/* <div className="footer-text">
        <a
          href="https://tickchak.co.il/24790"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          אירוע מיטאפ 16.02
        </a>
      </div> */}
    </div>
  );
};

export default Footer;
