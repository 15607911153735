import React, { useState } from "react";

const HowItWorkForm = () => {
  const [tipexampleVisible, setTipexampleVisible] = useState(false);

  return (
    <div>
      <h3 className="h-nomargin-bold margintop18"> איך דאבל עובד?</h3>

      <div className="howItWork">
        {/* {"מספרים על עצמי, ואפילו יש טיפים :)"} */}
        מעדכנים את הפרופיל שלכם בדאבל
        <br />( יש אפילו טיפים לעשות זאת טוב)
        <div className="aboutmeiconw"></div>
        <br />
        <u onClick={(e) => setTipexampleVisible(!tipexampleVisible)}> לדוגמה</u>
        <div className={tipexampleVisible ? "tipexample" : "invisible"}></div>
      </div>
      <div className="rectangle"></div>
      <div className="triangle-down"></div>

      <div className="howItWork">
        {/* מסמנים מה מחפשים */}
        מגדירים את החיפוש שלכם
        <div className="aboutpartnericonw"></div>
      </div>
      <div className="rectangle"></div>
      <div className="triangle-down"></div>

      <div className="howItWork">
        קצת סבלנות, דאבל מחפש..
        <div className="doublematchicon"></div>
      </div>
      <div className="rectangle"></div>
      <div className="triangle-down"></div>

      <div className="howItWork">
        דאבל מוצא התאמה ושולח לכם הודעה
        {/* כשדאבל מוצא התאמה - מקבלים הודעה */}
        <div className="msgicon"></div>
      </div>
      <div className="rectangle"></div>
      <div className="triangle-down"></div>

      <div className="howItWork">
        שניכם מעוניינים? בהצלחה!<div className="heart-date"></div>
      </div>
    </div>
  );
};

export default HowItWorkForm;
