import React from "react";

const PrayWoman = () => {
  return (
    // <div className="terms-and-conditions-container">
    // {/* className="terms-and-conditions-p" */}

    <div>
      יהי רצון מלפניך ה' אלוהינו ואלוהי אבותינו,
      <br />
      שתמציא לי ברחמיך הרבים ובחסדיך הגדולים את זווגי הראוי לי בזמנו,
      <br />
      זיווג הגון הראוי להוליד תלמיד חכם גדול בתורה וביראה, מזרע צדיקים ואנשי אמת
      ויראת חטא, כמו שהמצאת זווגו לאדם הראשון, לאברהם ויצחק ויעקב ומשה, כל אחד
      זווגו בעתו ובזמנו.
      <br />
      ואותו איש שתמציא לי לזיווגי יהא איש טוב, איש נאה במעשיו, בעל מעשים טובים,
      בעל חן, איש משכיל וירא אלוהים, רודף צדקה וגומל חסד. ולא יהא בו שמץ פסול,
      ומום ופגם, ולא יהא כעסן ורגזן, רק בעל ענוה ונמיכות רוח, בריא ובעל כח,
      <br />
      ואל יעכב אכזריות הבריות ושונאים ומחשבותיהם ותחבולותיהם לעכב את בן זוגי
      שהוכן לי.
      <br />
      יהיו לרצון אמרי פי והגיון לבי לפניך, ה' צורי וגואלי..
    </div>
  );
};

export default PrayWoman;
