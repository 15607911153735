import React from "react";

const BelieveInYourselfSariel = () => {
  return (
    // <div className="terms-and-conditions-container">
    // {/* className="terms-and-conditions-p" */}

    <div className="">
      <b> "מי בכלל תרצה אותי?" שמעתי אותו בקול מבואס.</b>
      <br />
      זו שאלה שאני שומע לא מעט בתהליכי הכוונה לחתונה, שנובעת מהסתכלות ביקורתית
      על עצמנו.
      <br />
      אני מחזיר לו את השאלה חזרה: "בא תגיד לי, למה דווקא בחורות כן ירצו אותך?"
      <br />
      נותן לו משימה לשבוע הקרוב:
      <br />
      תעשה רשימה של עשרה דברים נהדרים אצלך שבזכותם בחורות ירצו להיות בחברתך
      ישמחו ליצור איתך קשר ייהנו איתך בדייט יהפכו אותך לאיש משפחה טוב לבעל אוהב
      ותומך, לאבא נהדר לילדים שלו
      <br />
      ***
      <br />
      בשבוע הבא הוא חוזר עם רשימה והוא מקריא לי, ובסוף ממשיך להתבאס:
      <br />
      <b>"אבל יש לכולם את התכונות האלו.., למה שבחורה תרצה להיות דווקא איתי?"</b>
      <br />
      "אמנם נכון, שיש להרבה אנשים תכונות שיש לך " אני עונה, אבל יש ניואנסים,
      כשכתבת שאתה בעל שיחה ומתעניין באחרים, זה קורה ברמות שונות אצל כל אחד"
      השבתי לו
      <br />
      "אתה צריך להעמיק רק עוד קצת כדי לראות מהי הדרך הייחודית שלך לעשות את זה.
      אתה גם מלא אמפתיה ומכיל, תכונות מעולות שלך, שכולן יחד יוצרות תמהיל ייחודי
      רק לך, שמרכיב את האישיות המיוחדת שלך"
      <br />
      הוא שאל:
      <b> "איך אני גורם לבחורה לראות את זה בי?" </b>
      ונימת תקווה מעודדת היתה בקולו
      <br />
      "הגישה שלך אקטיבית, ולוקחת אחריות על המצב שלך, ואני מאוד מעריך בך את זה."
      עניתי.
      <br />
      ושאלתי: "בא תגיד לי אתה, באיזה מצב אתה חושב שתוכל לתפוס הזדמנות להראות את
      זה"?
      <br />
      "אם היא תגיע במצב רוח 'לא משהו', אז במקום שהדייט שלנו ילך לעזאזל בגלל זה,
      (כי ישר אני חושב שזה בגללי) במקום זה אני אתעניין מה קרה, ואקשיב לה בדרך
      שאני יודע, אשאל שאלות, ואגיב באמפתיה למצב שלה".
      <br />
      "מצוין." עניתי.
      <br />
      ראית איך בכמה רגעים של התבוננות אפשר לשנות את הגישה. וזה למעשה כל הסיפור.
      <br />
      <br />
      בהצלחה לך.
    </div>
  );
};

export default BelieveInYourselfSariel;
