import React, { useState } from "react";

const StoryTelling = () => {
  const [isContinuePressed, setIsContinuePressed] = useState(false);

  return (
    <div
      className="contactUsHelper tellAboutUsSection"
      style={{ borderBottom: "0px" }}
    >
      <b>האתר הוקם למען 3 מטרות עיקריות:</b>
      <br />
      1.
      <b> להביא התאמות לחברי האתר </b>
      באופן כמה שיותר שיוויוני ומכבד, ללא קשר לסביבה החברתית שלהם היום.
      <br />
      2. לנצל את
      <b> הטכנולוגיה</b>, הכישורים,
      <b> והחוויה </b>
      שלנו ולהביא התאמות ממוקדות אליך ובסגנון שלך, שבעזרת ה יובילו
      <b> לקשר יציב ואף לחתונה.</b>
      <br />
      3. להביא את ההצעות אליך,
      <b> כי מגיע לך. יגיע עד אליך.</b>
      <br />
      <br />
      <b>דאבל הכרויות הוא </b>{" "}
      <u
        className="cursor"
        onClick={() => setIsContinuePressed(!isContinuePressed)}
      >
        {isContinuePressed ? "הצג פחות" : "להמשך קריאה"}
      </u>{" "}
      <div className={isContinuePressed ? "" : "invisible"}>
        <b> השדכן הוירטואלי האולטימטיבי. הוא כמו חבר נאמן ורגיש.</b>
        <br />
        דאבל מבין ומעריך את החשיבות והיופי שבמערכות יחסים.
        <br />
        השם דאבל מייצג את הרעיון הזוגי ואת המציאות החדשה שתזכו בה במציאת אהבת
        אמת ובבניית בית בישראל, ומציע דרך נוספת בתהליך שבסופו תמצאו התאמה זוגית
        ואמיתית.
        <br />
        <br />
        כמומחים בתחום השידוכים, אנו מחויבים למציאת זוגיות למשתמשים שלנו
        <b> תוך שמירה על פרטיותם. </b>
        אנו מספקים חוויה מותאמת אישית ונוחה.
        <br />
        האתר שלנו פונה לקהל השמרני: (דתי, חרדי, דתלשי) שכמה למערכת יחסים
        משמעותית.
        <br />
        <b>כי טובים השניים מן האחד!</b>
        <br />
        אחרי שתירשמו לדאבל,
        <b> תיצרו פרופיל אישי, תגדירו את הצ'ק ליסט שלכם להתאמה </b>
        המושלמת. דאבל ייכנס לפעולה, יחפש וימצא עבורכן.ם – התאמה! (בעז"ה)
        <br />
        <br />
        וכעת, נעבור לדבר המייסדת ;) :
        <br />
        "הי אני מיכל, בעלת תואר ראשון במתמטיקה ותואר שני במתמטיקה וכלכלה, עבדתי
        בתחום ההיי טק הרבה שנים, ודתיה.
        <br />
        <b> אני מכירה את תחום הדייטינג </b>
        מהחוויה האישית שלי ושל חברים שלי. היה חסר לי אתר שהוא שדכן וירטואלי -
        כמו חבר טוב שלומד אותי ומחפש לי התאמה, בלי שאני הופכת לכרטיס שכולם
        יכולים לראות, ובלי שאני צריכה להיכנס ולחפש לי,
        <b> ובלי החוסר נעימות </b>
        לפנות לחברים אם יש להם רעיון.
        <br />
        מתוך כך החלטתי להקים סטארטאפ משלי בתחום הדייטינג.
        <br />
        אני עובדת על האתר כבר 3 שנים.
        <br />
        מאחר ואני מתכנתת ומכירה את התחום, ויודעת מזה לכתוב
        <b> אלגוריתם מדויק</b>
        . כתבתי אתר עם אלגוריתם מאוד מדויק ופשוט לתחום הדייטינג, שהוא הרבה יותר
        ידידותי, פשוט, חכם.
        <br />
        אתר שמניב תוצאות מדויקות ונותן את החוויה בתחום הדייטים
        <b> בצורה אחרת לגמרי, </b>
        מתוך הנישה החברתית המדויקת של כל נרשם באתר. אני מכירה מקרוב את החברה
        הדתית, החרדית, והדתלשית. (עבור היחודיות של החברה החרדית - עשיתי חקר שוק
        נוסף ודיוקים בהתאם.)
        <br />
        האתר כל הזמן משתדרג לפי החוויה של המשתמשים, ומדייקים אותו עוד ועוד."
        <br />
        הצטרפו אלינו למסע למציאת ההתאמה המושלמת – בדאבל!
        <br />
      </div>
      <br />
      <b>
        {" "}
        <u>צוות האתר </u>
      </b>
      <div>
        <div className="MichalI"></div>
        <div>מיכל יזרלביץ</div>
        <div style={{ marginTop: "-4px" }}>מייסדת</div>
      </div>
      <br />
      <div className="faceline">
        <div>
          <div className="TomerC"></div>
          <div>תומר כהן</div>
          <div style={{ marginTop: "-4px" }}> מתכנת ראשי </div>
        </div>
        <div>
          <div className="RomiM"></div>
          <div> רומי מלחי</div>
          <div style={{ marginTop: "-4px" }}> ארט דיירקטורית</div>
        </div>
        <div>
          <div className="MosheI"></div>
          <div> מוישי יזרלביץ</div>
          <div style={{ marginTop: "-4px" }}> מתכנת AI</div>
        </div>
      </div>
    </div>
  );
};

export default StoryTelling;
