import React from "react";

const PhotoHelper = () => {
  return (
    // <div className="terms-and-conditions-container">
    // {/* className="terms-and-conditions-p" */}
    <div className="terms-and-conditions-p">
      <br />
      תמונה היא כמו
      <b> מודעה </b>
      <br />
      <br />
      בואו נסתכל מה המודעה משדרת.
      <br />
      אפשר לצרף
      <b> תמונה שמשדרת אווירה. </b>
      לדוגמה, אם בישול זה הקטע שלך, אז תמונה במטבח. כך גם אקסטרים, טיול, מעבדה
      וכד'.
      <br />
      כדאי תמונה צבעונית, שרואים בה את העיניים - ראי הנפש שלך.
      <br />
      <b>אם אתם מחייכים בתמונה הכי כיף, בטח מי שיראה אותך יחייך אליך בחזרה.</b>
      <br />
      אבל אל תשכחו - אתם הכי הכי כשאתם אתם, הביאו תמונה
      <b> אותנטית, </b>
      משוחררת ולא עשויה מידי.
      <br /> <br />
      נ.ב. אם התמונה גדולה מידי - אפשר לנסות לבחור תמונה מגלריית הווטאספ (תשלחו
      את התמונה למישהו ושהוא יחזיר לכם, ואז היא תופיע בגלריית הווטסאפ), או לצלם
      מסך.
      <br /> <br />
    </div>
  );
};

export default PhotoHelper;
