import React from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import HeaderBack from "../../components/Header/HeaderBack";

const IntoSite = () => {
  let history = useHistory();
  return (
    <div>
      <HeaderBack />
      <h3 className="h-nomargin-bold margintop18"> קבלו הצצה לדאבל</h3>

      <div className="faceline">
        <iframe
          src={"https://www.youtube.com/embed/mIhOU7_hBgM"}
          className="youtube-homepage"
          title="האתר מבפנים"
        ></iframe>
      </div>
      <br />
      <Link
        to={"/"}
        style={{ textDecoration: "none", border: "1px black solid" }}
      >
        <div className="button" id="backBtn">
          {" "}
          חזרה
        </div>
      </Link>
    </div>
  );
};

export default IntoSite;
