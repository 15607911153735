import React from "react";

const CreateMeDateOsifGonen = () => {
  return (
    // <div className="terms-and-conditions-container">
    // {/* className="terms-and-conditions-p" */}

    <div className="">
      <b> 4 דברים שיביאו לך יותר דייטים (בצורה עקיפה)</b>
      <br />
      * הכתיבה היא לגברים, אך זה נכון לכולם.
      <br />
      <br />
      1.
      <b>להחזיר קשרים מהעבר וליצור קשרים עם גברים: </b>
      אוקי.. אני מבין את זה, סיימת צבא, סיימת לימודים, רוב האנשים בעבודה שלך
      נשואים, אתה עסוק, יש לך הרבה על הראש ולאט לאט קשרים נעלמו. כשאתה תחזיר
      קשרים ותיצור קשרים עם גברים חדשים, אתה תתחיל לצאת יותר, להיות יותר במקומות
      ולהיות יותר חשוף לסיטואציות בהם בחורות נמצאות. בנוסף, אתה תרגיש תנופה של
      אנרגיות טובות בחיים שלך ומומונטום שיווצר בעקבות כך.
      <br />
      <br />
      2.
      <b>תעמוד במילה שלך: </b>
      כמה פעמים קרה שאמרת שתעשה משהו ואז שנית את דעתך ובסוף לא עשית. תלמד לעמוד
      במילה שלך, גם אם בסוף החלטת שזה רעיון פחות טוב, תעשה את זה בכל זאת. זה
      יהפוך אותך לאדם שלא מוותר ושעומד במילה שלו ושלמילה שלו יש המון ערך (וזה
      מאוד מושך).
      <br />
      <br />
      <b>3. בלי טלפון ואוזניות: </b>
      כשאתה רואה את הבחורה היפה הזאת באוטובוס או ברכבת, איך תיגש אליה? יש לך
      אוזניות שמסיחות את דעתך טלפון שאתה דבוק אליו, אתה בכלל לא שם לב מה קורה
      סביבך. כשאתה תהיה בלי הסחות הדעת האלה פתאום אתה תמצא את עצמך יותר תקשורתי
      עם אנשים. בנוסף, אתה מבלה הרבה יותר מדי זמן בטלפון, הוא מסיח את דעתך תגביל
      את הזמן שלך בטלפון.
      <br />
      <br />
      <b>4. זמן ללא הפרעות בכלל: </b>
      אני ממליץ לך לעשות הליכות ארוכות ללא טלפון או משהו שיכול להסיח את דעתך, זה
      יכול להיות הליכות, זה יכול להיות נקיון של הבית הנקודה היא שאתה לגמרי לבד
      בלי שום הסחות דעת ואתה נותן לראש שלך לרוץ עם המחשבות. אתה תהיה מופתע מכמה
      תובנות מעניינות יגיעו אליך.
    </div>
  );
};

export default CreateMeDateOsifGonen;
