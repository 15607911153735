import React from "react";

const FirstDatesInterestingEgoza = () => {
  return (
    // <div className="terms-and-conditions-container">
    // {/* className="terms-and-conditions-p" */}

    <div className="">
      <b> איך לגרום שיהיה מעניין בדייטים ראשונים?</b>
      <br />
      <br />
      האם קרה לכם שחששתם לפני דייט שיהיה משעמם? לא מעניין? תחושה זו יכולה להקשות
      ואף לגרום לכם להיות בלחץ ולא במיטבכם לפני ובמהלך הדייט.
      <br />
      האם יש דרך להימנע מכך? או לפחות להפחית את תחושה זו?
      <br />
      אם נבחן את הסיטואציה, נגלה לא פעם שלא הפגישה היא המשעממת, לא המפגש הוא זה
      שמעורר את אותה תחושת חזרתיות וחוסר עניין, אלא הגישה איתה אנו מגיעים אל
      המפגש.
      <br />
      <br />
      מערכת יחסים נבנית מהיכרות, אשר באופן טבעי מתחילה באופן שטוח יותר ועם הזמן
      הולכת ומעמיקה. ממילא, דייטים ראשונים עוסקים לא מעט בשאלות טכניות אשר נועדו
      לעזור לכל אחד מהצדדים להכיר טוב יותר את האדם שמולו. אלא שלעיתים קרובות
      נמצא את עצמנו חוזרים על אותם משפטים ואף מדקלמים את אותן תשובות ידועות מראש
      לשאלות ידועות מראש כמו איפה למדנו או במה אנחנו עובדים. אפילו אנחנו מצליחים
      להשתעמם מעצמנו, ופה בדיוק הנקודה!
      <br />
      על מנת ליצור עניין, כבר בפגישות הראשונות, אני ממליצה-
      <b> לתכנן מראש </b>
      על מה לדבר בדייט.
      <br />
      לדבר על נושאים
      <b> שמעניינים אתכם </b>
      ביומיום, כמו לספר על אירועים שקרו לכם במהלך היום, בשבוע, לשתף בסיפורים
      מצחיקים והתלבטויות או ללכת למקומות אחרים וחדשים שיעניינו ויסקרנו גם אתכם,
      <b> חשבו- לאיפה הייתם הולכים עם חבר/ה, על מה הייתם מדברים איתם. </b>
      כאשר תיצרו עניין לעצמכם כך יהיה לכם נחמד וכיף בדייטים וגם תוכלו להנות
      ולהיות במיטבכם.
      <br />
    </div>
  );
};

export default FirstDatesInterestingEgoza;
