import React from "react";

const FreetextHelper = () => {
  return (
    // <div className="terms-and-conditions-container">
    // {/* className="terms-and-conditions-p" */}

    <div className="terms-and-conditions-p">
      <b>כששומעים זה אחרת</b>
      <br />
      נכון שכשאתם שומעים בן אדם, או על בן אדם, אתם מקבלים חוויה אחרת, יותר חיה,
      יותר אמינה, יותר
      <b> אותנטית.</b>
      <br />
      נכון, זה קשה להקליט (וידוי: גם לי זה לא קל, בטח אם ההקלטה היא על עצמי)
      <br />
      אבל, יש בהקלטה משהו סופר יחודי.
      <br />
      <b>זו משימה,</b>
      <br />
      אבל, אם תצליחו,
      <b> זה מדהים :)</b>
      <br />
      אז אפשר להקליט
      <b> על עצמך.</b>
      <br />
      ואפשר לתת לחבר/ה
      <b> שיקליטו עליך.</b>
      <br />
      (ובטח זה כדאי אם אתם רגילים לברר לפני דייט)
      <br />
      <br />
      יאללה, מנסים?
    </div>
  );
};

export default FreetextHelper;
