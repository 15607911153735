import React from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import { getSignedIn } from "../../handlers/dictionaryHandler.js";
import HeaderBack from "../../components/Header/HeaderBack";
import QandA from "../../components/Q&A/Q&A";

const QAForm = () => {
  let history = useHistory();

  return (
    <div className="aboutdouble-full-screen">
      <HeaderBack />
      <br />
      <form className="aboutdouble-form">
        <h1 className="h1-homepage"> שאלות ותשובות </h1>
        <QandA />

        <Link
          to={getSignedIn() === "1" ? "/questionnaires" : "/"}
          style={{ textDecoration: "none" }}
        >
          <div className="button" id="backBtn">
            {" "}
            חזרה
          </div>
        </Link>
      </form>{" "}
    </div>
  );
};

export default QAForm;
