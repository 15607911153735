import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../../App.css";
import axios from "axios";
import {
  getWordByGender,
  getGender,
  getHared,
} from "../../handlers/dictionaryHandler.js";

const AboutMeHared = () => {
  const history = useHistory();
  const [isDataLoaded, setIsDataLoaded] = useState();
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [myCurPos, setMyCurPos] = useState("");
  const [myLimud, setMyLimud] = useState("0");
  const [myEda, setMyEda] = useState("");
  const [myDos, setMyDos] = useState("");

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const displayeHaredArea = () => {
    // if (document.getElementById("haredArea").style.height === "610px") {
    //   document.getElementById("haredArea").style.height = "0px";
    // } else {
    //   document.getElementById("haredArea").style.height = "610px";
    // }
    if (document.getElementById("haredArea").style.height === "auto") {
      document.getElementById("haredArea").style.height = "0";
    } else {
      document.getElementById("haredArea").style.height = "auto";
    }
  };
  const handleCurPos = (val) => {
    setMyCurPos(val);
  };
  const handleMyLimud = (val) => {
    setMyLimud(val);
  };
  const handleMyEda = (val) => {
    setMyEda(val);
  };
  const handleMyDos = (val) => {
    setMyDos(val);
  };

  const getClassNameForCharacterCube = (currentValue, myValue) => {
    let defaultClassName = "characterCube";

    if (currentValue == myValue) {
      defaultClassName += " active";
    } else if (currentValue == myValue - 1) {
      // Right
      defaultClassName += " rightCharacterCube";
    } else if (currentValue == myValue + 1) {
      // Left
      defaultClassName += " leftCharacterCube";
    }

    return defaultClassName;
  };

  const alertUser = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage(message);
  };

  useEffect(async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PaboutMeHaredGet",
        config
      );
      const userData = response.data;
      if (userData.lastSchool > 0) {
        setMyCurPos(userData.lastSchool);
        setMyLimud(userData.limudTora);
        setMyEda(userData.eda);
        setMyDos(userData.dosometer);
        setIsDataLoaded(true);
      } else {
        setIsDataLoaded(true); // in case its a new user
      }
    } catch (error) {
      // probebly user is not authenticated or server is down
    }
  }, []);

  const onSubmitHared = async () => {
    if (myCurPos != "") {
      if ((myLimud != "0" && getGender() == "2") || getGender() == "1") {
        if (myEda != "") {
          if (myDos != "") {
            try {
              const response = await axios.post(
                process.env.REACT_APP_LOCALHOST_ADDRESS + "/PaboutMeHared",
                {
                  myCurPos,
                  myLimud,
                  myEda,
                  myDos,
                },
                config
              );
              if (response.data === true) {
                alertUser("נשמר בהצלחה");
              } else {
                alertUser(
                  "יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית"
                );
              }
            } catch (error) {
              console.log(error);
              alertUser("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
            }
            // console.log(response.data[0][0]);
          } else {
            alertUser("לא בחרת מדד דוסיות");
            console.log("Hobby did not set");
          }
        } else {
          alertUser("לא בחרת עדה");
          console.log("Education did not set");
        }
      } else {
        alertUser("לא בחרת לימוד תורה");
        console.log("status not set");
      }
    } else {
      alertUser("לא בחרת מסגרת אחרונה/נוכחית");
      console.log("Dat did not chosen");
    }
  };

  return (
    <div className="">
      {getHared() != "1" ? (
        <div className="write-with-me" onClick={displayeHaredArea}>
          רוצה שאלון מותאם לחרדים? הנה :)
        </div>
      ) : (
        ""
      )}

      <div
        id="haredArea"
        className={` ${getHared() == "1" ? "haredAreaVisible" : ""}`}
      >
        <br />
        <div className="section-title">
          <b>מסגרת אחרונה/נוכחית</b>
        </div>
        <div className="parent-edu">
          <input
            type="button"
            name="edu"
            className={`seminar ${myCurPos == "1" ? "active" : ""}`}
            value={getWordByGender("msmallyeshiva")}
            key="1"
            onClick={(e) => handleCurPos("1")}
          />
          <input
            type="button"
            name="edu"
            className={`seminar ${myCurPos == "2" ? "active" : ""}`}
            value={getWordByGender("mbigyeshiva")}
            key="2"
            onClick={(e) => handleCurPos("2")}
          />
          {getGender() === "2" ? (
            <input
              type="button"
              name="edu"
              className={`seminar ${myCurPos == "3" ? "active" : ""}`}
              value='צבא / ש"ל'
              key="3"
              onClick={(e) => handleCurPos("3")}
            />
          ) : (
            ""
          )}
          <input
            type="button"
            name="edu"
            className={`curPos ${myCurPos == "4" ? "active" : ""}`}
            style={{ fontSize: "12px", top: "-2px" }}
            value="מכללה/ אוניברסיטה"
            key="4"
            onClick={(e) => handleCurPos("4")}
          />
          <input
            type="button"
            name="edu"
            className={`curPos ${myCurPos == "5" ? "active" : ""}`}
            value="לימוד מקצועי"
            key="5"
            onClick={(e) => handleCurPos("5")}
          />
          {getGender() === "2" ? (
            <input
              type="button"
              name="edu"
              className={`hesderHared ${myCurPos == "6" ? "active" : ""}`}
              value="ישיבת הסדר חרדית"
              key="6"
              onClick={(e) => handleCurPos("6")}
            />
          ) : (
            ""
          )}
        </div>
        {getGender() === "2" ? (
          <div>
            <br />
            <div className="section-title">
              {" "}
              <b>לימוד תורה </b>
            </div>
            <div className="parent-edu">
              <input
                type="button"
                name="edu"
                className={`limudTop ${myLimud == "1" ? "active" : ""}`}
                value="חצי יום בכולל"
                key="1"
                onClick={(e) => handleMyLimud("1")}
              />
              <input
                type="button"
                name="edu"
                className={`limudDaf ${myLimud == "2" ? "active" : ""}`}
                value="דף יומי"
                // style={{ top: "6px" }}
                key="2"
                onClick={(e) => handleMyLimud("2")}
              />

              <input
                type="button"
                name="edu"
                className={`limud ${myLimud == "3" ? "active" : ""}`}
                value="שיעור כשיש"
                key="3"
                onClick={(e) => handleMyLimud("3")}
              />

              <input
                type="button"
                name="edu"
                className={`limud ${myLimud == "4" ? "active" : ""}`}
                style={{ fontSize: "12px", top: "-2px" }}
                value="ישיבה על מלא"
                key="4"
                onClick={(e) => handleMyLimud("4")}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <br />
        <div className="section-title">
          <b> עדה</b>
        </div>
        <div className="parent-edu">
          <input
            type="button"
            name="edu"
            className={`eda ${myEda == "1" ? "active" : ""}`}
            value="אשכנז"
            key="1"
            onClick={(e) => handleMyEda("1")}
          />
          <input
            type="button"
            name="edu"
            className={`eda ${myEda == "2" ? "active" : ""}`}
            value="עדות מזרח"
            style={{ fontSize: "12px" }}
            key="2"
            onClick={(e) => handleMyEda("2")}
          />

          <input
            type="button"
            name="edu"
            className={`eda ${myEda == "3" ? "active" : ""}`}
            value="תימן"
            key="3"
            onClick={(e) => handleMyEda("3")}
          />

          <input
            type="button"
            name="edu"
            className={`eda ${myEda == "4" ? "active" : ""}`}
            value="אתיופיה"
            key="4"
            onClick={(e) => handleMyEda("4")}
          />
          <input
            type="button"
            name="edu"
            className={`eda ${myEda == "5" ? "active" : ""}`}
            value="מעורב"
            key="5"
            onClick={(e) => handleMyEda("5")}
          />

          <input
            type="button"
            name="edu"
            className={`eda ${myEda == "6" ? "active" : ""}`}
            value="אחר"
            key="6"
            onClick={(e) => handleMyEda("6")}
          />
        </div>
        <br />
        <div className="section-title">
          <b>{getWordByGender("howMuchDos")}</b>
        </div>
        <div style={{ direction: "ltr" }}>
          <div id="hright">{getWordByGender("meSuperDos")}</div>
          <div id="hleft">{getWordByGender("meSuperLight")}</div>
          <br></br>
          <div className="parent-7cubes">
            <input
              type="button"
              value="1"
              className={getClassNameForCharacterCube(myDos, 1)}
              onClick={(e) => handleMyDos("1")}
            />
            <input
              type="button"
              value="2"
              className={getClassNameForCharacterCube(myDos, 2)}
              onClick={(e) => handleMyDos("2")}
            />
            <input
              type="button"
              value="3"
              className={getClassNameForCharacterCube(myDos, 3)}
              onClick={(e) => handleMyDos("3")}
            />
            <input
              type="button"
              value="4"
              className={getClassNameForCharacterCube(myDos, 4)}
              onClick={(e) => handleMyDos("4")}
            />
            <input
              type="button"
              value="5"
              className={getClassNameForCharacterCube(myDos, 5)}
              onClick={(e) => handleMyDos("5")}
            />
            <input
              type="button"
              value="6"
              className={getClassNameForCharacterCube(myDos, 6)}
              onClick={(e) => handleMyDos("6")}
            />
            <input
              type="button"
              value="7"
              className={getClassNameForCharacterCube(myDos, 7)}
              onClick={(e) => handleMyDos("7")}
            />
          </div>
        </div>
        <br />
        {isErrorMessageDisplayed && (
          <div className="error-message">
            <h5>{errorMessage} </h5>
          </div>
        )}
        <div
          className="button-text-center"
          id="btnSubmit"
          onClick={onSubmitHared}
        >
          שמירה!
        </div>

        <div className="line" />
      </div>
    </div>
    // </div>
  );
};

export default AboutMeHared;
