import React from "react";
import "./HeaderHomepage.css";
import { useHistory } from "react-router-dom";

const HeaderHomepage = () => {
  let history = useHistory();

  const aboutDoubleClicked = () => {
    history.push("/aboutdouble");
  };

  const logInClicked = async () => {
    history.push("/login");
  };

  const signUpClicked = () => {
    history.push("/signup");
  };

  const homeClicked = () => {
    if (window.location.href.includes("sign")) {
      history.push("/");
    } else {
      humburgerClicked();
    }
  };

  const contactUsClicked = () => {
    history.push("/contactus");
  };

  const qaClicked = () => {
    history.push("/qa");
  };

  const tellAboutUsClicked = () => {
    history.push("/tellaboutus");
  };

  const termsClicked = () => {
    history.push("/terms");
  };

  const intoSiteClicked = () => {
    history.push("/intosite");
  };

  const howItWorkClicked = () => {
    history.push("/howitwork");
  };

  const humburgerClicked = () => {
    if (document.getElementById("slidepanel").style.width === "200px") {
      document.getElementById("slidepanel").style.width = "0px";
    } else {
      document.getElementById("slidepanel").style.width = "200px";
    }
  };
  return (
    <div>
      <div className="headerHomepage">
        {/* <div className="phone"></div> */}

        <div className="hp-logo" onClick={homeClicked} />
        <div className="button-header shadow" onClick={signUpClicked}>
          הרשמו ←
        </div>
        <div
          className="button-header shadow"
          style={{ textDecorationLine: "underline" }}
          onClick={logInClicked}
        >
          כנסו ←
        </div>
        <div className="lbl">בס"ד</div>
        {/* <div className="running"> אנחנו בהרצה, תהיו סבלניים :)</div> */}

        <div className="hamburger" onClick={humburgerClicked}></div>
        <div id="slidepanel">
          <div
            onClick={humburgerClicked}
            style={{
              fontSize: "22px",
              margin: "5px",
              direction: "rtl",
              cursor: "pointer",
            }}
          >
            {" "}
            X{" "}
          </div>
          {/* <div className="button-slidepanel" onClick={aboutDoubleClicked}>
            אודות
          </div> */}
          <div className="button-slidepanel" onClick={contactUsClicked}>
            יצירת קשר
          </div>
          <div className="button-slidepanel" onClick={tellAboutUsClicked}>
            כותבים לנו
          </div>
          <div className="button-slidepanel" onClick={termsClicked}>
            תקנון האתר
          </div>
          <div className="button-slidepanel" onClick={qaClicked}>
            שו"ת
          </div>
          <div className="button-slidepanel" onClick={intoSiteClicked}>
            הצצה לאתר
          </div>
          <div className="button-slidepanel" onClick={howItWorkClicked}>
            איך דאבל עובד?
          </div>
          <div className="button-slidepanel" onClick={homeClicked}>
            דף הבית
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderHomepage;
