import axios from "axios";

let currentVersion = "";

const getClientVersion = async () => {
  const response = await axios.get("/client-version.txt", {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });
  const version = response.data;
  // console.log("veriosn", version);
  return version;
};

const refreshIfneeded = async () => {
  // console.log("checking for new update");

  const updatedVersion = await getClientVersion();
  if (currentVersion !== "") {
    if (currentVersion !== updatedVersion) {
      // console.log("refreshing website");
      window.location.reload();
    }
  } else {
    currentVersion = updatedVersion;
  }
};

const timeTillNextCheck = 1 * 60 * 1000;
// const timeTillNextCheck = 30 * 1000;

const init = () => {
  refreshIfneeded();

  setInterval(async () => {
    await refreshIfneeded();
  }, timeTillNextCheck);
};

const RefreshManager = {
  init,
};

export default RefreshManager;
