import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";

const HeaderBackToEdit = () => {
  return (
    <div className="headerback">
      <Link to={"/edit"} style={{ textDecoration: "none" }}>
        <div className="backhome" />
      </Link>
    </div>
  );
};

export default HeaderBackToEdit;
