import axios from "axios";

const getFile = async (s3FilePath) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_LOCALHOST_ADDRESS}/file/${s3FilePath}`,
      {
        withCredentials: true,
        responseType: "arraybuffer",
      }
    );

    const binaryData = response.data;
    const blob = new Blob([binaryData], {
      type: "image/jpeg",
    });

    const file = new File([blob], "image.jpg", { type: "image/jpeg" });

    return {
      blobURL: URL.createObjectURL(blob),
      file,
    };
  } catch (error) {
    return {
      BlobURL: "",
      file: "",
    };
  }
};

const fileToURL = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });
};

const blobURLToFile = async (blobURL) => {
  return new Promise((resolve, reject) => {
    fetch(blobURL)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], "image.jpg", { type: "image/jpeg" });
        resolve(file);
      });
  });
};

const FileManager = {
  getFile,
  fileToURL,
  blobURLToFile,
};

export default FileManager;
