import React from "react";

const MistakeInDateShirliVal = () => {
  return (
    // <div className="terms-and-conditions-container">
    // {/* className="terms-and-conditions-p" */}

    <div className="">
      <b> האם מותר לטעות בדייט?</b>
      <br />
      <br />
      זה ממש לא מה שתכננת, אבל מרוב התרגשות דיברת דברים שגרמו לריחוק. והדייט
      הסתיים בהודעה: "זה לא מתאים".
      <br />
      <b>חבל לך! כי את יודעת שזה היה יכול להתאים אבל שידרת שדר אחר.</b>
      <br />
      בעולם שכולו הצלחה, שהדיבור הפנימי והחיצוני ברשתות החברתיות ובכלל בכל מקום,
      הוא שיש לשאוף רק למצויינת והצלחה. אין מקום למניעות, עיכובים וטעויות.
      <br />
      וכשיש פאשלה עולה בתוכנו ה"מבקר" זה ששמח "לטפל" בנו ברגעים האלה, ולהעצים את
      הרגשות הלא נעימים. "האכזבה", "האשמה" ועוד.... אך האם זה חייב להיות כך?
      <br />
      <br />
      גם אם הדייט לא הצליח תמיד ניתן לשתף את הצד השני, לכתוב בבטחון: "לא הייתי
      במיטבי ושידרתי שדר אחר. אשמח שננסה שוב".
      <br />
      טעות היא מאת ה' אין מה לברוח ממנה ולהסתתר מתחת לשמיכה. אלא לקחת אחריות
      אמיצה. לשקף לצד השני,
      <b> אם ניתן</b>
      , את החוויה, ולהעביר מסר חדש.
      <br />
      ואם לא ניתן לתקן, אז ניתן ללמוד מהטעות ולהתקדם.
      <br />
      פגיעות היא מצויינות, ורק היא יכולה לקדם, להוביל, ליצור את הבלתי אפשרי
      ולחדד את ההצלחה בכל תחום.
      <br />
      <br />
      מאמינה בך!
    </div>
  );
};

export default MistakeInDateShirliVal;
