import React from "react";

const FreetextHelper = () => {
  return (
    // <div className="terms-and-conditions-container">
    // {/* className="terms-and-conditions-p" */}

    <div className="terms-and-conditions-p">
      רגע לפני שנכתוב טקסט על עצמי, נחשוב רגע: מי אני מחפש/ת, ונדבר אליו/ה בשפה
      מתאימה.
      <br />
      אין מה לכתוב שם וגיל, זה כבר ידוע.
      <br />
      נכתוב משהו
      <b> שיסקרן </b>, לדוגמה על
      <b> תחביב, טיול, </b>
      התנדבות, כשרון מיוחד,
      <b> ספר </b>
      אהוב.
      <br />
      אם נצליח לכתוב
      <b> באופן משעשע - בונוס! </b>
      <br />
      טקסט שמתאים להרבה אנשים - בד"כ לא כ"כ מושך, אז נשים לב לכתוב
      <b> משהו שמייחד אותנו.</b>
      <br />
      מטרת האתר היא היכרות זוגית ולא מציאת עבודה, כך שאין צורך בפירוט ביוגרפי
      מלא :)
      <br />
      נכתוב בשפה המשלבת רגש (חום, הומור, רגישות) ונשתף במשהו שאנו מעריכים,
      מחבבים וכד'.
      <br />
      ננסה לתת עוגן, כך
      <b> שיהיה נוח לגשת אלינו ולפתח שיחה על משהו שסיפרנו כאן. </b>
      <br />
      נ.ב. ניתן להשאיר מס' טלפון לבירורים
    </div>
  );
};

export default FreetextHelper;
