const TermsAndConditionsText = (
  <div>
    קדימון
    <br />
    ההסכם הינו בעל תוקף משפטי, חובה על הנרשם לקרוא בעיון רב את ההסכם.￼ באישור
    הנרשם את ההסכם הינו מצהיר כי קרא את כולו וכן הינו מתחייב לעמוד בכל התנאים
    שמובאים בהסכם. אם אתה, הנרשם, לא מסכים לתנאי ההסכם והשירות. או לחילופין, לא
    יכול לעמוד בתנאי התקנון. הינך מתבקש שלא להירשם לשירות. הנהלת האתר שומרת
    לעצמה הזכות לעדכן את תנאי השימוש המוצגים להלן מעת לעת וללא התראה או אזכור
    מיוחד בערוצי האתר השונים.
    <br />
    <br />
    1. האתר הוא פלטפורמה לשירותי הכרויות בישראל ויש להימנע מכל פגיעה באורחות
    החיים של המשתמשים.
    <br />
    2. ההרשמה הינה לנרשם בודד בלבד.
    <br />
    3. דאבל בעלת זכות שלא לאשר, על פי שיקול דעתה, הרשמה לאתר, בכל עת, בלא צורך
    לנמק מדוע.
    <br />
    4. דאבל רשאית שלא לאשר תמונות שהנרשם מעלה לאתר, מתוך שיקול דעתה בלא לנמק
    מדוע.
    <br />
    5. דאבל רשאית לערוך את תמונות הנרשם.
    <br />
    6. כל חלק גלוי בפרופיל הנרשם הכולל תמונות גלויות שהעלה, יהיה בעל זכות לשימוש
    חופשי של דאבל. המסכים להסכם מצהיר כי הינו מוותר על כל הזכויות שלו באותם
    החלקים. בנוסף, כל הפרטים שהנרשם מעלה הם על אחריותו בלבד ודאבל לא אחראית לכל
    נזק שעלול להיגרם מהעלאת התכנים בין למעלה התכנים ובין לכל משתמש אחר שצורך את
    התכנים.
    <br />
    7. הפגישות בין חברי האתר הן על דעתם ואחריותם בלבד ומוטלת על הנרשם האחריות
    לבדוק היטב עם מי הוא יוצר קשר ובייחוד עם מי הוא נפגש, דאבל אינה אחראית לכל
    פגם/חסרון/נזק שעלול לקרות או קרה עקב מפגשים בין חברי האתר.
    <br />
    8. דאבל מודיעה שאסור להעלות תמונה שאינה נושאת את המראה האמיתי של הנרשם, וכן
    אסור להעלות תכנים כלשהם שאינם נכונים בצורה מובהקת או כאלה שיש בהם פוטנציאל
    לפגיעה במי מהמשתמשים האחרים באתר.
    <br />
    9. האתר מותר בשימוש לנרשמים אשר בשעת ההרשמה, גילם הינו 18 ומעלה.
    <br />
    10. הפרסום באתר, ללא קבלת רשות מפורשת, אסור בכל דרך שהיא, העובר על סעיף זה
    מתחייב לשלם סך של 20,000 ש"ח לדאבל, ללא כל הוכחת גרימת נזק כלשהו מהפרסום.
    <br />
    11. דאבל שומרת לעצמה את הזכות להוסיף או לגרוע או לשנות תכנים, שירותים
    ומאפיינים של האתר.
    <br />
    12. חל איסור מוחלט להשתמש בשירותי האתר לשם הונאה/לקיחת כספים מהזולת בכל דרך
    שהיא.
    <br />
    13. לדאבל הזכות המלאה לנטר את פעילות האתר, כולל שליחת הודעות פרטיות, לצורך
    שמירה על צביון האתר בתור אתר שמרני ובתור אתר שפועל על פי כללי המוסר הסבירים.
    <br />
    14. דאבל תקפיד על נכונות התכנים שמועלים על ידה לאתר. עם זאת, דאבל לא מתחייבת
    לאמינות ומהימנות המידע והתכנים שמועלים לאתר. דאבל לא תהיה אחראית בשום צורה
    על כל נזק שיגרם מאי-נכונות או מנכונות התכנים שמועלים לאתר.
    <br />
    15. קישורים לאתרים חיצוניים אינם מהווים ערובה כי מדובר באתרים בטוחים,
    איכותיים או אמינים וביקור בהם נעשה על דעתכם האישית בלבד ונמצאים בתחום
    האחריות הבלעדי של המשתמש באתר.
    <br />
    16. דאבל אינה אחראית על התכנים שמועלים על ידי המשתמשים לפרופיל שלהם המוצג
    באתר, בנוסף דאבל אינה אחראית לבדוק ו/או לעקוב אחר התכנים שמועלים לאתר.
    <br />
    17. דאבל אינה אחראית על אמינות זהות אמיתית של נרשם, בין אם אומתה זהותו על
    ידי הכלים שבידי דאבל ובפרט אם לא מאומתת זהותו.
    <br />
    18. כלי אימות הזהות שבאתר הינם לעזר בלבד למשתמשי האתר לקבלת מידע מסויים על
    רמת האימות שהנרשם ביצע, אולם אין אימות זה קובע בוודאות על זהות מאומתת.
    <br />
    19. דאבל אינה מתחייבת לשמירה מוחלטת של התכנים שמעלים המשתמשים באתר, יתכן
    והתכנים ימחקו מסיבה כלשהיא בין אם זה ע"י דאבל, ו/או לרבות ע"י פגם או תקלה
    בשרתים המאחסנים את האתר ו/או כל תקלה ו/או פגיעה פיסית/ קיברנטית, בין בשגגה
    ובין בכוונת זדון וכן כל היזק שנגרם על ידי כוח עליון. לדאבל הזכות שלא￼ לנמק
    מדוע התכנים נמחקו.
    <br />
    20. אנו שואפים לספק לכם את המידע המוצג באתר ללא הפרעות אך יתכנו בשל שיקולים
    טכניים, תקלות צד ג או אחרים, הפרעות בזמינות האתר. ולכן איננו יכולים להתחייב
    כי האתר יהיה זמין לכם בכל עת ולא יינתן כל פיצוי כספי או אחר בשל הפסקת השירות
    / הורדת האתר.
    <br />
    21. דאבל שומרת לעצמה את הזכות לסגור את האתר או/ו לחסום את הכניסה לאתר בשבת,
    לפי הזמנים שיוחלטו על ידי דאבל. זכות זו נוגעת לזמנים בארץ ישראל וכן לזמנים
    בכל שאר העולם.
    <br />
    22. חל איסור על הנרשם להעלות כל תוכן המוגן בזכויות יוצרים השייכים לכל גורם
    אחר מלבד תכנים שזכויות היוצרים ו/או זכויות ההפצה הן בבעלות הנרשם.
    <br />
    23. דאבל אינה אחראית על חוקיות התכנים שמעלים המשתמשים, התכנים שמעלים
    המשתמשים הינם באחריות המשתמשים בלבד.
    <br />
    24. אי אחריות דאבל על חוקיות התכנים הינה למעט מקרים של "נוהל הודעה והסרה"
    ע"פ חוקי המדינה.
    <br />
    25. הנרשם מצהיר בהסכם זה כי אינו מתנגד להצגת תמונתו ופרטיו לכל אחד מהמשתמשים
    באתר.
    <br />
    26. דאבל שומרת לעצמה את הזכות לפרסם באתר, כל תוכן פרסום שהוא. בין אם הפרסום
    נעשה ישירות על ידי דאבל ובין אם הפרסום נעשה על ידי צד ג'. דאבל אינה אחראית
    לכל נזק שהוא שיגרם כתוצאה מפרסומים אלו.
    <br />
    27. בהסכם זה מצהיר הנרשם כי הוא משתף עם דאבל כל זכות שלו על תוכן גלוי שהוא
    מפרסם באתר.
    <br />
    28. חל איסור מוחלט להכניס/להעלות קבצים בכל דרך שהיא, קוד מחשב ותוכנות עם
    יישום המכיל נגיף מחשב ("וירוס"), דואר זבל ("SPAM"), יישומים מזיקים,
    וונדליזם, סוס טרויאני ותולעים ("WORMS") למיניהם. כל זאת במסגרת חוק המחשבים.
    <br />
    29. חל איסור מוחלט להעלאת פרסום תכנים ופעילויות פליליות מכל סוג שהוא ובכל
    דרך שהיא.
    <br />
    30. המשתמש והוא בלבד אחראי על התכנים שהוא מעלה לאתר ועל השלכותיהם. בהצהרה זו
    המשתמש פוטר ומסיר אחריות מהאתר ומנהליו לגבי כל תביעה ו/או טענה בקשר להפסד,
    אבדן, הפרת זכויות מכל סוג שהוא, הוצאה או נזק מכל סוג, שיגרם למשתמש ולסובבים
    לו בצורה עקיפה או ישירה כתוצאה מתכנים שהועלו ומופיעים באתר. בנוסף, במקרה שבו
    הנזק נגרם לאתר, הנרשם יפצה את האתר לפי אמדן הנזק.
    <br />
    31. בהתאם לאופי הפעילות באינטרנט, וכפי שקרה בעבר באתרים שונים בעולם. עלולים
    להיות מצבים שבו מידע רגיש מאתרי הכרויות דלף החוצה באמצעות שימוש לא חוקי,
    פריצה או מעשי זדון אחרים. עליך להימנע ממסירת מידע שאתה חושב שיש בו כדי לפגוע
    בפרטיותך.
    <br />
    32. חל איסור מוחלט להעלות חומר בוטה, פורנוגרפי, תמונות המכילות קטינים
    ודמויות אשר גילן לא עולה על 18 או עם מאפיינים מיניים בוטים שפוגעים או עלולים
    לפגוע ברגשות הציבור. הנרשם מצהיר בזאת שבמקרה ועבר על סעיף זה הינו מתחייב
    לשלם פיצוי בסך של 5,000 ש"ח לדאבל, ללא הוכחת נזק כל שהוא. זאת מבלי לגרוע
    מזכותה של דאבל למיצוי זכויותיה וכל סעד המגיע לה בגין הפרה כאמור.
    <br />
    33. למעט הפיצוי האמור בסע' 10 וסע' 31. משתמש שיעבור על כל אחד ו/או חלקם ו/או
    כולם מתנאי התקנון ישלם לדאבל פיצוי של 1,000 שח על כל סעיף בודד שעליו עבר.
    זאת, חוץ מתשלום הנזק שנגרם לאתר כתוצאה מעבירות הנ"ל.
    <br />
    34. דאבל אינה אחראית על מידע שנגנב או/ו נחשף או/ו נפרץ או/ו שונה, כשמקור
    הפריצה הוא החברה המאחסנת של האתר.
    <br />
    35. דאבל אינה אחראית לשום נזק שיגרם בעקבות תכנות לקוי שלא במטרת זדון.
    <br />
    36. דאבל שומרת לעצמה את הזכות להציג את תוכן האתר בכל סוגי המדיה הטכנולוגיים
    לרבות אך לא רק: טלפונים סלולריים ו/או טאבלטים, אפליקציה לסלולרי ו/או לטאבלט.
    סוגי המדיה יכללו את המדיות הנוספות המתחדשות מעת לעת.
    <br />
    37. קנין רוחני - דאבל היא הבעלים של כל הזכויות בשירות אשר כולל, אך לא רק,
    חומר שיש לגביו זכויות מסד נתונים, זכויות יוצרים, זכויות תכנון (בין שתהיה
    מסומנת ככזו או שלא תהיה מסומנת כמוגנת), סימן מסחרי (בין שיהיה רשום או בין
    שלא יהיה רשום), וזכויות דומות, בכל מקום בעולם, לצד הזכות להגיש בקשה לסמנו
    כמוגן. כל סימן מסחרי, לוגו, סימול שירות, שם חברה או מוצר שיפורטו בשירות יהיו
    בבעלות הבעלים של חומר זה.
    <br />
    38. האתר כמו גם כל המידע שבו לרבות עיצוב האתר, קוד האתר, קבצי מדיה לרבות
    גרפיקות, סרטונים, תמונות, טקסטים, קבצים המוצעים להורדה וכל חומר אחר אשר מוצג
    באתר שייכים במלואם לאתר הנ"ל ומהווים קניין רוחני בלעדי של דאבל ואין לעשות
    בהם שימוש ללא אישור כתוב מראש מדאבל.
    <br />
    39. בנוסף אין להפיץ, להעתיק, לשכפל, לפרסם, לחקות או לעבד פיסות קוד, גרפיקות,
    סרטונים, סימנים מסחריים או כל מדיה ותוכן אחר מבלי שיש ברשותכם אישור כתוב
    מראש.
    <br />
    40. אתר זה עשוי לעשות שימוש בקבצי קוקיז (במיוחד עבור משתמשים רשומים ומנויים)
    ובממשקי סטטיסטיקה פנימיים בכדי לשמור תיעוד סטטיסטי אנונימי של גולשים וניתוח
    תנועת הגולש/ים, הרגלי גלישה באתר וניתוח קליקים וזמן שהייה.
    <br />
    41. הנהלת האתר שומרת לעצמה את הזכות לחסום כל משתמש ובין אם על ידי חסימת
    כתובת ה IP של המחשב שלו, כתובת ה MACID של המחשב שלו או אפילו בהתאם למדינת
    המוצא ללא צורך לספק תירוץ אשר מקובל על המשתמש.
    <br />
    42. מנויים באתר - מפעילת האתר רשאית להעניק מנויים לאנשים או לבטלם ללא הודעה
    מוקדמת וללא תנאי.
    <br />
    43. ניתן לבטל מנוי באתר בהתאם להוראות חוק הגנת הצרכן, תשמ"א - 1981. הביטול
    יעשה ע"י יצירת קשר ובקשה באחת מדרכי ההתקשרות עם מפעילת האתר.
    <br />
    44. במיקרה בו שידוך יוצא לפועל(חתונה) - יש לשלם דמי שדכנות כפי הבנתכם.
    <br />
    45. כל האמור בתקנון מנוסח מטעמי נוחות בלשון זכר, אך מכוון לנשים וגברים כאחד.
    <br />
    46. לבית משפט שלום בירושלים או בית משפט מחוזי בירושלים לפי העניין, תהיה
    סמכות מקומית בלעדית לדון בכל סכסוך הנובע ו/או הנוגע להסכם זה, לרבות פרשנותו,
    תוקפו, ביצועו, ביטולו ו/או סיומו תחת סמכות השיפוט הבלעדי של החוק הישראלי. כל
    הזכויות באתר שמורות לדאבל. דאבל הינה הבעלים הבלעדיים של כל הזכויות באתר, בין
    במקום שצוין זאת ובין במקום שלא צוין זאת. דבר זה תקף בכל מקום בעולם.
    <br />
    <br />
    כתובת: ירושלים
    <br />
    אימייל: Doublelo2love@gmail.com
    <br />
    טלפון: 0549624084
  </div>
);
export default TermsAndConditionsText;
