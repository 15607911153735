import React, { useRef, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./FunDates.css";
import { Ellipsis } from "react-spinners-css";
import Header from "../../components/Header/Header";
import HeaderBackToBlog from "../../components/Header/HeaderBackToBlog";
import { getVip } from "../../handlers/dictionaryHandler";

const FunDates = () => {
  const history = useHistory();
  const questions = [
    {
      photo: "tap",
      text: "אם את צריכה לבחור בין חשמל לבין מים זורמים, מה היית מעדיפה? למה?",
      link: "",
    },
    {
      photo: "oldObject",
      text: "  משהו שיש לך בבית ולעולם לא תזרוק למרות שהוא לא בשימוש?",
      link: "",
    },
    {
      photo: "waytojerus",
      text: "אם היית בשיירות לירושלים הנצורה, היית מעדיף להביא תרופות, נשק או מזון?  אפשר לקרוא עוד על השיירות לירושלים  ",
      link: "https://he.wikipedia.org/wiki/%D7%94%D7%A9%D7%99%D7%99%D7%A8%D7%95%D7%AA_%D7%9C%D7%99%D7%A8%D7%95%D7%A9%D7%9C%D7%99%D7%9D",
    },
    {
      photo: "globe",
      text: "לאן היית עושה טיול גדול בעולם? למה? ",
      link: "",
    },
    {
      photo: "changecareere",
      text: "אם היית עושה הסבה מקצועית, לאיזה תחום?",
      link: "",
    },
    {
      photo: "moovie",
      text: "סרט/סדרה שראית, והשאיר בך חותם",
      link: "",
    },
    {
      photo: "extreme",
      text: "חווית אקסטרים שעשית או היית רוצה לעשות?",
      link: "",
    },
    {
      photo: "hundrednis",
      text: "אם היית מגלה שנשארו לך 100 שח בחשבון, מה היית עושה? ",
      link: "",
    },
    {
      photo: "food",
      text: "מהו המאכל שהכי מעורר בך געגוע? ",
      link: "",
    },
    {
      photo: "teacher",
      text: "מורה שאת זוכרת? לא חייב להיות זכרון טוב דווקא (אבל בואו לא נלכלך..)",
      link: "",
    },
    {
      photo: "cars",
      text: "כביש מהיר, שלושה נתיבים. אתה בנתיב האמצעי, אתה רואה שרכב מהנתיב הימני ורכב מהנתיב השמאלי מתחילים להיכנס יחד לנתיב שלך. מה אתה עושה??",
      link: "",
    },
    {
      photo: "cellphone",
      text: "יצאת מהבית, שכחת את הטלפון. מה תעשי/ה?",
      link: "",
    },
    {
      photo: "philantrop",
      text: "נדבן מסתורי משלם לך מחייה לשנה שלימה. אין צורך לעשות כלום בתמורה. מה תעשי/ה בשנה זו?",
      link: "",
    },
    {
      photo: "lowkid",
      text: "כשהייתי ילד הייתי נמוך, לא שיחקתי כדורסל כי הייתי נמוך, חשבו שאני הכי קטן, ופחות סמכו עלי. בגיל 17 גבהתי פתאום ממש הרבה. היום בגיל 28 עדיין יש לי אמונה בראש שאני נמוך על אף שאני לא. עכשיו כשביקשו ממני להוריד משהו ממדף גבוה כי אני גבוה - זה היה לי מוזר. והבנתי שהגיע הזמן להיפרד מהאמונה הזו. היא כבר לא נכונה. גם לך יש אמונות על עצמך שהן כבר לא רלוונטיות?",
      link: "",
    },
    {
      photo: "boss",
      text: "הבוס בעבודה כועס עליך שלא עשית משהו שהוא ביקש. את יודעת שעשית את זה, והוא כנראה פיספס את המייל. מה תעשי?",
      link: "",
    },
    {
      photo: "castume",
      text: "אתה מתחפש בפורים? רוצה לשתף על תחפושת כלשהי מהעבר?",
      link: "",
    },
    {
      photo: "beggar",
      text: "יושבים במסעדה ואוכלים. קבצן ניגש. מה עושים?",
      link: "",
    },
    {
      photo: "moses",
      text: 'דמות מהתנ"ך שהיית רוצה לפגוש',
      link: "",
    },
    {
      photo: "supermarket",
      text: " אתה מגיע לסופר לרכוש שני מוצרים, בקופות יש תורים כאורך הגלות. מה תעשה?",
      link: "",
    },
  ];

  const [curPhoto, setCurPhoto] = useState("");
  const [curText, setCurText] = useState("");
  const [curLink, setCurLink] = useState("");

  useEffect(() => {
    setNewCard();
  }, []);

  const setNewCard = () => {
    let i;
    i = getVip() === "1" ? Math.floor(Math.random() * questions.length) : 1;
    setCurPhoto(questions[i].photo);
    setCurText(questions[i].text);
    setCurLink(questions[i].link);
  };
  return (
    <form className="forum-main-form">
      <HeaderBackToBlog />
      <br></br>

      <div className="form-body">
        <div className="choose_room">
          <h1 className="h1-center">שאלות מעניינות</h1>

          <div className="aquestion shadowcard">
            <div
              className={"fundatephoto " + curPhoto}
              // style={{
              //   backgroundSize: "cover",
              //   width: "180px",
              //   height: "100px",

              //   margin: "0 auto",
              //   border: "1px solid black",
              //   padding: "3px",
              //   backgroundImage: "url(" + { curPhoto } + ")",
              // }}
            ></div>

            <div className="textcontainer ">
              <div className="fundatetext ">{curText}</div>
              {curLink !== "" ? (
                <a
                  href={curLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="white"
                >
                  {" "}
                  כאן
                </a>
              ) : (
                ""
              )}{" "}
            </div>
          </div>
        </div>

        <br />

        {getVip() === "1" ? (
          <div className="newQuestionBtn" onClick={setNewCard}>
            {" "}
            שאלה חדשה :)
          </div>
        ) : (
          <div className="newQuestionBtngrey"> שאלות נוספות פתוחות למנויים</div>
        )}
        <div className="smalllogo" />
        <div className="lbl_left">Double2love.com </div>
      </div>
    </form>
  );
};

export default FunDates;
