import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { getSignedIn } from "../../handlers/dictionaryHandler.js";

const HeaderBack = () => {
  return (
    <div className="headerback">
      <Link
        to={getSignedIn() === "1" ? "/questionnaires" : "/"}
        style={{ textDecoration: "none" }}
      >
        <div className="backhome" />
      </Link>
    </div>
  );
};

export default HeaderBack;
