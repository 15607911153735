import React from "react";
import "./TermsAndConditions.css";
import TermsAndConditionsText from "./TermsAndConditionsText";

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions-container">
      <span style={{ fontSize: "10px" }}> בס“ד</span>
      <h3>הסכם השימוש באתר ’דאבל הכרויות‘￼</h3>

      {/* className="terms-and-conditions-p" */}
      <div className="terms-and-conditions-p">{TermsAndConditionsText}</div>
    </div>
  );
};

export default TermsAndConditions;
