import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Files from "react-files";
import { Ellipsis } from "react-spinners-css";
import Slider, { Range } from "rc-slider";
import splitArray from "split-array";
import "rc-slider/assets/index.css";
import "../../App.css";
import "../../slider.css";
import HeaderBackToEdit from "../../components/Header/HeaderBackToEdit";
// import { Slider } from "rsuite";
import axios from "axios";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import FreetextHelper from "../../components/FreetextHelper/FreetextHelper";
import PhotoHelper from "../../components/PhotoHelper/PhotoHelper";
import RecordHelper from "../../components/RecordHelper/RecordHelper";
import { getWordByGender } from "../../handlers/dictionaryHandler.js";
import "react-datepicker/dist/react-datepicker.css";
// import { setTwilioEmailAuth } from "@sendgrid/mail";
import Resizer from "react-image-file-resizer";
import ReactPlayer from "react-player";
import { Line } from "rc-progress";
import { ReactMic } from "react-mic";
import FileManager from "../../utils/FileManager";

const MAX_IMAGES = 4;

const format = (time) => {
  // Hours, minutes and seconds
  var hrs = ~~(time / 3600);
  var mins = ~~((time % 3600) / 60);
  var secs = ~~time % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
};

const dataURItoBlobAndBuffer = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return [blob, ab];
};

const Pic = () => {
  let history = useHistory();
  const myRef = useRef(null);
  const [viewIndex, setViewIndex] = useState(1);
  const [image1, setImage1] = useState("");
  const [additionalImages, setAdditionalImages] = useState([]);

  const [image1BlobURL, setImage1BlobURL] = useState("");
  const [isImage1CheckLoading, setIsImage1CheckLoading] = useState(false);
  const [isImage1Valid, setIsImage1Valid] = useState(false);

  const [freetext, setFreetext] = useState("");
  const [freeTextLength, setFreeTextLength] = useState("");
  const [origin, setOrigin] = useState("");
  const [cellFriend, setCellFriend] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState();
  const [myRecorda, setMyRecorda] = useState(true);

  const [isUploadRecordErrorDisplyed, setIsUploadRecordErrorDisplyed] =
    useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isMicEnabled, setisMicEnabled] = useState(false);
  const [recordingBlobURL, setRecordingBlobURL] = useState("");
  const [recordingBlob, setRecordingBlob] = useState(null);
  const [isRecordPlaying, setIsRecordPlaying] = useState(false);
  const [recordLength, setRecordLength] = useState(0);
  const [recordingPlayingProgress, setRecordingPlayingProgress] =
    useState(null);
  const [recordingTimeout, setRecordingTimeout] = useState(null);
  const maxRecordingTimeInSeconds = 120;
  const [recordingProgress, setRecordingProgress] = useState(0);
  const [recordingProgressInterval, setRecordingProgressInterval] =
    useState(null);
  const [recordingBlobBufferArray, setRecordingBlobBufferArray] = useState([]);
  const [
    isTriedToRecordWithoutPremissions,
    setIsTriedToRecordWithoutPremissions,
  ] = useState(false);

  const [isFreetextHelperDisplayed, setIsFreetextHelperDisplayed] =
    useState(false);
  const [isPhotoHelperDisplayed, setIsPhotoHelperDisplayed] = useState(false);
  const [isRecordHelperDisplayed, setIsRecordHelperDisplayed] = useState(false);

  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);
  const [isWriteWithMeDisplayed, setIsWriteWithMeDisplayed] = useState(false);
  const [yourCharacterWithMe, setYourCharacterWithMe] = useState("");
  const [otherCharacterWithMe, setOtherCharacterWithMe] = useState("");
  const [inspiredWithMe, setInspiredWithMe] = useState("");
  const [motoWithMe, setMotoWithMe] = useState("");
  const [experienceWithMe, setExperienceWithMe] = useState("");

  const handleWriteWithMe = (val) => {
    setIsWriteWithMeDisplayed(false);
    setFreetext(
      " הי לך :) " +
        "אפשר לומר שאני " +
        yourCharacterWithMe +
        ", " +
        getWordByGender("i_love") +
        " " +
        inspiredWithMe +
        ". " +
        " לאחרונה " +
        experienceWithMe +
        ". " +
        getWordByGender("i_appreciate") +
        otherCharacterWithMe +
        ". " +
        getWordByGender("i_believe") +
        motoWithMe
    );
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [imagesCounter, setImagesCounter] = useState(0);

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((stream) => {
        setisMicEnabled(true);
      })
      .catch((err) => {
        setisMicEnabled(false);
      });
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_LOCALHOST_ADDRESS + "/PaboutMe",
          config
        );
        const userData = response.data;

        // setEmail(userData.email);

        if (userData.image1 !== "") {
          const image1Data = await FileManager.getFile(userData.image1);
          setImage1(image1Data.file);
          setImage1BlobURL(image1Data.blobURL);
          setIsImage1Valid(true);
        }

        const currentAdditionalImages = [];

        if (userData.image2 !== "") {
          const image2Data = await FileManager.getFile(userData.image2);
          currentAdditionalImages.push({
            resizedImage: image2Data.file,
            imageURL: image2Data.blobURL,
          });
        }

        if (userData.image3 !== "") {
          const image3Data = await FileManager.getFile(userData.image3);
          currentAdditionalImages.push({
            resizedImage: image3Data.file,
            imageURL: image3Data.blobURL,
          });
        }
        if (userData.image4 !== "") {
          const image4Data = await FileManager.getFile(userData.image4);
          currentAdditionalImages.push({
            resizedImage: image4Data.file,
            imageURL: image4Data.blobURL,
          });
        }
        if (userData.image5 !== "") {
          const image5Data = await FileManager.getFile(userData.image5);
          currentAdditionalImages.push({
            resizedImage: image5Data.file,
            imageURL: image5Data.blobURL,
          });
        }

        setAdditionalImages(currentAdditionalImages);

        if (
          userData.occupation.length > 0 ||
          userData.freeText.length > 0 ||
          userData.origin.length > 0 ||
          userData.rec1.length > 0 ||
          userData.cellFriend.length > 0
        ) {
          handleText(userData.freeText);
          setOrigin(userData.origin);
          setCellFriend(userData.cellFriend);
          // To load the record
          const rec1Buffer = userData.rec1
            .split(",,")
            .map(
              (current) =>
                new Int8Array(current.split(",").map((current) => +current))
            );
          const blob1 = new Blob(rec1Buffer, {
            type: "audio/mp3",
          });
          const blob1URL = URL.createObjectURL(blob1);

          setRecordingBlobURL(blob1URL);
          setRecordingBlobBufferArray(userData.rec1);
          setRecordingBlob(blob1);
          setMyRecorda(userData.myRec1);
        }

        setIsDataLoaded(true); // in case its a new user
      } catch (error) {
        // probebly user is not authenticated or server is down
      }
    };

    loadData();
  }, []);

  const onImagesSelected = async (files) => {
    setImagesCounter(files.length);

    const newAdditionalImages = [];

    for (const file of files) {
      if (additionalImages.length + newAdditionalImages.length >= MAX_IMAGES) {
        break;
      }
      const resizedImage = await resizeFile(file);
      const imageURL = await FileManager.fileToURL(resizedImage);

      const imageUrls = additionalImages.filter(
        (currentImage) => currentImage.imageURL !== imageURL
      );
      if (imageUrls.length === additionalImages.length) {
        newAdditionalImages.push({
          resizedImage,
          imageURL,
        });
      }
    }

    setAdditionalImages([...additionalImages, ...newAdditionalImages]);
  };

  const onMainImageSelected = async (files) => {
    setIsImage1CheckLoading(true);
    const resizedImage1ForTest = await resizeFileForTest(files[0]);
    let data = new FormData();
    data.append("firstImage", resizedImage1ForTest);

    const configImage = {
      withCredentials: true,
    };

    let isImageValid = false;
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/upload/test-first-image",
        data,
        configImage
      );
      isImageValid = true;
    } catch (error) {
      isImageValid = false;
    }

    setIsImage1Valid(isImageValid);

    setIsImage1CheckLoading(false);

    if (files.length > 0 && isImageValid) {
      const image1 = files[0];
      const resizedImage1 = await resizeFile(files[0]);
      const image1URL = await FileManager.fileToURL(image1);

      setImage1(resizedImage1);
      setImage1BlobURL(image1URL);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const resizeFileForTest = (file) =>
    new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
      Resizer.onerror = (error) => {
        reject(error);
      };
    });

  const resizeFile = (file) =>
    new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        1920,
        1920,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
      Resizer.onerror = (error) => {
        reject(error);
      };
    });

  const alertUser = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage(message);
  };

  const handleText = (text) => {
    setFreetext(text);
    setFreeTextLength(text.length);
  };

  const imgClick = (imgId) => {
    if (document.getElementById(imgId).style.width != "240px") {
      document.getElementById(imgId).style.maxHeight = "290px";
      document.getElementById(imgId).style.width = "240px";
    } else {
      document.getElementById(imgId).style.maxHeight = "100px";
      // document.getElementById(imgId).style.maxHeight = "50px";
      document.getElementById(imgId).style.width = "100px";
      // document.getElementById(imgId).style.flex = "1";

      // document.getElementById("img1").style.backgroundsize = "cover";
      // document.getElementById("img1").style.position = "absolute";
    }
  };

  const onClickMyRecord = () => {
    setMyRecorda(!myRecorda);
  };

  const onSubmitStep3 = async () => {
    if (freetext.length < 800) {
      if (origin.length < 20) {
        if (cellFriend.length < 20) {
          try {
            const response = await axios.post(
              process.env.REACT_APP_LOCALHOST_ADDRESS + "/PaboutMeStep3",
              {
                freetext,
                origin,
                cellFriend,
                recordBlobArray1: recordingBlobBufferArray.toString(),
                myRecorda,
                recordBlobArray2: "",
                myRecordb: myRecorda,
              },
              config
            );
            if (response.data === true) {
              // localStorage.getItem("fromMiror") == 1 &&
              // Cookies.get("newUser") == 1 // &&&&&&&
              //   ? history.push("/myprofile") :
              history.push("/myprofile");
            } else {
              alertUser("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
            }
          } catch (error) {
            console.log(error);
            alertUser("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
          }
        } else {
          alertUser("טלפון לבירורים ארוך מידי");
          console.log("occupation too long");
        }
      } else {
        alertUser("מוצא ארוך מידי");
        console.log("origin too long");
      }
    } else {
      alertUser("טקסט חופשי ארוך מידי ");
      console.log("Text too long");
    }
  };

  const onSubmitStep4 = async () => {
    if (image1 !== "") {
      try {
        let data = new FormData();
        data.append("firstImage", image1);
        additionalImages.forEach((image, index) => {
          data.append(`file${index}`, image.resizedImage);
        });

        const configImage = {
          withCredentials: true,
        };

        const response = await axios.post(
          process.env.REACT_APP_LOCALHOST_ADDRESS + "/upload/image",
          data,
          configImage
        );

        // console.log(response.data);

        if (response.status === 200) {
          setViewIndex(2);
          myRef.current.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          alertUser("");
        } else {
          alertUser("יש שגיאה, יתכן שהתמונה שצירפת גדולה מידי, נסו תמונה אחרת");
        }
      } catch (error) {
        console.log(error);
        alertUser("יש שגיאה, יתכן שהתמונה שצירפת גדולה מידי, נסו תמונה אחרת");
      }
    } else {
      alertUser("חובה להעלות תמונה, לא לכל המשתמשים יש פייסבוק");
      console.log("Facebook url is not valid");
    }
  };

  const startRecording = () => {
    setIsRecordPlaying(false);
    setRecordLength(0);
    setRecordingBlobURL("");
    setRecordingBlobBufferArray([]);
    setRecordingPlayingProgress(null);
    setIsRecording(true);
    setRecordingProgress(0);
    setIsRecordPlaying(false);
  };

  useEffect(() => {
    console.log(isRecording);
    if (isRecording) {
      setRecordingProgressInterval(
        setInterval(() => {
          setRecordingProgress((recordingProgress) => recordingProgress + 0.5);
        }, 500)
      );
    }
    return () => {
      clearInterval(recordingProgressInterval);
    };
  }, [isRecording]);

  const stopRecording = () => {
    console.log("Stop Recording");
    setIsRecordPlaying(false);
    setIsRecording(false);
    setRecordingPlayingProgress(null);

    if (recordingTimeout) {
      clearTimeout(recordingTimeout);
    }
    if (recordingProgressInterval) {
      clearInterval(recordingProgressInterval);
    }
  };

  return (
    <form id="formId" ref={myRef}>
      <HeaderBackToEdit />

      {isDataLoaded === true ? (
        <>
          <div className="steps">
            <div
              className={"step-about-me-after"}
              onClick={() => setViewIndex(1)}
            >
              1
            </div>
            <div
              className={
                viewIndex > 1 ? "line-between-step-after" : "line-between-step"
              }
            ></div>
            <div
              className={
                viewIndex > 1 ? "step-about-me-after" : "step-about-me"
              }
              onClick={() => setViewIndex(2)}
            >
              2
            </div>
          </div>

          <div className="page-title">
            <div>כמעט מסיימים!!</div>
          </div>

          <div className="aboutme-body">
            {viewIndex === 2 && (
              <>
                <h5 className="vipDescription">
                  אם העמוד מאתגר, אפשר להמשיך לעמוד הבא ולחזור בהמשך. עמוד זה
                  הוא לא חובה. (אבל מומלץ!)
                </h5>
                <div className="faceLine">
                  <br />
                  <div className="section-title">
                    <b>מוצא</b>
                  </div>
                  <textarea
                    className="occupation"
                    rows="1"
                    maxLength="19"
                    value={origin}
                    onChange={(e) => setOrigin(e.target.value)}
                  ></textarea>
                </div>
                <div className="line" />

                <div className="faceline">
                  <div className="section-title">
                    <b> טקסט חופשי </b>
                    {freeTextLength + "/800 "}{" "}
                  </div>

                  <div
                    className="helphand-without-margin shadow"
                    onClick={() => setIsFreetextHelperDisplayed(true)}
                  />
                </div>

                <Popup
                  open={isFreetextHelperDisplayed}
                  contentStyle={{
                    minWidth: "30%",
                    maxWidth: "60%",
                    borderRadius: "5px",
                  }}
                  position="right center"
                  onClose={() => setIsFreetextHelperDisplayed(false)}
                  closeOnDocumentClick
                  closeOnEscape
                >
                  {FreetextHelper}
                </Popup>
                {/* <input type='text' id='freetext' placeholder=' כיף לקבל ולקרוא על אחרים,\n לא חובה,לבחירתך:)\n ניתן להשאיר מס טלפון לבירורים'></input> */}
                {/* <input type='text' id='freetext' placeholder={'njlef&#10;dwd'}></input> */}
                <div className="freetext-container" style={{ height: "260px" }}>
                  <textarea
                    className="freetext"
                    style={{ height: "250px" }}
                    rows="16"
                    maxLength="799"
                    // placeholder="כיף לקבל ולקרוא על אחרים, &#13;&#10; לא חובה, לבחירתך:) &#13;&#10; נ.ב. אפשר להשאיר מספר טלפון לבירורים"
                    placeholder="ממש כדאי להשקיע כמה רגעים ולכתוב&#13;&#10;  או לתת לחבר שיכתוב עלינו.&#13;&#10;  יש המלצות לטקסט מכפתור המידע שנמצא מעל התיבה"
                    value={freetext}
                    onChange={(e) => handleText(e.target.value)}
                  ></textarea>
                </div>
                <div
                  className="write-with-me shadow"
                  onClick={() => setIsWriteWithMeDisplayed(true)}
                >
                  רוצה שנכתוב ביחד איתך?
                </div>

                <Popup
                  open={isWriteWithMeDisplayed}
                  contentStyle={{
                    minWidth: "30%",
                    maxWidth: "60%",
                    borderRadius: "5px",
                  }}
                  position="right center"
                  onClose={handleWriteWithMe}
                  closeOnDocumentClick
                  closeOnEscape
                >
                  <div className="terms-and-conditions-p">
                    <div className="lbl-write-with-me">
                      3 תכונות אופי טובות שלך{" "}
                    </div>
                    <textarea
                      className="txt-write-with-me"
                      rows="2"
                      value={yourCharacterWithMe}
                      onChange={(e) => setYourCharacterWithMe(e.target.value)}
                    ></textarea>
                    <div className="lbl-write-with-me">
                      משהו שמרגש אותך, או נותן לך השראה{" "}
                    </div>
                    <textarea
                      className="txt-write-with-me"
                      rows="2"
                      value={inspiredWithMe}
                      onChange={(e) => setInspiredWithMe(e.target.value)}
                    ></textarea>
                    <div className="lbl-write-with-me">
                      חוויה מיוחדת מהשנתיים האחרונות, תשובה מלאה, נניח: טיילתי
                      בהודו, סיימתי קורס, התנדבתי ב..{" "}
                    </div>
                    <textarea
                      className="txt-write-with-me"
                      rows="2"
                      value={experienceWithMe}
                      onChange={(e) => setExperienceWithMe(e.target.value)}
                    ></textarea>
                    <div className="lbl-write-with-me">
                      תכונה או תחביב או התנהגות שמשמחת אותך אצל אחרים{" "}
                    </div>
                    <textarea
                      className="txt-write-with-me"
                      rows="2"
                      value={otherCharacterWithMe}
                      onChange={(e) => setOtherCharacterWithMe(e.target.value)}
                    ></textarea>

                    <div className="lbl-write-with-me">מה המוטו שלך לחיים </div>
                    <textarea
                      className="txt-write-with-me"
                      rows="2"
                      value={motoWithMe}
                      onChange={(e) => setMotoWithMe(e.target.value)}
                    ></textarea>
                    <div className="lbl-write-with-me">
                      ועכשיו סיגרו את הפופאפ, ותראו מה הטקסט שיצא :) תקנו במידת
                      הצורך{" "}
                    </div>
                  </div>
                </Popup>

                <div className="faceLine">
                  <br />
                  <div className="section-title">
                    <b>טלפון לבירורים</b>
                  </div>
                  <input
                    className="occupation"
                    rows="1"
                    value={cellFriend}
                    onChange={(e) => setCellFriend(e.target.value)}
                  ></input>
                </div>

                <div className="line" />

                <div className="faceline">
                  <div className="section-title">
                    {" "}
                    <b>הקלטות</b>- כך ישמעו וירגישו אותך מעט :)
                  </div>
                  <div
                    className="helphand-without-margin shadow"
                    onClick={() => setIsRecordHelperDisplayed(true)}
                  />
                </div>

                <Popup
                  open={isRecordHelperDisplayed}
                  contentStyle={{
                    minWidth: "30%",
                    maxWidth: "60%",
                    borderRadius: "5px",
                  }}
                  position="right center"
                  onClose={() => setIsRecordHelperDisplayed(false)}
                  closeOnDocumentClick
                  closeOnEscape
                >
                  {RecordHelper}
                </Popup>

                <div>
                  <ReactMic
                    record={isRecording} // defaults -> false.  Set to true to begin recording
                    className="recorder-tag"
                    onStop={(recordedBlob) => {
                      setRecordLength(
                        Math.floor(
                          (recordedBlob.stopTime - recordedBlob.startTime) /
                            1000
                        )
                      );

                      setRecordingBlobURL(recordedBlob.blobURL);
                      setRecordingBlob(recordedBlob.blob);

                      const fileReader = new FileReader();
                      fileReader.onload = function (event) {
                        const arrayBuffer = event.target.result;
                        setRecordingBlobBufferArray(new Int8Array(arrayBuffer));
                      };
                      fileReader.readAsArrayBuffer(recordedBlob.blob);
                    }} // callback to execute when audio stops recording
                    mimeType="audio/mp3" // defaults -> audio/wav. Set audio/mp3 to switch to MP3
                    bufferSize={2048} // defaults -> 2048. You can set following bufferSize values: 0, 256, 512, 1024, 2048, 4096, 8192, and 16384.
                    sampleRate={44100} // defaults -> 44100. It accepts values only in range: 22050 to 96000
                  />
                  {!isMicEnabled && isTriedToRecordWithoutPremissions ? (
                    <p
                      className="recording-premission-error"
                      style={{ margin: "0px" }}
                    >
                      לא קיבלנו הרשאות להקליט, ולכן אפשר רק להעלות הקלטה
                    </p>
                  ) : (
                    <p style={{ margin: "0px" }}>
                      אפשר להקליט או להעלות הקלטה קיימת
                    </p>
                  )}
                  {recordingPlayingProgress ? (
                    <p style={{ margin: "0px" }}>
                      {format(recordingPlayingProgress.playedSeconds)} /{" "}
                      {format(recordLength)}
                    </p>
                  ) : isRecording || recordingBlobURL !== "" ? (
                    <p style={{ margin: "0px" }}>
                      {format(recordingProgress)} /{" "}
                      {format(maxRecordingTimeInSeconds)}
                    </p>
                  ) : null}
                  {recordingPlayingProgress ? (
                    <div className="faceline">
                      <Line
                        className="record-progress-bar"
                        percent={
                          (recordingPlayingProgress.playedSeconds /
                            recordLength) *
                          100
                        }
                        strokeWidth="2"
                        strokeColor="#f2b807"
                      />
                      <h4
                        className="remove-image-button"
                        onClick={() => {
                          setIsRecording(false);
                          setRecordingBlobURL("");
                          setRecordingBlob(null);
                          setRecordingBlobBufferArray("");
                          setRecordingPlayingProgress(null);
                          setIsRecordPlaying(false);
                          clearTimeout(recordingTimeout);
                          setRecordingProgress(0);
                        }}
                      >
                        X
                      </h4>
                    </div>
                  ) : isRecording ? (
                    <Line
                      className="record-progress-bar"
                      percent={
                        (recordingProgress / maxRecordingTimeInSeconds) * 100
                      }
                      strokeWidth="2"
                      strokeColor="#f2b807"
                    />
                  ) : null}
                </div>

                <ReactPlayer
                  playing={isRecordPlaying}
                  autoPlay={false}
                  url={recordingBlobURL}
                  controls={false}
                  progressInterval={100}
                  onProgress={(progressObject) => {
                    setRecordingPlayingProgress(progressObject);
                  }}
                  onDuration={(duration) => {
                    if (duration !== Infinity) {
                      setRecordLength(duration);
                    }
                  }}
                  config={{
                    file: {
                      forceAudio: true, // before it was true, maybe it would fix the prob in iphone
                    },
                  }}
                  onEnded={() => {
                    setIsRecordPlaying(false);
                  }}
                  width="0px"
                  height="0px"
                />

                <div className="record-line">
                  <div style={{ width: "33.3%" }}>
                    <div
                      // className="recordicon-aboutme"
                      className={
                        "recordicon-aboutme " +
                        (isRecording ? "pause-icon" : "record-icon ")
                      }
                      onClick={() => {
                        if (!isRecording) {
                          if (isMicEnabled) {
                            startRecording();
                            setIsRecording(!isRecording);
                          } else {
                            setIsTriedToRecordWithoutPremissions(true);
                          }
                        } else {
                          stopRecording();
                          setIsRecording(!isRecording);
                        }
                      }}
                    ></div>
                    <p className="lbl">לחיצה להקלטה</p>
                  </div>
                  <div style={{ width: "33.3%" }}>
                    <div
                      className={
                        "playicon-button " +
                        (isRecordPlaying ? "pause-icon" : "play-icon")
                      }
                      onClick={() => {
                        if (recordingBlobURL != "" && !isRecording) {
                          setIsRecordPlaying(!isRecordPlaying);
                        }
                      }}
                    ></div>
                    <p className="lbl">לשמיעה</p>
                  </div>
                  <div style={{ width: "33.3%" }}>
                    <fieldset style={{ marginTop: "-12px", marginLeft: "5px" }}>
                      <div>
                        <input
                          type="radio"
                          className="myRadio"
                          checked={myRecorda}
                          name="meRadio"
                          onChange={onClickMyRecord}
                        ></input>
                        <label>אני בהקלטה</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          className="myRadio"
                          checked={!myRecorda}
                          name="meRadio"
                          onChange={onClickMyRecord}
                        ></input>
                        <label>מספרים עלי </label>
                      </div>
                    </fieldset>
                    <div>
                      {/*  <label className="toggle">
                        <input
                          type="checkbox"
                          onChange={onClickMyRecord}
                          checked={myRecorda}
                        />
                        <span className="slider-in-toggle-aboutme"></span>
                      </label>
                     <p className="lbl">
                        {myRecorda ? "אני בהקלטה" : "מספרים עלי"}
                      </p> */}
                    </div>
                  </div>
                </div>

                <div className="images-drop-area-container">
                  <Files
                    // className="files-dropzone"

                    onChange={(newFile) => {
                      if (newFile.length > 0) {
                        // console.log(newFile);
                        const fileReader = new FileReader();

                        fileReader.onload = () => {
                          setIsUploadRecordErrorDisplyed(false);

                          let [blob, blobBuffer] = dataURItoBlobAndBuffer(
                            fileReader.result
                          );
                          blobBuffer = new Uint8Array(blobBuffer);

                          setRecordingBlobBufferArray(blobBuffer);
                          setRecordingBlob(blob);

                          // To Play from array
                          const blobURL = URL.createObjectURL(blob);
                          setRecordingBlobURL(blobURL);
                        };
                        fileReader.onerror = (error) => {
                          console.log(error);
                        };
                        fileReader.readAsDataURL(newFile[0]);
                      } else {
                        setIsUploadRecordErrorDisplyed(true);
                      }
                    }}
                    onError={(error) => {
                      setIsUploadRecordErrorDisplyed(true);
                    }}
                    multiple={false}
                    accepts={[".mp3"]}
                    maxFileSize={4000000}
                    minFileSize={1}
                    clickable
                    className="images-drop-area"
                  >
                    גרירה או לחיצה להעלאת הקלטה קיימת
                  </Files>
                  {isUploadRecordErrorDisplyed && (
                    <p className="recording-filesize-error">
                      {" "}
                      ההקלטה שהעלת גדולה מידי או לא בפורמט mp3
                    </p>
                  )}
                </div>
                <br />
              </>
            )}

            {viewIndex === 1 && (
              <>
                <div className="section-title">
                  <b> ועכשיו נבחר תמונות :)</b>
                </div>
                <br></br>

                <h5 className="vipDescription">
                  רק מי שמקבל אתכם כהתאמה יכול לראות את התמונה. לשאר המשתמשים
                  אין גישה לתמונות (אין דיפדוף).
                  <br />
                  וחובה להעלות תמונה ראשית
                </h5>
                <div className="files-dropzone">
                  <div className="images-drop-area-container">
                    <Files
                      // className="files-dropzone"
                      onChange={onMainImageSelected}
                      onError={(error) => {
                        console.log(error);
                      }}
                      accepts={["image/png", ".jpeg", ".jpg", ".webpg"]}
                      multiple={false}
                      maxFileSize={100000000} // 100MB
                      minFileSize={0}
                      clickable
                      className="images-drop-area"
                    >
                      גרירה או לחיצה להעלאת תמונה
                      <br />
                      <b>תמונה ראשית- תמונה שרואים פנים, ואין טקסט</b>
                    </Files>
                  </div>
                  <div
                    className="helphand shadow"
                    onClick={() => setIsPhotoHelperDisplayed(true)}
                  />
                </div>
                <div className="faceline">
                  {/* key={image2} */}
                  {isImage1CheckLoading && (
                    <div
                      className="loading-data-container"
                      style={{ maxHeight: "120px" }}
                    >
                      <div className="loading-data-div">
                        <Ellipsis />
                        <h4>
                          התמונה החדשה שלך בבדיקה, עוד כמה שניות מסיימים :)
                        </h4>
                      </div>
                    </div>
                  )}
                  {!isImage1Valid && !isImage1CheckLoading && (
                    <h4>
                      התמונה החדשה בעייתית, צריך להעלות תמונה שרואים פנים ואין
                      בה טקסט..{" "}
                    </h4>
                  )}
                  {image1 !== "" ? (
                    <>
                      <img
                        className="chosen-image"
                        src={image1BlobURL}
                        alt=""
                        id="img1"
                        onClick={(e) => imgClick("img1")}
                      />
                      <h4
                        className="remove-image-button"
                        onClick={() => {
                          // console.log("Image 1 delete");
                          setImage1("");
                          setImage1BlobURL(null);
                        }}
                      ></h4>
                    </>
                  ) : null}
                </div>
                <div className="files-dropzone">
                  <div className="images-drop-area-container">
                    <Files
                      // className="files-dropzone"
                      onChange={onImagesSelected}
                      onError={(error) => {}}
                      accepts={["image/png", ".jpeg", ".jpg", ".webpg"]}
                      multiple
                      maxFileSize={100000000} // 100MB
                      minFileSize={0}
                      clickable
                      className="images-drop-area"
                    >
                      גרירה או לחיצה להעלאת תמונה
                      <br />
                      <b>תמונות נוספות - מקסימום 4 תמונות</b>
                    </Files>
                  </div>
                  <div
                    className="helphand shadow"
                    onClick={() => setIsPhotoHelperDisplayed(true)}
                  />
                </div>
                <div>
                  {/* key={image2} */}
                  {splitArray(additionalImages, 2).map((images, outerIndex) => {
                    return (
                      <div className="faceline">
                        {images.map((image, index) => {
                          return (
                            <Fragment>
                              <img
                                className="chosen-image"
                                src={image.imageURL}
                                alt=""
                                id={`image${index}`}
                                onClick={(e) => imgClick(`image${index}`)}
                              />
                              <span
                                className="remove-image-button"
                                onClick={() => {
                                  setAdditionalImages(
                                    additionalImages.filter(
                                      (currentImage) =>
                                        currentImage.imageURL !== image.imageURL
                                    )
                                  );
                                }}
                              />
                            </Fragment>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
                <Popup
                  open={isPhotoHelperDisplayed}
                  contentStyle={{
                    minWidth: "30%",
                    maxWidth: "60%",
                    borderRadius: "5px",
                  }}
                  position="right center"
                  onClose={() => setIsPhotoHelperDisplayed(false)}
                  closeOnDocumentClick
                  closeOnEscape
                >
                  {PhotoHelper}
                </Popup>
              </>
            )}

            <div></div>
            {isErrorMessageDisplayed && (
              <div className="error-message">
                <h5>{errorMessage} </h5>
              </div>
            )}
            <br />
            <div
              className="button-text-center"
              id="btnSubmit"
              onClick={viewIndex === 2 ? onSubmitStep3 : onSubmitStep4}
            >
              שמירה והמשך
            </div>

            <Link to="/edit" style={{ textDecoration: "none" }}>
              <div className="button" id="backBtn">
                {" "}
                חזרה
              </div>
            </Link>
          </div>
        </>
      ) : (
        <div className="loading-data-container">
          <div className="loading-data-div">
            <Ellipsis />
            <h4>טוען את המידע שלך</h4>
          </div>
        </div>
      )}
    </form>
  );
};

export default Pic;
