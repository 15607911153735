import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "../../App.css";
import "./ManagePlace.css";
import axios from "axios";
import Files from "react-files";
import Resizer from "react-image-file-resizer";

import {
  getRegionPlace,
  getKindPlace,
  getStagePlace,
} from "../../handlers/dictionaryHandler.js";
const ManagePlace = () => {
  const history = useHistory();
  const [serialPlace, setSerialPlace] = useState("0");
  const [region, setRegion] = useState("");
  const [stage, setStage] = useState("");
  const [kind, setKind] = useState("");
  const [title, setTitle] = useState("");
  const [freetext, setFreetext] = useState("");
  const [writerId, setWriterId] = useState("");
  const [status, setStatus] = useState("");
  const [imgPlace, setImgPlace] = useState("");
  const [waze, setWaze] = useState("");
  const [imgPlaceFullScreen, setImgPlaceFullScreen] = useState(false);
  const [imagesCounter, setImagesCounter] = useState(0);

  const [cellularPlace, setCellularPlace] = useState("");
  const [namePlace, setNamePlace] = useState("");

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const resizeFile = (file) =>
    new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        1920,
        1920,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
      Resizer.onerror = (error) => {
        reject(error);
      };
    });

  const onImagesSelected = async (files) => {
    // console.log("heree ");
    setImagesCounter(files.length);

    if (files.length - imagesCounter >= 2) {
      const image1 = files[files.length - 2];
      // console.log("1: " + image1);
      const image1InBase64 = await resizeFile(image1);
      setImgPlace(image1InBase64);

      if (files[files.length - 1]) {
        const image2 = files[files.length - 1];
        // console.log("2: " + image2);
        const image2InBase64 = await resizeFile(image2);
        setImgPlace(image2InBase64);
      }
    } else {
      const newImage = files[files.length - 1];
      const newImageInBase64 = await resizeFile(newImage);
      if (imgPlace == "") {
        setImgPlace(newImageInBase64);
      } else {
        setImgPlace(newImageInBase64);
      }
    }
  };

  const savePlace = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PapprovePlace",
        {
          idPlace: serialPlace,
          title,
          waze,
          freetext,
          imgPlace,
        },
        config
      );
      if (response.data === true) {
        alert("done");
      } else {
      }
    } catch (error) {}
    // console.log(response.data[0][0]);
  };

  const delPlace = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PdelPlace",
        {
          idPlace: serialPlace,
        },
        config
      );
      if (response.data === true) {
        alert("done");
      }
    } catch (error) {
      console.log(error);
    }
    // console.log(response.data[0][0]);
  };
  const showPlace = async (serial) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/getOnePlace",
        { serial },
        config
      );
      const userData = response.data;
      if (userData[0].length > 0) {
        setSerialPlace(userData[0][0].idPlace);
        setRegion(userData[0][0].region);
        setStage(userData[0][0].stage);
        setKind(userData[0][0].kind);
        setTitle(userData[0][0].title);
        setFreetext(userData[0][0].freeText);
        setWriterId(userData[0][0].writerId);
        setWaze(userData[0][0].waze);
        setStatus(userData[0][0].status);
        setImgPlace(userData[0][0].photo);
        setCellularPlace(userData[0][0].cellular);
        setNamePlace(userData[0][0].fullName);
      } else {
        // in case there is no data
      }
    } catch (error) {
      // probebly user is not authenticated or server is down
    }
  };

  return (
    <div>
      <div className="h5middle"> אישור מקום המלצה לדייט</div>
      <input
        type="text"
        placeholder="סידורי המלצה לדייט"
        value={serialPlace}
        maxLength={10}
        onChange={(e) => setSerialPlace(e.target.value)}
      ></input>

      <div
        className="button-text-center"
        onClick={() => {
          showPlace(serialPlace);
        }}
      >
        הצגת מקום לפי מספר סידורי
      </div>
      <div className="lbl">נקיש 0 - ונקבל את המקום הבא בתור</div>

      <div className="messegeMatch">{"איזור: " + getRegionPlace(region)}</div>
      <div className="messegeMatch">{"שלב בקשר: " + getStagePlace(stage)}</div>
      <div className="messegeMatch">{"סוג דייט: " + getKindPlace(kind)}</div>
      <div className="messegeMatch">
        {"כותרת: "}
        <textarea
          className="occupation"
          rows="1"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        ></textarea>
      </div>
      <div className="messegeMatch">
        {"מה לרשום בוויז: "}
        <textarea
          className="occupation"
          rows="1"
          value={waze}
          onChange={(e) => setWaze(e.target.value)}
        ></textarea>
      </div>
      <div className="freetext-container">
        {" טקסט חופשי:"}
        <textarea
          className="freetext"
          rows="8"
          value={freetext}
          onChange={(e) => setFreetext(e.target.value)}
        ></textarea>
      </div>
      {imgPlace !== "" ? (
        <>
          <img
            className={
              imgPlaceFullScreen ? "image-fullscreen" : "image-doubleMatch"
            }
            src={`${imgPlace}`}
            alt=""
            onClick={(e) => setImgPlaceFullScreen(!imgPlaceFullScreen)}
          />
        </>
      ) : null}
      <div className="messegeMatch">
        סטטוס המקום
        {status}
      </div>
      <div className="files-dropzone">
        <div className="images-drop-area-container">
          <Files
            // className="files-dropzone"
            onChange={onImagesSelected}
            onError={(error) => {
              console.log(error);
            }}
            accepts={["image/png", ".jpeg", ".jpg", ".webpg"]}
            multiple
            maxFileSize={10000000}
            minFileSize={0}
            clickable
            className="images-drop-area"
          >
            גרור או לחץ להעלאת תמונה
          </Files>
        </div>
      </div>
      <div className="messegeMatch">
        פרטי הכותב
        {writerId + "|" + namePlace + "|" + cellularPlace}
      </div>
      <div className="faceline">
        <div
          className="button-text-center"
          style={{ width: "130px", fontSize: "15px" }}
          onClick={() => {
            savePlace(serialPlace);
          }}
        >
          אישור המקום
        </div>
        <div
          className="button-text-center"
          style={{ width: "130px", fontSize: "15px" }}
          onClick={() => {
            delPlace(serialPlace);
          }}
        >
          מחיקת המקום
        </div>
      </div>
    </div>
  );
};

export default ManagePlace;
