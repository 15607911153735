import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./FindPlace.css";
import HeaderBack from "../../components/Header/HeaderBack";
import axios from "axios";
import Linkify from "react-linkify";

const shareMessageFirstPart =
  "היי מה נשמע? אני רוצה להמליץ לך על המקום הבא מהאתר double2love.com :";

const shareMessageLastPart =
  " רוצה גם שאשלח לך צילום מסך של המקום? המלצות רבות נוספות באתר דאבל השדכן הוירטואלי";

const FindPlace = () => {
  let history = useHistory();
  const [region, setRegion] = useState("0");
  const [stage, setStage] = useState("0");
  const [kind, setKind] = useState("0");
  const [isItFree, setIsItFree] = useState(false);
  const [placeList, setPlaceList] = useState([]);
  const [textToFind, setTextToFind] = useState("");

  const imgClick = (imgId) => {
    if (document.getElementById(imgId).style.width != "240px") {
      document.getElementById(imgId).style.maxHeight = "290px";
      document.getElementById(imgId).style.width = "240px";
    } else {
      document.getElementById(imgId).style.maxHeight = "100px";
      document.getElementById(imgId).style.width = "100px";
    }
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const findMePlace = async () => {
    try {
      console.log("stage ", stage);
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PfindMePlace",
        {
          region,
          stage,
          kind,
          isItFree: isItFree ? "1" : "0",
          textToFind,
        },
        config
      );
      const userData = response.data;
      setPlaceList([...userData]);
    } catch (error) {
      // probebly user is not authenticated or server is down
      console.log("wrong", error);
    }
  };

  const updatePlaceLike = async (isPlaceLiked, idPlace) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/update-like-place",
        {
          isPlaceLiked,
          idPlace,
        },
        config
      );

      const updatedPlaceList = placeList.map((place) => {
        console.log(place.idPlace, idPlace);
        if (place.idPlace === idPlace) {
          return {
            ...place,
            isLiked: isPlaceLiked,
            likeCount: isPlaceLiked ? place.likeCount + 1 : place.likeCount - 1,
          };
        } else {
          return place;
        }
      });

      console.log("updatedPlaceList");
      console.log(updatedPlaceList);

      setPlaceList(updatedPlaceList);
    } catch (error) {
      console.log("Could not update like", error);
    }
  };

  const showDetailsArea = async (serial) => {
    setPlaceList([
      ...placeList.map((cur) => {
        if (cur.idPlace === serial) {
          return {
            ...cur,
            showDetails: cur.showDetails === "0" ? "1" : "0",
          };
        } else {
          return cur;
        }
      }),
    ]);
  };

  const getImage = async (serial) => {
    if (serial !== "0") {
      try {
        console.log(serial);
        const response = await axios.post(
          process.env.REACT_APP_LOCALHOST_ADDRESS + "/getOnePlace",
          { serial },
          config
        );
        const userData = response.data;
        if (userData[0].length > 0) {
          setPlaceList([
            ...placeList.map((cur, index) => {
              if (cur.idPlace === serial) {
                return {
                  ...cur,
                  imgPlace: userData[0][0].photo,
                  showDetails: "1",

                  // imgPlace: userData[0][0].photo,
                };
              } else {
                return cur;
              }
            }),
          ]);
        } else {
          // in case there is no data
        }
      } catch (error) {
        // probebly user is not authenticated or server is down
      }
    }
  };

  return (
    <div className="aboutdouble-full-screen">
      <HeaderBack />
      <br />
      <form className="addplace-form">
        <h2 className="h-nomargin">דאבל, תהיה חבר, יש לך רעיון לאן לצאת? </h2>

        <br />

        <div className="section-title"> אני רוצה באיזור</div>
        <div className="parent-edu">
          <input
            type="button"
            className={`region ${region === "1" ? "active" : ""}`}
            value={"צפון"}
            key="1"
            onClick={(e) => (region === "1" ? setRegion("0") : setRegion("1"))}
          />
          <input
            type="button"
            className={`region ${region === "2" ? "active" : ""}`}
            value={"דרום"}
            key="2"
            onClick={(e) => (region === "2" ? setRegion("0") : setRegion("2"))}
          />
          <input
            type="button"
            className={`region ${region === "3" ? "active" : ""}`}
            value={"מרכז"}
            key="3"
            onClick={(e) => (region === "3" ? setRegion("0") : setRegion("3"))}
          />
          <input
            type="button"
            className={`region ${region === "4" ? "active" : ""}`}
            value={"ירושלים"}
            key="4"
            onClick={(e) => (region === "4" ? setRegion("0") : setRegion("4"))}
          />
        </div>
        <br />
        <div className="section-title">השלב שלנו בקשר הוא: </div>
        <div className="parent-edu">
          <input
            type="button"
            className={`region stage ${stage === "1" ? "active" : ""}`}
            value={"התחלה "}
            key="1"
            onClick={(e) => (stage === "1" ? setStage("0") : setStage("1"))}
          />
          <input
            type="button"
            className={`region stage ${stage === "2" ? "active" : ""}`}
            value={"דייט מתקדם"}
            key="2"
            onClick={(e) => (stage === "2" ? setStage("0") : setStage("2"))}
          />
        </div>
        <br />
        <div className="section-title">אנחנו יותר בסגנון של: </div>
        <div className="parent-edu">
          <input
            type="button"
            className={`region ${kind === "1" ? "active" : ""}`}
            value={"טבע "}
            key="1"
            onClick={(e) => (kind === "1" ? setKind("0") : setKind("1"))}
          />
          <input
            type="button"
            className={`region ${kind === "2" ? "active" : ""}`}
            value={"אוכל "}
            key="2"
            onClick={(e) => (kind === "2" ? setKind("0") : setKind("2"))}
          />
          <input
            type="button"
            className={`region ${kind === "3" ? "active" : ""}`}
            value={"אקסטרים "}
            key="3"
            onClick={(e) => (kind === "3" ? setKind("0") : setKind("3"))}
          />
          <input
            type="button"
            className={`region ${kind === "4" ? "active" : ""}`}
            value={"פעילות "}
            key="4"
            onClick={(e) => (kind === "4" ? setKind("0") : setKind("4"))}
          />
        </div>
        <br />
        <div className="section-title">ונעדיף מקום שהוא בחינם</div>
        <br />
        <div className="toggle-line">
          <label className="toggle">
            <input
              type="checkbox"
              onChange={() => {
                setIsItFree(!isItFree);
              }}
              checked={isItFree}
            />
            <span className="slider-in-toggle"></span>
          </label>

          <div className="lbl">{isItFree ? "כן, שיהיה מקום בחינם" : "לא"}</div>
        </div>
        <br />
        <div className="section-title">
          <b>או.. חיפוש חופשי</b>
        </div>

        <input
          type="text"
          placeholder=" לאן בא לך לצאת?"
          value={textToFind}
          maxLength={20}
          onChange={(e) => setTextToFind(e.target.value)}
        ></input>

        <div
          className="button-text-center"
          id="btnSubmit"
          onClick={findMePlace}
        >
          הצג מקומות
        </div>
        <br />
        {placeList.map((curPlace) => {
          return (
            <div key={curPlace.idPlace} style={{ height: "auto" }}>
              <div className={curPlace.rowNum != "0" ? "" : "invisible"}>
                <div
                  className="linePlace"
                  onClick={() => {
                    showDetailsArea(curPlace.idPlace);
                    curPlace.imgPlace == ""
                      ? getImage(curPlace.idPlace)
                      : getImage("0");
                  }}
                >
                  {/* <div className="rowNum">{curPlace.rowNum}. </div> */}
                  <div className="rowTitle">{curPlace.title} </div>
                  <div className="rowTitle"> | {curPlace.waze} </div>
                </div>
                <div className="divider" />
                <div className="placeButtonSection">
                  <div className="likecount">{curPlace.likeCount}</div>
                  <div
                    className={
                      curPlace.isLiked ? "likebuttonChosen" : "likebutton"
                    }
                    onClick={() =>
                      updatePlaceLike(!curPlace.isLiked, curPlace.idPlace)
                    }
                  />
                  <div>
                    <div className="whatsuppgrey" />
                    <a
                      href={`whatsapp://send?text=${[
                        shareMessageFirstPart,
                        "*",
                        curPlace.title,
                        "*",
                        curPlace.waze,
                        curPlace.freeText,
                        shareMessageLastPart,
                      ]
                        .join("\n")
                        .replace(/\n/gm, "%0a")}`}
                      onClick={() => localStorage.setItem("recommendPlace", 1)}
                    >
                      שליחה
                    </a>
                  </div>
                </div>

                <div>
                  <div
                    className={curPlace.showDetails === "0" ? "invisible" : ""}
                  >
                    <div className="faceline">
                      <>
                        <img
                          className="imagePlace"
                          src={curPlace.imgPlace}
                          // id="imgPlace"
                          // onClick={(e) => imgClick("imgPlace")}
                          alt=""
                        />
                      </>
                      <div className="lbl" style={{ textAlign: "right" }}>
                        <Linkify>{curPlace.freeText} </Linkify>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <br />

        <Link to={"/addplace"} style={{ textDecoration: "none" }}>
          <div
            className="prev-matches-button"
            style={{ color: "var(--btn-color)" }}
          >
            {" "}
            אהבת? רוצה להוסיף המלצה?
          </div>
        </Link>

        <br />
        <Link to={"/questionnaires"} style={{ textDecoration: "none" }}>
          <div className="button" id="backBtn">
            {" "}
            חזרה
          </div>
        </Link>
      </form>{" "}
    </div>
  );
};

export default FindPlace;
