import React from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./DoubleFriend.css";
import axios from "axios";
import HeaderBack from "../../components/Header/HeaderBack";
import { getWordByGender } from "../../handlers/dictionaryHandler.js";

const DoubleFriend = () => {
  const history = useHistory();

  const tipWritersFirstDates = [
    {
      name: '   ד"ר אגוזה וסרמן',
      phoneNumber: "505377525",
      photo: "EgozaW",
    },
    {
      name: "   שרית שביט",
      phoneNumber: "506596984",
      photo: "SaritShavit",
    },
    {
      name: "  שירלי והל",
      phoneNumber: "545757469",
      photo: "ShirliVal",
    },
  ];

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const tryToBid = async (cell) => {
    console.log("test - post");
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PtryToBid",
        { cell },
        config
      );
    } catch (error) {}
  };

  return (
    <form className="forum-main-form">
      <HeaderBack />

      <br></br>

      <div className="form-body">
        <div className="choose_room">
          <h1 className="h1-center"> הדאבליסטים שלנו </h1>
          <div className="photographline">
            הי מה נשמע? אז... למה אנחנו כאן?
            <br />
            1. אנחנו כאן בשביל שאלות ופניות שונות הקשורות לאתר.
            <br />
            2. יצא לך {getWordByGender("someoneToYourFriend")} אפשר לעשות זאת
            דרכינו.
            <br />
            3. רוצה שנעבור איתך על הכרטיס שלך? בכיף
          </div>
          <br />
          <br />
          <div className="doublefriendarea">
            {/* <div className="smallArticle"> */}
            {/* <FirstDatesInterestingEgoza /> */}

            {tipWritersFirstDates.map((currentTip) => {
              return (
                <div key={currentTip.name} className="tip1">
                  <div style={{ height: "150px" }}>
                    <div className="photographline">
                      <div className="rightCol">
                        <div> {currentTip.name} </div>

                        <div> {"ליצירת קשר:  0" + currentTip.phoneNumber} </div>
                        <div
                          className="whatsupFloata"
                          onClick={() => tryToBid(currentTip.phoneNumber)}
                        >
                          <a
                            href={`https://wa.me/972${currentTip.phoneNumber}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="whatsupTips" />
                          </a>
                        </div>
                      </div>
                      <div className="leftCol">
                        <div className={currentTip.photo} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* </div> */}
          </div>
        </div>

        <br></br>
        <br></br>

        <Link to="/questionnaires" style={{ textDecoration: "none" }}>
          <div className="button" id="backBtn">
            {" "}
            חזרה
          </div>
        </Link>
        <div className="lineDice" />
      </div>
    </form>
  );
};

export default DoubleFriend;
