import React from "react";
import { getFirstName } from "../../handlers/dictionaryHandler.js";

const PhotoArticle = () => {
  return (
    // <div className="terms-and-conditions-container">
    // {/* className="terms-and-conditions-p" */}

    <div className="smallArticle" style={{ border: "0px" }}>
      <b>במאמר הבא נדבר על חשיבות תמונה.</b>
      <br />
      אנחנו לא באים לשכנע אלא להציג את זוית הראייה שלנו. מקוים שתהנו 🙂
      <br />
      <br />
      <b>נתחיל בדוגמה מעולם הקניות:</b>
      <br />
      דליה נכנסת לחנות רהיטים לבחור כיסא חדש למרפסת שלה, בחנות יש המון כיסאות:
      כיסא בורדו מבד קטיפה וצר יחסית, כיסא עץ חום במודל עתיק, כיסא בר עגול
      מסתובב ללא משענת.
      <br />
      כל כיסא מונח בסביבה שמציגה אותו באופן הטוב ביותר:
      <br />
      כיסא הבר ליד שולחן גבוה כיסא הקטיפה מעל שטיח. וכמובן יש תאורה.
      <br />
      דליה ככל הנראה תצא מהחנות בידיים מלאות, וחיוך.
      <br />
      <br />
      אדיר מעדיף לבצע את הקניות שלו מהבית, מהרשת.
      <br />
      אדיר נכנס לחנות אינטרנטית, גם הוא רוצה לקנות כיסא.
      <br />
      מנהל החנות האינטרנטית רוצה שירכשו את המוצרים שלו. אז הוא צילם אותם עם
      תאורה טובה ומתאימה מכמה זוויות.
      <br />
      אין לו ברירה, החנות שלו וירטואלית, הוא חייב להעביר חוויה כמה שיותר קרובה
      למציאות. אדיר ירצה לקנות כאשר ירגיש חוויה טובה.
      <br />(<b> נשים לב, כולם נהנים,</b>
      החנות מוכרת והקונה מבסוט)
      <br />
      <br />
      מה אומרים, אפשר לומר לדליה ואדיר תתחדשו, לאור החוויה הטובה שלהם?
      <br />
      <br />
      באופן דומה אפשר לומר זאת גם לגבי תמונות בהצעות שידוכים.
      <br />
      הצעה שמגיעה מדאבל הכרויות או הצעה מהווטסאפ.
      <br />
      התמונה (וגם הטקסט) מעבירים חוויה.
      <br />
      כדאי שהתמונה והטקסט יעבירו חוויה הכי קרובה למציאות הטובה שלנו,
      <br />
      ויציגו אותנו ברושם ראשוני יותר טוב.
      <br />
      הרי עוד לא מכירים אותנו
      <br />
      לא פגשו אותנו עם הקסם שלנו שרואים רק במציאות.
      <br />
      כל אחת ואחד יודעים מהי הסביבה שתציג אותו באופן מיטבי, האוירה המאפיינת
      אותו. לדוגמה, אם בישול זה קטע שלך, אז תמונה במטבח. כך גם אקסטרים, טיול,
      מעבדה וכד'.
      <br />
      תמונה שרואים את העיניים - ראי הנפש שלך. תמונה עם חיוך, בטח מי שיראה אותך
      יחייך אליך בחזרה.
      <br />
      <br />
      {/* <b>
        {" "}
        רוצים להתחדש בתמונה אישית ומקצועית? לחצו כאן וניצור אתכם קשר בקרוב.
      </b>
      <br />
      <br />
      <br />
      <div className="whatsupFloata">
        <a
          href={`https://wa.me/972549624084?text=הי+אשמח+לשמוע+פרטים+על+צילומים. ${getFirstName()}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="whatsupFloat" />
        </a>
      </div> */}
    </div>
  );
};

export default PhotoArticle;
