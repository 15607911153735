import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "../../App.css";
import axios from "axios";

const LastDataForMatchMaker = () => {
  const [datesList, setDatesList] = useState([]);
  const [totalRows, setTotalRows] = useState("");
  const [totalRowsUsers, setTotalRowsUsers] = useState("");
  const [userList, setUserList] = useState([]);
  const [from, setFrom] = useState("");

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const getDatesThisWeek = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PgetDatesThisWeek",
        config
      );
      const datesList = response.data;
      setTotalRows(datesList.length);
      setDatesList([...datesList]);
    } catch (error) {
      // probebly user is not authenticated or server is down
    }
  };

  const getNewUsersDetails = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PgetNewUsersDetails",
        { from },
        config
      );
      const userList = response.data;
      setTotalRowsUsers(userList.length);
      setUserList([...userList]);
    } catch (error) {}
  };

  return (
    <div>
      <div
        className="button-small-letters"
        onClick={() => {
          getDatesThisWeek();
        }}
      >
        השבוע יצאו לדייטים
      </div>
      <div> סהכ: {totalRows}</div>
      {datesList.map((cur) => {
        return (
          <div key={cur.cellular} style={{ height: "auto" }}>
            <div className="userline">
              <div>{cur.idWoman}</div>
              <div>{cur.womanName} |</div>
              <div>{cur.womanDetails} |</div>
              <div>{cur.idMan} |</div>
              <div>{cur.manName} |</div>
              <div>{cur.manDetails} |</div>
            </div>
          </div>
        );
      })}
      <div className="faceline">
        <input
          type="text"
          placeholder="מספר"
          maxLength={5}
          style={{ width: "80px" }}
          onChange={(e) => setFrom(e.target.value)}
        ></input>
        <div
          className="stopWhatsappBtn"
          onClick={() => {
            getNewUsersDetails();
          }}
        >
          משתמשים ממספר
        </div>
      </div>
      <div> סהכ: {totalRowsUsers}</div>
      {userList.map((cur) => {
        return (
          <div key={cur.id} style={{ height: "auto" }}>
            <div className="userline">
              <div>{cur.id} |</div>
              <div>{cur.fullName} |</div>
              <div>{cur.Gender} |</div>
              <div>{cur.referenceToWeb} |</div>
              <div>{cur.payRoute} |</div>
              <div>{cur.age} |</div>
              <div>{cur.religous} |</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LastDataForMatchMaker;
