import React from "react";

const PrayMan = () => {
  return (
    // <div className="terms-and-conditions-container">
    // {/* className="terms-and-conditions-p" */}

    <div>
      יהי רצון מלפניך ה' אלוהינו ואלוהי אבותינו,
      <br />
      שתמציא לי ברחמיך הרבים ובחסדיך הגדולים, את זיווגי הראוי לי בזמני, לכל צרכי
      ותקנתי,
      <br />
      זיווג הגון הראוי להוליד, מזרע תלמידי חכמים, מזרע צדיקים ואנשי אמת ויראי
      חטא, כמו שהמצאת זיווגו של אדם הראשון, ויצחק ויעקב ומשה, כל אחד ואחד זיווגו
      בעתו ובזמנו.
      <br />
      ואותה אשה שתמציא לי לזיווגי תהא אשה טובה, אשה נאה במעשיה ונאה במראיתה,
      בעלת מעשים טובים, בעלת חן, אשה מושכלת ויראת אלוהים, רודפת צדק וגומלת חסד.
      <br />
      לא יהא בה שום שמץ פיסול ומום ופגם, ולא תהא כעסנית ורגזנית, רק תהא בעלת
      ענוה ונמוכת רוח,
      <br />
      ויקויים בי מקרא שכתוב: 'לא ינוח שבט הרשע על גורל הצדיקים',
      <br />
      ומקרא שכתוב: 'אשתך כגפן פוריה בירכתי ביתך בניך כשתילי זיתים סביב
      לשולחניך',
      <br />
      כי אתה הוא המושיב יחידים ביתה, מוציא אסירים בכושרות.
      <br />
      יהיו לרצון אמרי פי והגיון לבי לפניך, ה' צורי וגואלי.
    </div>
  );
};

export default PrayMan;
