import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./AboutDouble.css";
import AboutHelper from "../../components/AboutHelper/AboutHelper";
import { getSignedIn } from "../../handlers/dictionaryHandler.js";
import HeaderBack from "../../components/Header/HeaderBack";

const AboutDouble = () => {
  let history = useHistory();
  return (
    <div className="aboutdouble-full-screen">
      <HeaderBack />
      <br />
      <form className="aboutdouble-form">
        <h1 className="h1-homepage">אודות דאבל הכרויות</h1>
        <AboutHelper />
        <Link
          to={getSignedIn() === "1" ? "/questionnaires" : "/"}
          style={{ textDecoration: "none" }}
        >
          <div className="button" id="backBtn">
            {" "}
            חזרה
          </div>
        </Link>
      </form>{" "}
    </div>
  );
};

export default AboutDouble;
