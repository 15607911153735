import React from "react";

const DateCount = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <u>מתחילת שנת 2024</u>
      <br />
      מנוי באתר יצא בממוצע פי 2.5 פעמים לדייט מדאבל
      <br />
      יותר ממי שלא מנוי.
      <br />
      <br />
      <div className="faceline">
        <div className="lblmiddle">
          <div
            className="birec"
            style={{ height: "70px", bottom: "0px" }}
          ></div>
          <div style={{ position: "absolute", bottom: "19px" }}>
            <div className="lblbirec"> דייטים למנוי </div>
          </div>
        </div>
        <div className="lblmiddle">
          <div
            className="birec"
            style={{ height: "28px", bottom: "-20px" }}
          ></div>
          <div style={{ position: "absolute", bottom: "19px" }}>
            <div className="lblbirec"> דייטים ללא מנוי </div>
          </div>
        </div>
      </div>
      <div className="myline" />
      <br />
    </div>
  );
};

export default DateCount;
