import React from "react";

const QualityOfDateShirliVal = () => {
  return (
    // <div className="terms-and-conditions-container">
    // {/* className="terms-and-conditions-p" */}

    <div className="">
      <b> מה המתכון לאיכות הקשר?</b>
      <br />
      <br />
      ברחוב שלנו בונים בית. כשאני עוברת שם, אני רואה שיש פועלים שעובדים בפנים
      אבל כבר 5 חודשים והבית נראה אותו דבר ואין התקדמות חיצונית כלל. ככה אנחנו
      רגילים למדוד הצלחות.
      <br />
      <b>יש הוכחות ברורות בשטח, יש תוצאות = הצלחה.</b>
      <br />
      כשבונים בית, ראשית חופרים ויוצקים את היסודות ובונים את השלד ואז מתחילים
      לעבוד מבפנים על התשתיות וזה לא נראה לעין כהתקדמות. ניתן לבנות בית בשלושה
      חודשים, בשנה ואף יותר. אך לא ניתן לדלג על השלבים ועל זמן
      <b> השהייה </b>
      בכל שלב.
      <br />
      <br />
      ככה גם כשבונים קשר לנשואים.
      <br />
      אם שיחת הטלפון הראשונה היא שעתיים. והפגישה הראשונה ארוכה. ההתלהבות גדולה
      והלב והרגש מובילים ונראה שזה קשר מוצלח. אך אחרי כמה פגישות פתאום, חלה
      ירידה בהתלהבות.
      <br />
      למה זה קורה?
      <br />
      כשמתחילים קשר חשוב לתת לרשמים לחלחל, לשהות, לבנות לשמוע את כל הקולות, גם
      את הקול הרציונאלי ולבנות את היסודות צעד, צעד.
      <br />
      קשר שמתחיל בהתלהבות מטורפת לא מוכיח על הצלחת הקשר.
      <br />
      חשוב לתחם את זמן הפגישה. לחזור הבייתה לחשוב על הדברים. להפגש שוב אחרי
      יומיים ולבנות לאט אבל מהר.
      <br />
      וכך גם אם פגישה אחת או שניים, היה סתם נחמד ואין אורות. אין לפרש או להגיע
      למסקנה שהקשר לא מתקדם. כי אז המוח מספק את כל הנתונים וההוכחות שאין הצלחה
      והקשר נגמר. אלא לאמר לעצמכם הקשר "בהשהייה" - בבניה. חשוב להיות בהוויה ,
      בלי לחכות לתוצאות מידיות. גם אם הרגע נראה אפור זכרו משהו נרקם בפנים. ורק
      כשמאפשרים לזה לקרות זה קורה בצורה רציפה ומתוך הבנה ברורה שזה הקשר הנכון.
      <br />
      בהצלחה
    </div>
  );
};

export default QualityOfDateShirliVal;
