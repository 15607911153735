import React from "react";

const ForumRules = () => {
  return (
    // <div className="terms-and-conditions-container">
    // {/* className="terms-and-conditions-p" */}
    <div className="terms-and-conditions-p">
      <b>מטרת הפורום</b>
      <br />
      הפורום מיועד לשמש מקום לשיתוף אנונימי מחוויות הדייטים.
      <br />
      כך שנעבור את התהליך יחד ♡
      <br />
      ניתן להתייעץ על התנהגויות, אמונות.
      <br />
      לפרוק חוויות.
      <br />
      לשתף בדברים משעשעים שארעו, ועוד.
      <br />
      בפורום משתתפים גם אנשי מקצוע, תראו את התגובות שלהם תחת השם "דאבל".
      <br />
      <br />
      <b>חוקי הפורום</b>
      <br />
      יש לשמור על שפה נקייה, מכובדת ונעימה.
      <br />
      אסור שיימינג מכל סוג.
      <br />
      אין פרסום, הן חיובי והן שלילי, רק באישור מ"דאבל".
      <br />
      אין להטיף בקבוצה.
      <br />
      יש לכם תלונה? תכתבו לנו למייל, לא כאן.
      <br />
      הפרסום בקבוצה מיועד לקבוצה בלבד. אין להוציא מידע מהקבוצה, לא בהעתקה ולא
      בצילום.
      <br />
      <br />
      דיברו אליכם באופן פוגעני? פנו אלינו ונשקול את חסימת המשתמש.
    </div>
  );
};

export default ForumRules;
