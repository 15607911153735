import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./AddPlace.css";
import HeaderBack from "../../components/Header/HeaderBack";
import axios from "axios";
import Cookies from "js-cookie";

const CollectStars = () => {
  let history = useHistory();
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);
  const [errorMessage2, setErrorMessage2] = useState("");
  const [kind, setKind] = useState("");
  const [isShareStatus, setIsShareStatus] = useState(false);
  const [isReccomendFriend, setIsReccomendFriend] = useState(false);
  const [isReccomedPlace, setIsReccomedPlace] = useState(false);
  const [isFew, setIsFew] = useState(false);
  const [cupon, setCupon] = useState("");

  const alertUser2 = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage2(message);
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const checkShareStatus = async () => {
    if (isShareStatus) {
      onCollectStars("2");
      alertUser2(" ");
    } else {
      console.log("is§shared " + isShareStatus, kind);
      alertUser2("לא סימנת שתשתפ.י בקבוצה ");
    }
  };

  const checkReccomendFriend = async () => {
    if (isReccomendFriend) {
      onCollectStars("3");
      alertUser2(" ");
    } else {
      alertUser2("לא סימנת שתירשום חבר לאתר ");
    }
  };

  const checkFewDates = async () => {
    if (Cookies.get("matchesCnt") < 4) {
      if (isFew) {
        onCollectStars("4");
        alertUser2(" ");
      } else {
        alertUser2("לא סימנת שלא קיבלת כמעט התאמות");
      }
    } else {
      alertUser2(
        "קיבלת " +
          `${Cookies.get("matchesCnt")}` +
          " התאמות.. ניתן לראות זאת בהתאמות שלי -> התאמות קודמות"
      );
    }
  };

  const checkCupon = async () => {
    if (cupon == "zilum2023") {
      onCollectStars("5");
      alertUser2(" ");
    } else {
      alertUser2("קוד לא תקין, אפשר לנסות שוב.");
    }
  };

  const checkReccomendPlace = async () => {
    if (localStorage.getItem("recommendPlace") == 1) {
      if (isReccomedPlace) {
        onCollectStars("7");
        localStorage.setItem("recommendPlace", 0);
        alertUser2(" ");
      } else {
        alertUser2("לא סימנת שתמליצ.י. על מקום לחברה ");
      }
    } else {
      alertUser2(
        "לא שלחת המלצה על מקום לחבר.ה, יש קישור ישיר לווטסאפ ברעיונות לאן לצאת "
      );
    }
  };

  const onCollectStars = async (kind) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PcollectStars",
        {
          kind,
        },
        config
      );
      if (response.data === true) {
        alertUser2("נשמר בהצלחה, אספת 10 נקודות!.");
      } else {
        alertUser2("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
      }
    } catch (error) {
      console.log(error);
      alertUser2("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
    }
  };

  return (
    <div className="aboutdouble-full-screen">
      <HeaderBack />
      <br />
      <form className="addplace-form">
        <h2 className="h-nomargin"> צוברים נקודות </h2>
        {/* <div
          className="tellAboutUsSection"
          style={{ fontSize: "15px", textAlign: "right" }}
        >
          1. אפשר לשתף בקבוצת ווטסאפ על האתר עם קישור Double2love.com ולקבל 10
          נקודות.
          <br />
          <br />
          <div className="faceline">
            <div className="toggle-line">
              <label className="toggle">
                <input
                  type="checkbox"
                  onChange={() => {
                    setIsShareStatus(!isShareStatus);
                  }}
                  checked={isShareStatus}
                />
                <span className="slider-in-toggle"></span>
              </label>

              <div className="lbl">
                {isShareStatus ? "סבבה, אעלה עכשיו לקבוצת ווטסאפ" : " "}
              </div>
            </div>
            <div
              className="button-text-center narrow"
              onClick={() => {
                checkShareStatus();
              }}
            >
              שמירה
            </div>
          </div>
        </div> */}

        <div
          className="tellAboutUsSection"
          style={{ fontSize: "15px", textAlign: "right" }}
        >
          1. המלצה לחבר על מקום לדייט - 10 נקודות.
          <br />
          א. מצאו מקום לדייט שאהבתם וסמנו עליו לייק (לחיצה על הלב)
          <br />
          ב. שלחו אותו בווטסאפ לחבר (לחיצה על הווטסאפ).
          <br />
          ג. הכניסה ל"רעיונות לאן לצאת" היא{" "}
          <Link to="/findPlace">{" כאן "}</Link>
          <br />
          <br />
          <div className="faceline">
            <div className="toggle-line">
              <label className="toggle">
                <input
                  type="checkbox"
                  onChange={() => {
                    setIsReccomedPlace(!isReccomedPlace);
                  }}
                  checked={isReccomedPlace}
                />
                <span className="slider-in-toggle"></span>
              </label>

              <div className="lbl">
                {isReccomedPlace ? "הבנתי, אמליץ לחבר על מקום לדייט" : " "}
              </div>
            </div>
            <div
              className="button-text-center narrow"
              onClick={() => {
                checkReccomendPlace();
              }}
            >
              שמירה
            </div>
          </div>
        </div>

        <br />

        {/* <div className="tellAboutUsSection" style={{ fontSize: "15px" }}>
          2. אפשר להמליץ לחבר/ה להירשם לאתר ושאכן הוא יצטרף אלינו, ולקבל 10
          נקודות.
          <br />
          <br />
          <div className="faceline">
            <div className="toggle-line">
              <label className="toggle">
                <input
                  type="checkbox"
                  onChange={() => {
                    setIsReccomendFriend(!isReccomendFriend);
                  }}
                  checked={isReccomendFriend}
                />
                <span className="slider-in-toggle"></span>
              </label>

              <div className="lbl">
                {isReccomendFriend ? "סבבה, ארשום חבר/ה נוסף לאתר" : " "}
              </div>
            </div>
            <div
              className="button-text-center narrow"
              onClick={() => {
                checkReccomendFriend();
              }}
            >
              שמירה
            </div>
          </div>
        </div> */}
        <div
          className="tellAboutUsSection"
          style={{ fontSize: "15px", textAlign: "right" }}
        >
          2. קיבלתי רק 2-3 התאמות עד עכשיו, ואני רוצה עוד להתרשם מהאתר. אבדוק מה
          סימנתי שמתאים לי, אולי יש עוד אופציות רלוונטיות- (10 נקודות)
          <br />
          <br />
          <div className="faceline">
            <div className="toggle-line">
              <label className="toggle">
                <input
                  type="checkbox"
                  onChange={() => {
                    setIsFew(!isFew);
                  }}
                  checked={isFew}
                />
                <span className="slider-in-toggle"></span>
              </label>

              <div className="lbl">
                {isFew ? "אסתכל מה סימנתי שרלוונטי לי" : " "}
              </div>
            </div>
            <div
              className="button-text-center narrow"
              onClick={() => {
                checkFewDates();
              }}
            >
              שמירה
            </div>
          </div>
        </div>
        <div
          className="tellAboutUsSection"
          style={{ fontSize: "15px", textAlign: "right" }}
        >
          3. יש לי קוד קופון - 20 נקודות.
          <br />
          <br />
          <div className="faceline">
            <input
              type="text"
              placeholder="הקוד שלך"
              value={cupon}
              maxLength={10}
              className="cupon-input"
              onChange={(e) => setCupon(e.target.value)}
            ></input>
            <div className="lbl" />

            <div
              className="button-text-center narrow"
              onClick={() => {
                checkCupon();
              }}
            >
              שמירה
            </div>
          </div>
        </div>
        {isErrorMessageDisplayed && (
          <div className="error-message">
            <h5>{errorMessage2} </h5>
          </div>
        )}
        <Link
          to="/Subscribe"
          // style={{ textDecoration: "none" }}
        >
          {" לרכישת מנוי "}
        </Link>
        <Link to={"/questionnaires"} style={{ textDecoration: "none" }}>
          <div className="button" id="backBtn">
            {" "}
            חזרה
          </div>
        </Link>
      </form>{" "}
    </div>
  );
};

export default CollectStars;
